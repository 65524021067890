import useSWR from 'swr'

import { Patient } from '../../../common/app.types'

const empty = []

export function usePatients(): [Patient[], () => Promise<boolean>, { loading; error }] {
	const { data, mutate, error } = useSWR('/patients')

	return [data ?? empty, () => mutate(), { loading: !data && !error, error }]
}
