export function ProtocolPageHelp() {
	return (
		<div>
			<h4 className="uppercase pb-4 border-b-2 border-main-dark mb-4 font-semibold">
				Pagina Protocollo
			</h4>
			<div className="text-gray-700">
				<p className="pb-2">
					Puoi assegnare un protocollo al gruppo creandone uno nuovo o
					selezionando un protocollo tra quelli esistenti.
				</p>
				<p className="pb-2">
					Una volta selezionato un protocollo, puoi aggiungere un
					numero di passaggi a piacere, e per ciascun passaggio
					associare contenuti <strong>testuali</strong>,{' '}
					<strong>audio</strong>, <strong>video</strong> o{' '}
					<strong>allegati</strong>.
				</p>
			</div>
		</div>
	)
}
