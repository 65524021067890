import { useEffect, useState } from 'react'
import { Plus, Trash, Youtube } from 'react-feather'

import { Button } from '../../common/components/button.component'
import { Input, InputAddition, InputAdditionPosition } from '../../common/components/input.component'
import { sortBySeqNumber } from './protocol.util'

const YOUTUBE_URL = 'https://www.youtube.com/watch?v='

function getEmbedId(url) {
	return url?.replace(YOUTUBE_URL, '') ?? ''
}

export function ProtocolFormVideoTab({ videos, onCreateVideo, onRemoveVideo, onEditVideo, onBlur }) {
	const [failedVideos, setFailedVideos] = useState([])

	useEffect(() => {
		function handleCheckVideoUrl(url) {
			if (!url.startsWith(YOUTUBE_URL) && url.length > 0) {
				return true
			}

			if (getEmbedId(url).length === 0 && url.length > 0) {
				return true
			}

			return false
		}

		const failedVideos = videos
			?.map((v) => {
				return handleCheckVideoUrl(v.url) ? v.url : null
			})
			.filter((x) => x !== null)

		setFailedVideos(failedVideos)
	}, [videos])

	if (!videos) {
		return null
	}

	return (
		<div className="bg-white">
			{videos?.length === 0 && (
				<p className="p-4 text-center text-gray-500">Non sono presenti video in questo passaggio</p>
			)}

			{sortBySeqNumber(videos).map((video) => (
				<div key={video.uid} className="mb-4 p-4 border-t border-gray-300">
					<div className="flex justify-between items-center">
						<span className="uppercase">Contenuto Video:</span>
						<Button variant="danger" size="md" onClick={() => onRemoveVideo(video)}>
							<Trash size={14} />
						</Button>
					</div>
					<Input
						value={video.name}
						disableAutoComplete
						label="Nome Video"
						onChange={(e) => onEditVideo(video, { name: e.currentTarget.value })}
						inputProps={{
							onBlur,
						}}
					/>
					<Input
						value={video.url}
						disableAutoComplete
						label="URL Video (YouTube)"
						onChange={(e) => {
							const url = e.currentTarget.value
							onEditVideo(video, { url })
						}}
						inputProps={{
							onBlur,
						}}
						error={failedVideos.includes(video.url)}
						errorText={
							failedVideos.includes(video.url) &&
							"È necessario inserire l'indirizzo di un video su YouTube"
						}
						append={
							video.url.length > 0 &&
							!failedVideos.includes(video.url) && (
								<InputAddition position={InputAdditionPosition.End}>
									<a href={video.url} target="_blank" rel="noreferrer nofollow noopener">
										<Youtube size={18} />
									</a>
								</InputAddition>
							)
						}
					/>
				</div>
			))}
			<div className="flex justify-end mb-2 p-4">
				<Button onClick={onCreateVideo} variant="success" className="w-full lg:w-auto">
					<Plus size={18} className="mr-2" /> Aggiungi Nuovo Video
				</Button>
			</div>
		</div>
	)
}
