import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import numeral from 'numeral'
import { unparse } from 'papaparse'
import { head, last } from 'ramda'
import { useMemo } from 'react'
import { Share, Star } from 'react-feather'
import {
	Bar,
	BarChart,
	Brush,
	CartesianGrid,
	Cell,
	ComposedChart,
	Label,
	Line,
	LineChart,
	ReferenceArea,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts'
import useSWR from 'swr'

import { Measure, Patient } from '../../common/app.types'
import { Button } from '../../common/components/button.component'
import { Spinner } from '../../common/components/spinner.component'
import { getDurationString } from '../../common/utils/get-duration-string.util'
import { getFeedbackColor } from '../../common/utils/get-feedback-color.util'
import { getIntervalColor } from '../../common/utils/get-interval-color.util'
import { getType } from './utils/get-type.util'

type PatientSessionOverviewProps = {
	title: string
	patient: Patient
	session: Measure
	label: string
}

function useSessionData(patientId, sessionId): [Measure, () => Promise<boolean>, { error; loading }] {
	const { data, mutate, error } = useSWR(
		patientId && sessionId ? `/patients/${patientId}/session/${sessionId}` : null,
	)

	return [data, () => mutate(), { loading: !data && !error, error }]
}

const intervalLabels = ['PARA-red', 'PARA-yellow', 'OK', 'ORTO-yellow', 'ORTO-red']

export function PatientSessionOverviewChart({ title, patient, session, label }: PatientSessionOverviewProps) {
	const [sessionData, , sessionDataLoadingStatus] = useSessionData(patient?.uid, session?.uid)

	const scoreChartData = useMemo(() => {
		if (!sessionData) {
			return []
		}

		return sessionData.hrvList.map((measure) => {
			return {
				time: +measure.timestamp,
				value: measure.hrv,
				hr: +(measure.hr ?? 0),
			}
		})
	}, [sessionData])

	const intervalsChartdata = useMemo(() => {
		if (!sessionData) {
			return []
		}

		return sessionData.stats.map((item, index) => {
			return { interval: intervalLabels[index], duration: item }
		})
	}, [sessionData])

	function handleGenerateCSV() {
		if (!sessionData) {
			return
		}

		const csv = unparse(
			sessionData.hrvList.map((x, i) => [
				format(new Date(+x.timestamp), 'dd/MM/yyyy hh:mm:ss'),
				x.hrv,
				x.hr ?? 0,
			]),
		)
		saveAs(new Blob([csv]), 'table-data.csv')
	}

	if (!patient || !session) {
		return null
	}

	return (
		<div className="bg-white py-4">
			<p className="text-center font-bold mb-2">{title}</p>
			{sessionDataLoadingStatus.loading && (
				<div className="m-8 text-center">
					<Spinner color="text-black" />
				</div>
			)}
			{!sessionDataLoadingStatus.loading && (
				<div>
					<div className="text-right mb-4">
						<Button variant="info" onClick={handleGenerateCSV}>
							<Share size={18} className="mr-2" /> Esporta CSV
						</Button>
					</div>
					<ResponsiveContainer height={380} width="100%">
						<ComposedChart data={scoreChartData}>
							<CartesianGrid />
							<XAxis
								dataKey="time"
								scale="time"
								type="number"
								domain={['dataMin', 'dataMax']}
								tickFormatter={(t) => getDurationString(t - head(sessionData.hrvList).timestamp)}
								style={{ fontSize: 12 }}
								height={32}
							/>
							<YAxis
								yAxisId="hrv"
								fontSize={12}
								width={50}
								domain={[-100, 100]}
								ticks={[-100, -75, -50, -25, 0, 25, 50, 75, 100]}
							>
								<Label angle={-90} value="HRV" position="insideLeft" style={{ textAnchor: 'middle' }} />
							</YAxis>
							<YAxis orientation="right" yAxisId="hr" fontSize={12} width={50} domain={[20, 220]}>
								<Label angle={-90} value="HR" position="insideRight" style={{ textAnchor: 'middle' }} />
							</YAxis>
							<ReferenceLine yAxisId="hrv" y={0} strokeWidth={2} stroke={'#333'} />
							<ReferenceArea yAxisId="hrv" y1={-75} y2={-100} fill={getIntervalColor(0)} />
							<ReferenceArea yAxisId="hrv" y1={-50} y2={-75} fill={getIntervalColor(1)} />
							<ReferenceArea yAxisId="hrv" y1={-50} y2={50} fill={getIntervalColor(2)} />
							<ReferenceArea yAxisId="hrv" y1={50} y2={75} fill={getIntervalColor(3)} />
							<ReferenceArea yAxisId="hrv" y1={75} y2={100} fill={getIntervalColor(4)} />
							<Line
								yAxisId="hrv"
								type="monotone"
								dataKey="value"
								width={2}
								stroke="#1E3A8A"
								strokeWidth={2}
								dot={false}
								isAnimationActive={false}
							/>
							<Line
								yAxisId="hr"
								type="monotone"
								dataKey="hr"
								width={2}
								stroke="red"
								strokeWidth={2}
								dot={false}
								isAnimationActive={false}
							/>
							<Brush style={{ marginTop: 20 }} travellerWidth={24} tickFormatter={() => ''}>
								<LineChart data={scoreChartData}>
									<Line
										type="monotone"
										dataKey="value"
										width={2}
										stroke="#1E3A8A"
										strokeWidth={2}
										dot={false}
										isAnimationActive={false}
									/>
								</LineChart>
							</Brush>
							<Tooltip
								labelFormatter={(value) => format(new Date(value), 'hh:MM:ss')}
								formatter={(value, name) => [
									numeral(value).format('0,0.00'),
									name === 'value' ? 'HRV' : 'HR',
								]}
							/>
						</ComposedChart>
					</ResponsiveContainer>
					<div className="grid grid-cols-1 lg:grid-cols-3 gap-2 mt-4">
						<div className="border p-4 shadow">
							<h3 className="font-bold">Inizio</h3>
							{format(new Date(+session.uid), 'dd/MM/yyyy hh:mm:ss')}
						</div>
						<div className="border p-4 shadow">
							<h3 className="font-bold">Durata</h3>
							{getDurationString(
								last(sessionData.hrvList).timestamp - head(sessionData.hrvList).timestamp,
							)}
						</div>
						<div className="border p-4 shadow">
							<h3 className="font-bold">Tipo</h3>
							{getType(session.type)}
						</div>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-3 gap-2 mt-2">
						<div className="border p-4 shadow">
							<h3 className="font-bold">Punteggio Medio</h3>
							{numeral(sessionData.hrv).format('0,0.00')}
						</div>
						<div className="border p-4 shadow">
							<h3 className="font-bold">Punteggio STD</h3>
							{numeral(sessionData.sigmaScore).format('0,0.00')}
						</div>
						<div className="border p-4 shadow">
							<h3 className="font-bold">Varianza HRV</h3>
							{numeral(sessionData.sigmaRRDiff).format('0,0.00')}
						</div>
					</div>
					<div className="grid grid-cols-3 lg:grid-cols-3 gap-2 mt-2">
						<div className="border p-4 shadow">
							<h3 className="font-bold">LF</h3>
							{numeral(sessionData.lf_median).format('0,0.00')}
						</div>
						<div className="border p-4 shadow">
							<h3 className="font-bold">HF</h3>
							{numeral(sessionData.hf_median).format('0,0.00')}
						</div>
						<div className="border p-4 shadow">
							<h3 className="font-bold">LF/HF</h3>
							{numeral(sessionData.lf_hf_median).format('0,0.00')}
						</div>
					</div>
					{sessionData.feedback && (
						<div className="border p-4 shadow mt-2 mb-2">
							<div className="grid grid-cols-2">
								<div className="border-r border-gray-300">
									<h3 className="font-bold">Feedback</h3>
									{sessionData.comment}
								</div>
								<div className="flex items-center justify-center">
									{Array.from(Array(sessionData.feedback + 1), (_, index) => {
										return <Star color={getFeedbackColor(sessionData.feedback)} key={index} />
									})}
								</div>
							</div>
						</div>
					)}
					{sessionData.activationFeedback && (
						<div className="grid grid-cols-3 lg:grid-cols-3 gap-2 mt-2">
							<div className="border p-4 shadow">
								<h3 className="font-bold">Attivazione</h3>
								{numeral(sessionData.activationFeedback).format('0')}
								<p className="text-sm">{sessionData.activationComment}</p>
							</div>
							<div className="border p-4 shadow">
								<h3 className="font-bold">Disattivazione</h3>
								{numeral(sessionData.deactivationFeedback).format('0')}
								<p className="text-sm">{sessionData.deactivationComment}</p>
							</div>
							<div className="border p-4 shadow">
								<h3 className="font-bold">Benessere</h3>
								{numeral(sessionData.wellbeingFeedback).format('0')}
								<p className="text-sm">{sessionData.wellbeingComment}</p>
							</div>
						</div>
					)}
					<div>
						<ResponsiveContainer height={250} width="100%">
							<BarChart data={intervalsChartdata}>
								<XAxis dataKey="interval" />
								<Bar dataKey="duration">
									{intervalsChartdata.map((entry, index) => {
										return <Cell key={`cell-${index}`} fill={getIntervalColor(index)} />
									})}
								</Bar>
								<Tooltip
									cursor={false}
									labelFormatter={(value) => value}
									formatter={(value, name) => [getDurationString(value), 'Durata']}
								/>
							</BarChart>
						</ResponsiveContainer>
					</div>
				</div>
			)}
		</div>
	)
}
