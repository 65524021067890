export function UserPageHelp() {
	return (
		<div>
			<h4 className="uppercase pb-4 border-b-2 border-main-dark mb-4 font-semibold">Pagina Utente</h4>
			<div className="text-gray-700">
				<p className="pb-2">Informazioni sull'utente selezionato.</p>
				<p className="pb-2">
					Puoi <strong>cambiare il tipo di utenza</strong> tramite le spunte dedicate.
				</p>
				<p className="mb-2">
					Un utente <strong>Amministratore</strong> ha la possibilità di modificare tutti gli utenti del
					sistema e attribuire a ciascun utente un ruolo.
				</p>
				<p className="mb-2">
					Un utente <strong>Professionista (Abbonato)</strong> è un utente con accesso alle funzionalità del
					sistema dedicate ai professionisti (gestione persone, gestione gruppi).
				</p>
				<p className="mb-2">
					Un utente <strong>senza nessuna spunta</strong> può accedere solamente a persone che gli sono stati
					dati in visione, non ha un codice professionista e non può creare gruppi.
				</p>
				<p className="mb-2">
					Utilizzando il pulsante <strong className="bold text-main uppercase">'REIMPOSTA PASSWORD'</strong>{' '}
					l'utente riceverà una email con le istruzioni per reimpostare la sua password. Per motivi di
					sicurezza <strong>non è possibile modificare direttamente la password dell'utente.</strong>
				</p>
			</div>
		</div>
	)
}
