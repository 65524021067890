import { format } from 'date-fns'
import firebase from 'firebase'
import numeral from 'numeral'
import { head, last } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { Heart } from 'react-feather'
import {
	CartesianGrid,
	Label,
	Line,
	LineChart,
	ReferenceArea,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts'

import { Measure, Patient } from '../../common/app.types'
import { Loading } from '../../common/components/loading.component'
import { getDurationString } from '../../common/utils/get-duration-string.util'

type PatientRealtimeChartProps = {
	title: string
	patient: Patient
	session: Measure
	label: string
}

export function PatientRealtimeChart({ title, patient, session, label }: PatientRealtimeChartProps) {
	const [hrvList, setHrvList] = useState([])
	const [completed, setCompleted] = useState(false)

	useEffect(() => {
		if (!session) {
			setHrvList([])
			return
		}

		const unsubscribeFromSessionUpdates = firebase
			.app()
			.firestore()
			.collection('users')
			.doc(patient.uid)
			.collection('measures')
			.doc(session.uid)
			.onSnapshot({
				next(value) {
					if (value.data()?.end_date) {
						setCompleted(true)
					}
				},
				error(error) {
					console.log('Session snapshot error', error)
				},
			})

		const unsubscribeFromHrvUpdates = firebase
			.app()
			.firestore()
			.collection('users')
			.doc(patient.uid)
			.collection('measures')
			.doc(session.uid)
			.collection('hrvlist')
			.onSnapshot({
				next(value) {
					setHrvList(
						value.docs.map((d) => {
							return {
								hr: d.data()!.hr,
								hrv: d.data()!.hrv,
								timestamp: d.data()!.timestamp,
							}
						}),
					)
				},
				error(error) {
					console.log('HRVList snapshot error', error)
				},
			})

		return () => {
			unsubscribeFromSessionUpdates?.()
			unsubscribeFromHrvUpdates?.()
		}
	}, [session, patient.uid])

	const scoreChartData = useMemo(() => {
		return hrvList.map((measure) => {
			return {
				time: +measure.timestamp,
				value: measure.hrv,
				hr: measure.hr,
			}
		})
	}, [hrvList])

	if (!patient || !session) {
		return null
	}

	if (hrvList.length === 0) {
		return (
			<div className="flex flex-col justify-center items-center">
				<Loading />
				<p className="mt-0">La sessione sta venendo inizializzata...</p>
			</div>
		)
	}

	return (
		<div className="bg-white py-4">
			<div className="grid grid-cols-1 lg:grid-cols-2 mb-2 mx-2">
				<p className="text-center lg:text-left font-bold">{title}</p>
				<div className="text-center lg:text-right">
					{getDurationString(last(hrvList).timestamp - head(hrvList).timestamp)}
				</div>
			</div>
			<div className="flex items-center justify-center p-4">
				<Heart size={28} className="mr-4 text-red-800 animate-pulse" />

				<span className="text-2xl font-bold text-red-800">{last(scoreChartData)?.hr}</span>
			</div>
			<ResponsiveContainer height={350} width="100%">
				<LineChart data={scoreChartData}>
					<CartesianGrid />
					<XAxis
						dataKey="time"
						scale="time"
						type="number"
						domain={['dataMin', 'dataMax']}
						tickFormatter={(t) => {
							return getDurationString(t - head(hrvList).timestamp)
						}}
						style={{ fontSize: 12 }}
						height={16}
					/>
					<YAxis
						fontSize={12}
						width={50}
						domain={[-100, 100]}
						ticks={[-100, -75, -50, -25, 0, 25, 50, 75, 100]}
					>
						<Label angle={-90} value="HRV" position="insideLeft" style={{ textAnchor: 'middle' }} />
					</YAxis>
					<ReferenceLine y={0} strokeWidth={2} stroke={'#333'} />
					<ReferenceArea y1={-50} y2={50} fill={'green'} />
					<ReferenceArea y1={50} y2={75} fill={'yellow'} />
					<ReferenceArea y1={-50} y2={-75} fill={'yellow'} />
					<ReferenceArea y1={75} y2={100} fill={'red'} />
					<ReferenceArea y1={-75} y2={-100} fill={'red'} />
					<Line
						type="monotone"
						dataKey="value"
						width={2}
						stroke="#1E3A8A"
						strokeWidth={2}
						dot={false}
						isAnimationActive={false}
					/>

					<Tooltip
						labelFormatter={(value) => format(new Date(value), 'hh:MM:ss')}
						formatter={(value, name) => [numeral(value).format('0,0.00'), 'HRV']}
					/>
				</LineChart>
			</ResponsiveContainer>
			{/* <ResponsiveContainer height={350} width="100%" className="mt-2">
				<LineChart data={scoreChartData}>
					<CartesianGrid />
					<XAxis
						dataKey="time"
						scale="time"
						type="number"
						domain={['dataMin', 'dataMax']}
						tickFormatter={(t) => {
							return getDurationString(
								t - head(hrvList).timestamp,
							)
						}}
						style={{ fontSize: 12 }}
						height={16}
					/>
					<YAxis
						fontSize={12}
						width={50}
						domain={[40, 120]}
						ticks={[40, 60, 80, 100, 120]}
					>
						<Label
							angle={-90}
							value="HR"
							position="insideLeft"
							style={{ textAnchor: 'middle' }}
						/>
					</YAxis>
					<Line
						type="monotone"
						dataKey="hr"
						width={2}
						stroke="#8A1E3A"
						strokeWidth={2}
						dot={false}
						isAnimationActive={false}
					/>
					<Tooltip
						labelFormatter={(value) =>
							format(new Date(value), 'hh:MM:ss')
						}
						formatter={(value, name) => [
							numeral(value).format('0,0.00'),
							'HR',
						]}
					/>
				</LineChart>
			</ResponsiveContainer> */}
			{completed && <p className="p-4 font-bold text-center text-green-700">SESSIONE COMPLETATA</p>}
		</div>
	)
}
