import React, {
	forwardRef,
	InputHTMLAttributes,
	SelectHTMLAttributes,
	TextareaHTMLAttributes,
} from 'react'
import { ChevronDown, Lock } from 'react-feather'

import { valueOrEmpty } from '../utils/value-or-empty.util'

type InputProps = {
	name?: string
	label?: React.ReactNode
	value?: any
	onChange?: Function
	type?: string
	placeholder?: string
	error?: boolean
	helperText?: React.ReactNode
	errorText?: React.ReactNode
	inputClassName?: string
	labelClassName?: string
	containerClassName?: string
	textarea?: boolean
	select?: boolean
	rows?: number
	min?: number
	max?: number
	step?: number
	disabled?: boolean
	prepend?: React.ReactNode
	append?: React.ReactNode
	before?: React.ReactNode
	after?: React.ReactNode
	disableAutoComplete?: boolean
	children?: React.ReactNode
	inputProps?:
		| Partial<InputHTMLAttributes<HTMLInputElement>>
		| Partial<SelectHTMLAttributes<HTMLSelectElement>>
		| Partial<TextareaHTMLAttributes<HTMLTextAreaElement>>
}

export enum InputAdditionPosition {
	Start,
	End,
}

type InputAdditionProps = {
	children: React.ReactNode
	position: InputAdditionPosition
	condensed?: boolean
	className?: string
}

export const InputAddition = ({
	children,
	position,
	condensed = false,
	className = '',
}: InputAdditionProps) => {
	return (
		<div
			className={`${
				position === InputAdditionPosition.Start ? '-mr-1' : '-ml-1'
			} flex justify-center items-center self-stretch ${
				!condensed ? 'px-4' : ''
			} border ${className}`}
		>
			{children}
		</div>
	)
}

export const InputInnerAddition = ({
	children,
	position,
	className = '',
}: InputAdditionProps) => {
	return (
		<div
			className={`absolute flex pointer-events-none top-0 left-0 h-full w-full items-center ${
				position === InputAdditionPosition.Start
					? 'justify-start'
					: 'justify-end'
			} ${className}`}
		>
			<div
				className={`${
					position === InputAdditionPosition.Start ? 'ml-4' : 'mr-4'
				}`}
			>
				{children}
			</div>
		</div>
	)
}

export const Input = forwardRef(function (
	{
		name,
		label,
		value,
		onChange = () => {},
		type = 'text',
		placeholder,
		error = false,
		helperText = '',
		errorText = '',
		inputClassName,
		labelClassName,
		containerClassName = 'mb-2',
		textarea = false,
		select = false,
		disabled = false,
		min,
		max,
		step,
		children,
		prepend,
		append,
		before,
		after,
		rows = 3,
		disableAutoComplete = false,
		inputProps = {},
	}: InputProps,
	ref,
) {
	return (
		<div className={`${containerClassName ?? ''}`}>
			{label && (
				<label
					className={`text-sm ${error ? 'text-red-500' : ''} ${
						labelClassName ?? ''
					}`}
				>
					{label}
				</label>
			)}
			<div className="flex items-center">
				{prepend}
				<div className="flex-1 relative">
					{before}
					{React.createElement(
						textarea
							? 'textarea'
							: select
							? 'select'
							: disableAutoComplete
							? 'textarea'
							: 'input',
						{
							ref,
							wrap: 'off',
							className: `${
								disableAutoComplete
									? 'resize-none overflow-x-hidden'
									: ''
							} ${before ? 'pl-12' : ''} ${
								after ? 'pr-12' : ''
							}  bg-white focus:outline-none focus:shadow focus:border-gray-800 border border-gray-300 py-2 px-4 block w-full appearance-none leading-normal ${
								error ? 'border-red-500' : ''
							} ${disabled ? 'bg-gray-100' : ''} ${
								inputClassName ?? ''
							}`,
							type,
							placeholder,
							value: valueOrEmpty(value),
							onChange: (e) => {
								if (
									disableAutoComplete &&
									e.nativeEvent.inputType ===
										'insertLineBreak'
								) {
									e.preventDefault()
									return
								}

								onChange(e)
							},
							name,
							rows: disableAutoComplete ? 1 : rows,
							children,
							min,
							max,
							step,
							disabled,
							onKeyPress: (e) => {},
							...inputProps,
						},
					)}
					{select && !disabled && !after && children && (
						<InputInnerAddition
							position={InputAdditionPosition.End}
						>
							<ChevronDown size={18} className="text-gray-500" />
						</InputInnerAddition>
					)}
					{disabled && !after && (
						<InputInnerAddition
							position={InputAdditionPosition.End}
						>
							<Lock size={18} className="text-gray-500" />
						</InputInnerAddition>
					)}
					{after}
				</div>
				{append}
			</div>
			{error && errorText && (
				<div className={`text-xs text-red-500`}>{errorText}</div>
			)}
			{helperText && <div className={`text-xs`}>{helperText}</div>}
		</div>
	)
})
