import { HandleChangeFunction, useFormList } from '@polaritybit/use-form'
import { useEffect, useMemo, useState } from 'react'
import { File, Minus, Paperclip, Plus, Repeat, Video, Volume1 } from 'react-feather'

import { Therapy, TherapyStep } from '../../common/app.types'
import { Button } from '../../common/components/button.component'
import { Input } from '../../common/components/input.component'
import { PopForm } from '../../common/components/pop-form.component'
import { Tabs } from '../../common/components/tabs.component'
import { ProtocolFormAttachmentTab } from './protocol-form-attachment-tab.component'
import { ProtocolFormAudioTab } from './protocol-form-audio-tab.component'
import { ProtocolFormTextTab } from './protocol-form-text-tab.component'
import { ProtocolFormVideoTab } from './protocol-form-video-tab.component'
import { ProtocolPageSortStepsFormContainer } from './protocol-page-sort-steps-form.component'
import { sortBySeqNumber } from './protocol.util'

function makeUid() {
	return Math.floor(Math.random() * 10e9).toString(16)
}

export type ProtocolFormProps = {
	therapy: Therapy
	data: Therapy
	onChange: HandleChangeFunction<Therapy>
	onSave: (data: Therapy, options: any) => void
}

export function ProtocolForm({ therapy, data, onChange, onSave }: ProtocolFormProps) {
	const [selectedTab, setSelectedTab] = useState(0)
	const [steps, { onAdd: onAddStep, onRemove: onRemoveStep, onEdit: onEditStep }] = useFormList(
		[data, onChange],
		'steps',
		(i) => i.uid,
	)
	const [currentStepUid, setCurrentStepUid] = useState<string>()

	const currentStep = useMemo(() => {
		return steps?.find((s) => s.uid === currentStepUid)
	}, [steps, currentStepUid])

	useEffect(() => {
		if (!currentStepUid && therapy.steps.length > 0) {
			setCurrentStepUid(therapy.steps[0].uid)
		}
	}, [therapy, currentStepUid])

	const onChangeCurrentStep = useMemo(() => {
		return onEditStep.bind(undefined, currentStep) as HandleChangeFunction<TherapyStep>
	}, [currentStep, onEditStep])

	const [currentStepTexts, { onAdd: onAddText, onRemove: onRemoveText, onEdit: onEditText }] = useFormList(
		[currentStep, onChangeCurrentStep],
		'texts',
		(i) => i.uid,
	)

	const [currentStepAttachments, { onAdd: onAddAttachment, onRemove: onRemoveAttachment, onEdit: onEditAttachment }] =
		useFormList([currentStep, onChangeCurrentStep], 'attachments', (i) => i.uid)

	const [currentStepAudios, { onAdd: onAddAudio, onRemove: onRemoveAudio, onEdit: onEditAudio }] = useFormList(
		[currentStep, onChangeCurrentStep],
		'audios',
		(i) => i.uid,
	)

	const [currentStepVideos, { onAdd: onAddVideo, onRemove: onRemoveVideo, onEdit: onEditVideo }] = useFormList(
		[currentStep, onChangeCurrentStep],
		'videos',
		(i) => i.uid,
	)

	const sortedSteps = useMemo(() => {
		return sortBySeqNumber(steps)
	}, [steps])

	async function handleSortSteps({ steps }) {
		const updatedSteps = []
		for (const step in steps) {
			const stepData = steps[step]
			const updatedStep = { ...stepData, seq_number: parseInt(step) + 1 }
			updatedSteps.push(updatedStep)
		}

		onChange('steps', updatedSteps)
	}

	function handleSelectStep(e) {
		setCurrentStepUid(e.currentTarget.value)
		setSelectedTab(0)
	}

	function handleCreateStep() {
		const uid = makeUid()
		onAddStep({
			uid,
			seq_number: Math.max(0, ...steps.map((s) => s.seq_number)) + 1,
			texts: [],
			audios: [],
			videos: [],
			attachments: [],
		})

		setCurrentStepUid(uid)
	}

	function handleCreateText() {
		onAddText({
			uid: makeUid(),
			seq_number: Math.max(0, ...currentStepTexts.map((s) => s.seq_number)) + 1,
			text: '',
		})
	}

	function handleCreateVideo() {
		onAddVideo({
			uid: makeUid(),
			seq_number: Math.max(0, ...currentStepVideos.map((s) => s.seq_number)) + 1,
			name: '',
			url: '',
		})
	}

	function handleCreateAudio() {
		onAddAudio({
			uid: makeUid(),
			seq_number: Math.max(0, ...currentStepAudios.map((s) => s.seq_number)) + 1,
			title: '',
			storage_id: '',
		})
	}

	function handleCreateAttachment() {
		onAddAttachment({
			uid: makeUid(),
			seq_number: Math.max(0, ...currentStepAttachments.map((s) => s.seq_number)) + 1,
			title: '',
			storage_id: '',
		})
	}

	function handleSaveOnBlur() {
		onSave(data, { implicit: true })
	}

	return (
		<div className="my-4">
			<div className="grid grid-cols-1 lg:grid-cols-2">
				<Input onChange={handleSelectStep} value={currentStepUid} select disabled={steps.length === 0}>
					{steps.length === 0 && <option selected>Nessun passaggio</option>}
					{steps.length > 0 && <option value="">Seleziona un passaggio</option>}
					{sortedSteps.map((s, i) => {
						return (
							<option key={s.uid} value={s.uid}>
								Passaggio {i + 1}
							</option>
						)
					})}
				</Input>
				<div className="grid grid-cols-2 grid-flow-col-dense mb-4">
					<Button
						// className="text-green-600 font-bold w-full hover:bg-green-600 hover:text-white"
						variant="success"
						outline
						onClick={handleCreateStep}
					>
						<Plus size={18} className="mr-2" /> <span className="hidden xl:block">Aggiungi Passaggio</span>
					</Button>
					<PopForm
						title="Rimuovi Passaggio"
						form={() => {
							return <p>Vuoi davvero rimuovere il passaggio selezionato?</p>
						}}
						onConfirm={() => {
							if (currentStep) {
								onRemoveStep(currentStep)
								setCurrentStepUid('')
							}
						}}
						confirmVariant="danger"
						confirmLabel="Sì"
						cancelLabel="No"
						disabled={!currentStepUid}
						containerClassName="col-start-1 lg:col-start-2"
					>
						<Button className="w-full" variant="danger" outline disabled={!currentStepUid}>
							<Minus size={18} className="mr-2" />{' '}
							<span className="hidden xl:block">Rimuovi Passaggio</span>
						</Button>
					</PopForm>
					<PopForm
						title="Riordina Passaggi"
						form={(data, onChange) => (
							<ProtocolPageSortStepsFormContainer steps={sortedSteps} data={data} onChange={onChange} />
						)}
						onConfirm={handleSortSteps}
					>
						<Button className="w-full" variant="info" outline disabled={steps?.length < 2}>
							<Repeat size={18} className="mr-2" />{' '}
							<span className="hidden xl:block">Riordina Passaggi</span>
						</Button>
					</PopForm>
				</div>
			</div>
			{currentStepUid && (
				<div>
					<div className="border border-gray-100">
						<Tabs selectedTab={selectedTab} onSelectTab={setSelectedTab}>
							<Tabs.Tab title="Testi" icon={<File size={18} className="mr-2" />} />
							<Tabs.Tab title="Video" icon={<Video size={18} className="mr-2" />} />
							<Tabs.Tab title="Audio" icon={<Volume1 size={18} className="mr-2" />} />
							<Tabs.Tab title="Allegati" icon={<Paperclip size={18} className="mr-2" />} />
						</Tabs>
						{selectedTab === 0 && (
							<ProtocolFormTextTab
								texts={currentStepTexts}
								onCreateText={handleCreateText}
								onRemoveText={onRemoveText}
								onEditText={onEditText}
								onBlur={handleSaveOnBlur}
							/>
						)}
						{selectedTab === 1 && (
							<ProtocolFormVideoTab
								videos={currentStepVideos}
								onCreateVideo={handleCreateVideo}
								onRemoveVideo={onRemoveVideo}
								onEditVideo={onEditVideo}
								onBlur={handleSaveOnBlur}
							/>
						)}
						{selectedTab === 2 && (
							<ProtocolFormAudioTab
								audios={currentStepAudios}
								onCreateAudio={handleCreateAudio}
								onRemoveAudio={onRemoveAudio}
								onEditAudio={onEditAudio}
								onBlur={handleSaveOnBlur}
							/>
						)}
						{selectedTab === 3 && (
							<ProtocolFormAttachmentTab
								attachments={currentStepAttachments}
								onCreateAttachment={handleCreateAttachment}
								onRemoveAttachment={onRemoveAttachment}
								onEditAttachment={onEditAttachment}
								onBlur={handleSaveOnBlur}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	)
}
