import { useCallback, useState } from 'react'
import { ChevronRight, HelpCircle, Menu } from 'react-feather'
import { Outlet, Navigate } from 'react-router'

import { Drawer, DrawerDirection } from '../../common/components/drawer.component'
import { useAuth } from '../auth/hooks/use-auth.hook'
import { Help } from './help.component'
import { Sidebar } from './sidebar.component'

function useDrawerStatus(initialStatus: boolean = false): [boolean, { openDrawer; closeDrawer; toggleDrawer }] {
	const [isDrawerOpen, setIsDrawerOpen] = useState(initialStatus)

	const openDrawer = useCallback(() => {
		setIsDrawerOpen(true)
	}, [])

	const closeDrawer = useCallback(() => {
		setIsDrawerOpen(false)
	}, [])

	const toggleDrawer = useCallback(() => {
		setIsDrawerOpen((c) => !c)
	}, [])

	return [isDrawerOpen, { openDrawer, closeDrawer, toggleDrawer }]
}

export function Dashboard() {
	const [isDrawerOpen, handleDrawerStatus] = useDrawerStatus()
	const [isHelpDrawerOpen, handleHelpDrawerStatus] = useDrawerStatus()
	const [isHelpSidebarCollapsed, handleHelpSidebarCollapsedStatus] = useDrawerStatus(true)

	const [user] = useAuth()

	return (
		<div className="h-screen w-full flex flex-col bg-gray-50">
			{!user && <Navigate to="/login" />}
			<Drawer open={isDrawerOpen} onClose={handleDrawerStatus.closeDrawer} direction={DrawerDirection.Left}>
				<div className="h-full w-3/4 bg-white border-r border-gray-200 shadow fixed z-1">
					<Sidebar onNavigate={handleDrawerStatus.closeDrawer} />
				</div>
			</Drawer>
			<Drawer
				open={isHelpDrawerOpen}
				onClose={handleHelpDrawerStatus.closeDrawer}
				direction={DrawerDirection.Right}
			>
				<div className="h-full w-full bg-white border-r border-gray-200 shadow fixed z-1">
					<Help onClose={handleHelpDrawerStatus.closeDrawer} />
				</div>
			</Drawer>
			<div className="flex flex-1 w-full bg-white">
				<aside className="hidden lg:block w-70 h-full border-r bg-white">
					<Sidebar />
				</aside>
				<div className="flex-1 flex flex-col bg-white">
					<div className="grid grid-cols-4 md:hidden border-b p-4 bg-yellow-100">
						<div className="col-span-4 lg:col-span-3 flex items-center justify-between">
							<button
								onClick={handleDrawerStatus.openDrawer}
								className="block lg:hidden focus:outline-none"
							>
								<Menu className="text-main" />
							</button>
							<h1 className="text-md font-bold flex justify-center flex-1  text-main-dark">
								Portale del Professionista{' '}
							</h1>

							<button
								onClick={handleHelpDrawerStatus.openDrawer}
								className="block lg:hidden focus:outline-none"
							>
								<HelpCircle className="text-main" />
							</button>
						</div>
					</div>
					<div className="hidden lg:grid grid-cols-4">
						<div className="col-span-3"></div>
						<div className="col-span-1">
							<div
								onClick={handleHelpSidebarCollapsedStatus.toggleDrawer}
								className={`absolute  bg-white h-6 w-6 flex justify-center items-center rounded-full  z-10 transform ${
									isHelpSidebarCollapsed
										? 'right-4 top-3'
										: 'border shadow -translate-x-3 translate-y-4'
								} translate-y-1 cursor-pointer hover:bg-gray-100`}
							>
								{isHelpSidebarCollapsed ? <HelpCircle size={18} /> : <ChevronRight size={14} />}
							</div>
						</div>
					</div>

					<div className="grid grid-cols-4">
						<div
							className={`col-span-4 border-r ${
								isHelpSidebarCollapsed ? 'lg:col-span-4' : 'lg:col-span-3'
							}`}
						>
							<Outlet />
						</div>
						<div className={`hidden relative ${isHelpSidebarCollapsed ? '' : 'lg:block'} `}>
							<Help></Help>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
