export function getType(type) {
	switch (type) {
		case 'open': {
			return 'Libera'
		}

		case 'breathing': {
			return 'Respirazione'
		}

		case 'training': {
			return 'Registrazione'
		}

		default: {
			return ''
		}
	}
}
