import { useEffect } from 'react'
import { Book } from 'react-feather'
import { Input, InputAdditionPosition, InputInnerAddition } from '../../common/components/input.component'

export function CreateProtocolForm({ data, onChange, validationStatus }) {
	useEffect(() => {
		if (!data) {
			onChange(
				{
					name: '',
				},
				true,
			)
		}
	}, [data, onChange])

	return (
		<div>
			<p className="mb-2">Scegli un nome da assegnare al protocollo.</p>

			<Input
				name="name"
				disableAutoComplete
				value={data?.name}
				onChange={onChange}
				before={
					<InputInnerAddition position={InputAdditionPosition.Start}>
						<Book size={18} className="text-gray-500" />
					</InputInnerAddition>
				}
				label="Nome Protocollo"
				error={validationStatus.name?.error}
				errorText={validationStatus.error?.message}
			/>
		</div>
	)
}
