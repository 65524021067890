import { ArrowRightCircle } from 'react-feather'

import { Patient } from '../../common/app.types'
import { Input } from '../../common/components/input.component'
import { Table } from '../../common/components/table.component'
import { useFilter } from '../../common/hooks/use-filter.hook'
import { useForm } from '@polaritybit/use-form'
import { PatientItem } from './patient-item.component'

const PatientFilterRules = {
	search: (v, f, i) => {
		return !f || i.name?.toLowerCase().includes(f.toLowerCase()) || i.email?.toLowerCase().includes(f.toLowerCase())
	},
}

const PatientFilterDefaultValue = {
	search: '',
}

type PatientFilter = {
	search: string
}

type PatientListProps = {
	patients: Patient[]
	onPatientClick?: (patient: Patient) => void
	patientItemIcon?: (patient: Patient) => React.ReactNode
}

export function PatientList({ patients, onPatientClick, patientItemIcon }: PatientListProps) {
	const [filter, setFilter] = useForm<PatientFilter>(PatientFilterDefaultValue)
	const filteredPatients = useFilter(PatientFilterRules, filter, patients)

	function handlePatientClick(item) {
		onPatientClick?.(item)
	}

	return (
		<div>
			<div className="mb-8">
				<Input
					label="Cerca tra le persone..."
					placeholder="Scrivi il nome o l'email della persona"
					onChange={setFilter}
					name="search"
					value={filter.search}
					inputProps={{ autoComplete: 'off' }}
				></Input>
			</div>

			{filteredPatients.length === 0 && (
				<p className="text-center my-4 text-gray-600">Nessuna persona trovata.</p>
			)}

			<Table
				getItemKey={(p) => p.uid}
				items={filteredPatients}
				onRowClick={handlePatientClick}
				columns={[
					{
						key: 'mobile-view',
						title: '',
						cellClassName: 'block',
						headerClassName: 'hidden',
						getValue(item) {
							return <PatientItem patient={item} icon={patientItemIcon?.(item) ?? <ArrowRightCircle />} />
						},
					},
				]}
			/>
		</div>
	)
}
