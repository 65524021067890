import { Heart, Mail } from 'react-feather'

import { Button } from '../../common/components/button.component'
import { PopForm } from '../../common/components/pop-form.component'
import { Table } from '../../common/components/table.component'
import { PatientPageInviteDoctorForm } from './patient-page-invite-doctor-form.component'

const InviteDoctorValidationRules = {
	email: (v) => v && v.length > 0,
}

export function PatientPageDoctors({ patient, onInvite }) {
	return (
		<div>
			{patient.doctors.length === 0 && (
				<p className="text-center text-gray-600 p-4">
					Nessun altro professionista ha accesso a questa persona.
				</p>
			)}

			<Table
				getItemKey={(p) => p.uid}
				items={patient.doctors}
				columns={[
					{
						key: 'mobile-view',
						title: '',
						cellClassName: 'block',
						headerClassName: 'hidden',
						getValue(item) {
							return (
								<div className="select-none border border-gray-100 bg-white shadow p-4 mb-4 lg:hover:bg-main lg:hover:text-white lg:cursor-pointer">
									<div className="flex items-center justify-between">
										<div className="mr-4">
											<Heart />
										</div>
										<div className="flex-1">
											<p className="font-bold overflow-ellipsis">{item.email}</p>
											{!item.pending && <p className="italic overflow-ellipsis">{item.name}</p>}
											{item.pending && (
												<p className="text-gray-400">Invito in attesa di accettazione...</p>
											)}
										</div>
										<div className="ml-4"></div>
									</div>
								</div>
							)
						},
					},
				]}
			/>
			<PopForm
				title="Invita un Professionista"
				icon={<Mail className="mr-2" size={18} />}
				form={(data, onChange, validationStatus) => {
					return (
						<PatientPageInviteDoctorForm
							data={data}
							onChange={onChange}
							validationStatus={validationStatus}
						/>
					)
				}}
				containerClassName="block w-full lg:w-auto text-right mb-4"
				validationRules={InviteDoctorValidationRules}
				onConfirm={onInvite}
			>
				<Button variant="success" className="w-full lg:w-auto">
					<Mail className="mr-2" size={18} /> Invita un Professionista
				</Button>
			</PopForm>
		</div>
	)
}
