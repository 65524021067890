import { useEffect } from 'react'
import { Input } from '../../common/components/input.component'
import { useTherapies } from './hooks/use-therapies.hook'

export function SwitchProtocolForm({ group, data, onChange }) {
	const [therapies, , therapiesLoadingStatus] = useTherapies()

	useEffect(() => {
		if (group) {
			onChange(group, true)
		}
	}, [group, onChange])

	if (therapiesLoadingStatus.loading) {
		return null
	}

	return (
		<Input
			name="therapy_id"
			label="Seleziona Protocollo"
			onChange={onChange}
			value={data?.therapy_id}
			disabled={therapies.length === 0}
			select
		>
			<option value="">Nessun Protocollo</option>
			{therapies.map((t) => {
				return (
					<option key={t.uid} value={t.uid}>
						{t.name}
					</option>
				)
			})}
		</Input>
	)
}
