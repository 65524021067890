import { XCircle } from 'react-feather'
import { Route, Routes } from 'react-router'

import { ProfilePageHelp } from '../auth/profile-page-help.component'
import { GroupPageHelp } from '../groups/group-page-help.component'
import { GroupsHelp } from '../groups/groups-help.component'
import { ProtocolPageHelp } from '../groups/protocol-page-help.component'
import { PatientPageHelp } from '../patients/patient-page-help.component'
import { PatientsHelp } from '../patients/patients-help.component'
import { UserPageHelp } from '../users/user-page-help.component'
import { UsersHelp } from '../users/users-help.component'

export type HelpProps = {
	onClose?: any
}

export function Help({ onClose }: HelpProps) {
	return (
		<div className={`h-screen bg-main-very-light relative overflow-x-hidden`}>
			<div className="lg:hidden flex items-center justify-between bg-main-light p-3">
				<div></div>
				<h1 className="text-lg font-bold text-center text-main-very-light">AIUTO</h1>

				<button onClick={onClose} className="block lg:hidden focus:outline-none ">
					<XCircle className="text-main-very-light" />
				</button>
			</div>

			<div className="bg-main-very-light p-4">
				<Routes>
					<Route path="/patients/:id" element={<PatientPageHelp />} />
					<Route path="/patients" element={<PatientsHelp />} />

					<Route path="/groups/:id/protocol" element={<ProtocolPageHelp />} />
					<Route path="/groups/:id" element={<GroupPageHelp />} />
					<Route path="/groups" element={<GroupsHelp />} />
					<Route path="/profile" element={<ProfilePageHelp />} />
					<Route path="/users/:id" element={<UserPageHelp />} />
					<Route path="/users" element={<UsersHelp />} />
				</Routes>
			</div>
		</div>
	)
}
