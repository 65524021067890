import { useMemo } from 'react'
import { Plus, Trash } from 'react-feather'
import { Button } from '../../common/components/button.component'
import { HtmlEditor } from '../../common/components/html-editor.component'
import { sortBySeqNumber } from './protocol.util'

export function ProtocolFormTextTab({ texts, onCreateText, onRemoveText, onEditText, onBlur }) {
	const sortedTexts = useMemo(() => {
		return sortBySeqNumber(texts)
	}, [texts])

	if (!texts) {
		return null
	}

	return (
		<div className="bg-white">
			{texts?.length === 0 && (
				<p className="p-4 text-center text-gray-500">Non sono presenti testi in questo passaggio</p>
			)}

			{sortedTexts.map((text) => (
				<div key={text.uid} className="mb-4 p-4 border-t border-gray-300">
					<div className="flex justify-between items-center mb-5">
						<span className="uppercase">Contenuto Testuale:</span>
						<Button variant="danger" size="md" onClick={() => onRemoveText(text)}>
							<Trash size={14} />
						</Button>
					</div>
					<HtmlEditor
						value={text.text}
						onChange={(t) => {
							if (t === text.text) {
								return
							}

							onEditText(text, { text: t })
						}}
						onBlur={onBlur}
					/>
				</div>
			))}
			<div className="flex justify-end mb-2 p-4">
				<Button onClick={onCreateText} variant="success" className="w-full lg:w-auto">
					<Plus size={18} className="mr-2" /> Aggiungi Nuovo Testo
				</Button>
			</div>
		</div>
	)
}
