import { useEffect, useRef } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const ItemTypes = {
	STEP: 'step',
}

function SortableListItem({ item, index, onSwap }) {
	const ref = useRef(null)

	const [{ isDragging }, drag] = useDrag({
		type: ItemTypes.STEP,
		item: item,
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	})

	const [{ isOver }, drop] = useDrop({
		accept: ItemTypes.STEP,
		collect: (monitor) => {
			return {
				isOver: monitor.isOver(),
			}
		},
		drop(item, monitor) {
			if (!ref.current) {
				return
			}

			const dragIndex = (item as any).index
			const hoverIndex = index

			if (dragIndex === hoverIndex) {
				return
			}

			onSwap(dragIndex, hoverIndex)
		},
	})

	drag(drop(ref))

	return (
		<div
			key={item.step.uid}
			ref={ref}
			className={`flex cursor-move items-center justify-between px-4 py-2 my-2 border border-gray-200 ${
				isDragging ? 'bg-gray-100' : isOver ? 'bg-yellow-100' : ''
			}`}
		>
			<span className="font-bold">Passaggio {item.step.seq_number}</span>
		</div>
	)
}

export function ProtocolPageSortStepsFormContainer({ steps, data, onChange }) {
	useEffect(() => {
		onChange({ steps }, true)
	}, [steps, onChange])

	function handleUpdateSteps(steps) {
		onChange({ steps })
	}

	if (!data) {
		return null
	}

	return (
		<DndProvider backend={HTML5Backend}>
			<ProtocolPageSortStepsForm steps={data.steps} onUpdateSteps={handleUpdateSteps} />
		</DndProvider>
	)
}

export function ProtocolPageSortStepsForm({ steps, onUpdateSteps }) {
	function handleSwap(dragIndex, hoverIndex) {
		if (typeof dragIndex === 'undefined') {
			return
		}

		if (typeof hoverIndex === 'undefined') {
			return
		}

		const updatedSteps = []
		for (const i in steps) {
			if (+i === dragIndex) {
				updatedSteps.push(steps[hoverIndex])
			} else if (+i === hoverIndex) {
				updatedSteps.push(steps[dragIndex])
			} else {
				updatedSteps.push(steps[i])
			}
		}

		onUpdateSteps(updatedSteps)
	}

	return (
		<div className="flex flex-col">
			{steps.map((step, index) => (
				<SortableListItem
					key={step.uid}
					item={{
						index,
						step,
					}}
					index={index}
					onSwap={handleSwap}
				/>
			))}
		</div>
	)
}
