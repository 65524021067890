import { useState } from 'react'
import { Key, Mail } from 'react-feather'
import toast from 'react-hot-toast'
import { Navigate } from 'react-router'
import { Link } from 'react-router-dom'
import { ReactComponent as GoogleIcon } from '../../common/resources/google.svg'

import { Button } from '../../common/components/button.component'
import { Input, InputAdditionPosition, InputInnerAddition } from '../../common/components/input.component'
import { Logo } from '../../common/components/logo.component'
import { useForm } from '@polaritybit/use-form'
import { useAuth } from './hooks/use-auth.hook'

export function Login() {
	const [data, onChange] = useForm({ email: '', password: '' })
	const [user, authHandlers] = useAuth()
	const [loading, setLoading] = useState(false)

	async function handleLoginWithGoogle() {
		try {
			setLoading(true)

			await authHandlers.onGoogleLogin()

			toast.success('Benvenuto!')
		} catch (error) {
			toast.error("Si è verificato un errore durante l'accesso. Verificare i dati inseriti e riprovare.")
		} finally {
			setLoading(false)
		}
	}

	async function handleLogin(event) {
		setLoading(true)
		event.preventDefault()
		event.stopPropagation()

		try {
			await authHandlers.onLogin(data)

			toast.success('Benvenuto!')
		} catch (error) {
			toast.error("Si è verificato un errore durante l'accesso. Verificare i dati inseriti e riprovare.")
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className="h-screen w-full lg:w-1/3 lg:mx-auto flex items-center justify-center flex-col p-4">
			{user && <Navigate to="/" />}
			<Logo />
			<h1 className="my-4 font-bold text-3xl text-main-light text-center">Portale del Professionista</h1>
			<form className="lg:border border-gray-100 lg:shadow p-4 mt-8 w-full" onSubmit={handleLogin}>
				<h2 className="text-xl mb-2 font-bold text-main-dark">Effettua l'accesso</h2>

				<Input
					before={
						<InputInnerAddition position={InputAdditionPosition.Start}>
							<Mail size={18} className="text-gray-500" />
						</InputInnerAddition>
					}
					label="Email"
					value={data.email}
					onChange={onChange}
					name="email"
				/>
				<Input
					before={
						<InputInnerAddition position={InputAdditionPosition.Start}>
							<Key size={18} className="text-gray-500" />
						</InputInnerAddition>
					}
					label="Password"
					type="password"
					value={data.password}
					onChange={onChange}
					name="password"
				/>
				<div className="flex mt-4 flex-row w-full justify-between">
					<Link to="/recover" className="p-2 link">
						Recupera Password
					</Link>
					<Button loading={loading} variant="success" type="submit">
						Accedi
					</Button>
				</div>
			</form>
			<div className="my-4">
				Non hai un account?{' '}
				<Link to="/signup" className="link">
					Registrati ora!
				</Link>
			</div>
			<div className="my-4">
				<Button variant="info" onClick={handleLoginWithGoogle}>
					<GoogleIcon /> Accedi con Google
				</Button>
			</div>
			<div className="flex-1"></div>
			<div className="text-xs">
				<p>&copy; 2021 Mind &amp; Move SRL</p>
				<p>
					<a
						className="link"
						href="https://www.mindtomove.it"
						rel="nofollow noopener noreferrer"
						target="_blank"
					>
						https://www.mindtomove.it
					</a>
				</p>
			</div>
		</div>
	)
}
