export function GroupPageHelp() {
	return (
		<div>
			<h4 className="uppercase pb-4 border-b-2 border-main-dark mb-4 font-semibold">
				Pagina Gruppo
			</h4>
			<div className="text-gray-700">
				<p className="pb-2">
					Visualizzazione del gruppo. La pagina è divisa in due
					sezioni: <strong>Partecipanti</strong> e{' '}
					<strong>Evoluzioni</strong>.
				</p>
				<p className="pb-2">
					La sezione <strong>Partecipanti</strong> contiene un elenco
					di tutti i partecipanti al gruppo. È possibile selezionare
					un partecipante specifico per mostrarne i dettagli.
				</p>
				<p className="pb-2">
					La sezione <strong>Evoluzioni</strong> mostra in formato
					grafico tutte le letture effettuate dai partecipanti del
					gruppo.
				</p>
				<p className="pb-2">
					Utilizzando il pulsante{' '}
					<strong className="uppercase bold text-main">
						'MODIFICA GRUPPO'
					</strong>{' '}
					puoi modificare le informazioni del gruppo e aggiungere o
					rimuovere partecipanti.
				</p>
				<p className="pb-2">
					Utilizzando il pulsante{' '}
					<strong className="uppercase bold text-main">
						'GESTISCI PROTOCOLLO'
					</strong>{' '}
					puoi accedere all gestione del protocollo per il gruppo
					selezionato.
				</p>
			</div>
		</div>
	)
}
