import joi from 'joi'
import { useEffect, useState } from 'react'
import { Key, Mail, User } from 'react-feather'
import toast from 'react-hot-toast'
import { Navigate, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { SignupDto } from '../../common/app.types'

import { Button } from '../../common/components/button.component'
import { Checkbox } from '../../common/components/checkbox.component'
import { Input, InputAdditionPosition, InputInnerAddition } from '../../common/components/input.component'
import { Logo } from '../../common/components/logo.component'
import { useForm } from '@polaritybit/use-form'
import { useValidation } from '../../common/hooks/use-validation.hook'
import { useAuth } from './hooks/use-auth.hook'

const SignupValidationRules = {
	name: (v) => v && v.length >= 3,
	email: (v) => {
		try {
			joi.assert(
				v,
				joi
					.string()
					.email({ tlds: { allow: false } })
					.required(),
			)
			return true
		} catch (err) {
			console.log(err)
			return false
		}
	},
	password: (v) => v && v.length >= 8,
	passwordConfirm: [
		{
			rule: (v, d) => v && v === d.password,
			message: 'Le password non coincidono',
		},
	],
	acceptTerms: (v) => v === true,
	acceptPrivacy: (v) => v === true,
}

export function Signup() {
	const [data, onChange] = useForm<SignupDto>({
		email: '',
		password: '',
		passwordConfirm: '',
		acceptTerms: false,
		acceptPrivacy: false,
		acceptMarketing: false,
	})
	const [user, authHandlers] = useAuth()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	const [validationStatus, { validate, resetValidationStatus }] = useValidation(SignupValidationRules)

	useEffect(() => {
		resetValidationStatus()
	}, [data, resetValidationStatus])

	async function handleSignup(event) {
		setLoading(true)
		event.preventDefault()
		event.stopPropagation()

		if (!validate(data)) {
			return
		}

		try {
			await authHandlers.onSignup(data)

			toast.success(
				"Registrazione avvenuta correttamente. Riceverai a breve una email con un link da seguire per verificare il tuo indirizzo email. Potrai effettuare l'accesso dopo la verifica.",
			)

			navigate('/login')
		} catch (error) {
			console.log(error)

			toast.error("Si è verificato un errore durante l'accesso. Verificare i dati inseriti e riprovare.")
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className="h-screen w-full lg:w-1/3 lg:mx-auto flex items-center justify-center flex-col p-4">
			{user && <Navigate to="/" />}

			<Logo />

			<form className="lg:border border-gray-100 lg:shadow p-4 mt-8 w-full" onSubmit={handleSignup}>
				<h2 className="text-xl mb-2 font-bold">Nuovo Account</h2>
				<p className="my-2 text-gray-500">
					<strong>Attenzione!</strong> Questo portale è riservato ai professionisti. Se sei una persona,
					utilizza l'app per creare un account!
				</p>

				<Input
					before={
						<InputInnerAddition position={InputAdditionPosition.Start}>
							<User size={18} className="text-gray-500" />
						</InputInnerAddition>
					}
					label="Nome"
					value={data.name}
					onChange={onChange}
					name="name"
					error={validationStatus.name?.error}
					errorText={validationStatus.name?.message}
				/>
				<Input
					before={
						<InputInnerAddition position={InputAdditionPosition.Start}>
							<Mail size={18} className="text-gray-500" />
						</InputInnerAddition>
					}
					label="Email"
					value={data.email}
					onChange={onChange}
					name="email"
					error={validationStatus.email?.error}
					errorText={validationStatus.email?.message}
				/>
				<Input
					before={
						<InputInnerAddition position={InputAdditionPosition.Start}>
							<Key size={18} className="text-gray-500" />
						</InputInnerAddition>
					}
					label="Password"
					type="password"
					value={data.password}
					onChange={onChange}
					name="password"
					error={validationStatus.password?.error}
					errorText={validationStatus.password?.message}
				/>
				<Input
					before={
						<InputInnerAddition position={InputAdditionPosition.Start}>
							<Key size={18} className="text-gray-500" />
						</InputInnerAddition>
					}
					label="Ripeti Password"
					type="password"
					value={data.passwordConfirm}
					onChange={onChange}
					name="passwordConfirm"
					error={validationStatus.passwordConfirm?.error}
					errorText={validationStatus.passwordConfirm?.message}
				/>
				<Checkbox
					className="my-2"
					name="acceptTerms"
					onChange={onChange}
					checked={data.acceptTerms}
					error={validationStatus.acceptTerms?.error}
					errorText={validationStatus.acceptTerms?.message}
					label={
						<>
							Ho letto e accetto i{' '}
							<a href="/terms" className="link" target="_blank" rel="noopener nofollow noreferrer">
								termini e le condizioni d'uso del servizio
							</a>
						</>
					}
				/>
				<Checkbox
					className="my-2"
					name="acceptPrivacy"
					onChange={onChange}
					checked={data.acceptPrivacy}
					error={validationStatus.acceptPrivacy?.error}
					errorText={validationStatus.acceptPrivacy?.message}
					label={
						<>
							Ho preso visione della{' '}
							<a href="/privacy" className="link" target="_blank" rel="noopener nofollow noreferrer">
								privacy policy
							</a>
						</>
					}
				/>
				<Checkbox
					name="acceptMarketing"
					onChange={onChange}
					checked={data.acceptMarketing}
					className="my-2"
					label={<>Desidero essere contattato per finalità di marketing</>}
				/>
				<div className="flex mt-4 flex-row w-full justify-between">
					<Button loading={loading} block variant="success" type="submit">
						Crea Account
					</Button>
				</div>
			</form>
			<div className="my-4">
				Hai già un account?{' '}
				<Link to="/login" className="link">
					Effettua l'accesso!
				</Link>
			</div>
			<div className="flex-1"></div>
			<div className="text-xs">
				<p>&copy; 2021 Mind &amp; Move SRL</p>
				<p>
					<a
						className="link"
						href="https://www.mindtomove.it"
						rel="nofollow noopener noreferrer"
						target="_blank"
					>
						https://www.mindtomove.it
					</a>
				</p>
			</div>
		</div>
	)
}
