type CheckboxProps = {
	label?: React.ReactNode
	name?: string
	checked?: boolean
	onChange?: any
	disabled?: boolean
	className?: string
	error?: boolean
	errorText?: React.ReactNode
	preventBubbling?: boolean
	inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
}

export function Checkbox({
	label,
	checked,
	name,
	onChange,
	disabled,
	className,
	error,
	errorText,
	preventBubbling = true,
	inputProps,
}: CheckboxProps) {
	return (
		<div
			onClick={(event) => {
				if (preventBubbling) {
					event.stopPropagation()
				}
			}}
		>
			<label className={`cursor-pointer flex items-center ${className ?? ''}`}>
				<span className="flex flex-col justify-center">
					<input
						name={name}
						checked={checked}
						onChange={onChange}
						disabled={disabled}
						type="checkbox"
						className={`form-tick cursor-pointer transition-colors duration-150 select-none bg-white appearance-none h-5 w-5 mr-2 border ${
							error ? 'border-red-500' : 'border-gray-300'
						} ${
							disabled ? 'checked:bg-blue-200' : 'checked:bg-main'
						} checked:border-transparent focus:outline-none`}
						{...inputProps}
					/>
				</span>
				<span className={`${error ? 'text-red-500' : disabled ? 'text-gray-500' : ''} select-none`}>
					{label}
				</span>
			</label>
		</div>
	)
}
