import { format } from 'date-fns'
import numeral from 'numeral'
import { useMemo } from 'react'
import { CartesianGrid, ComposedChart, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis } from 'recharts'
import { useStableCallbackReference } from '../../common/hooks/use-stable-callback-reference.hook'

type PatientChartProps<T> = {
	title?: string
	items: T[]
	getValue: (v: T) => number
	getTime: (v: T) => number
	label: string
	tooltipUnit?: string
}

export function PatientChart<T>({ title, getValue, getTime, items, label, tooltipUnit = '' }: PatientChartProps<T>) {
	const stableGetValue = useStableCallbackReference(getValue)
	const stableGetTime = useStableCallbackReference(getTime)

	const scoreChartData = useMemo(() => {
		if (!items) {
			return []
		}

		return items.map((item) => {
			return {
				time: stableGetTime(item),
				value: stableGetValue(item),
			}
		})
	}, [stableGetValue, stableGetTime, items])

	if (!items) {
		return null
	}

	return (
		<div className="bg-white py-4">
			{title && <p className="text-center font-bold mb-2 text-xl text-main">{title}</p>}
			<ResponsiveContainer height={300} width="100%">
				<ComposedChart data={scoreChartData}>
					<CartesianGrid />
					<XAxis
						dataKey="time"
						scale="time"
						type="number"
						domain={['dataMin - 100000000', 'dataMax + 100000000']}
						tickFormatter={(t) => format(new Date(t), 'dd/MM/yyyy')}
						style={{ fontSize: 12 }}
						height={16}
					/>
					{/* <YAxis /> */}
					<ReferenceLine y={0} strokeWidth={2} stroke={'#333'} />
					<Line
						type="monotone"
						dataKey="value"
						width={2}
						stroke="#1E3A8A"
						strokeWidth={2}
						dot={true}
						isAnimationActive={false}
					/>
					<Tooltip
						labelFormatter={(value) => format(new Date(value), 'dd/MM/yyyy')}
						formatter={(value, name) => [`${numeral(value).format('0,0.00')} ${tooltipUnit}`, label]}
					/>
				</ComposedChart>
			</ResponsiveContainer>
		</div>
	)
}
