import React, { useMemo } from 'react'
import { createPortal } from 'react-dom'

import { useDelayedRender } from '../hooks/use-delayed-render.hook'

export enum DrawerDirection {
	Left,
	Right,
	Top,
	Bottom,
}

export enum DrawerAnimationDuration {
	MS75 = 75,
	MS100 = 100,
	MS150 = 150,
	MS200 = 200,
	MS300 = 300,
	MS500 = 500,
	MS700 = 700,
	MS1000 = 1000,
}

export type DrawerProps = {
	open: boolean
	onClose: () => void
	children: React.ReactNode
	direction?: DrawerDirection
	animationDuration?: DrawerAnimationDuration
	className?: string
}

export const Drawer = function ({
	open,
	onClose,
	children,
	direction = DrawerDirection.Left,
	animationDuration = DrawerAnimationDuration.MS500,
	className = '',
}: DrawerProps) {
	const [positionClassName, openClassName, closedClassName] = useMemo(() => {
		switch (direction) {
			case DrawerDirection.Bottom: {
				return [
					'bottom-0 left-0 w-screen h-screen sm:h-64',
					'translate-y-0',
					'translate-y-full',
				]
			}
			case DrawerDirection.Top: {
				return [
					'top-0 left-0 w-screen h-screen sm:h-64',
					'translate-y-0',
					'-translate-y-full',
				]
			}
			case DrawerDirection.Right: {
				return [
					'top-0 right-0 w-screen sm:w-64 h-screen',
					'translate-x-0',
					'translate-x-full',
				]
			}
			case DrawerDirection.Left:
			default: {
				return [
					'top-0 left-0 w-screen sm:w-64 h-screen',
					'translate-x-0',
					'-translate-x-full',
				]
			}
		}
	}, [direction])

	const [shouldRender, isVisible] = useDelayedRender(open, animationDuration)

	if (!shouldRender) {
		return null
	}

	return createPortal(
		<>
			<div
				onClick={onClose}
				className={`transform duration-${animationDuration} fixed z-10 top-0 left-0 w-screen h-screen bg-black ${
					isVisible ? 'opacity-25' : 'opacity-0'
				} ${className}`}
			></div>

			<aside
				className={`fixed z-20 bg-darker pointer-events-none transform duration-${animationDuration} ${positionClassName} ${
					isVisible ? openClassName : closedClassName
				} ${className}`}
			>
				<div className="pointer-events-auto">{children}</div>
			</aside>
		</>,
		document.body,
	)
}
