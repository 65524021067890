import firebase from 'firebase'
import { createContext, useCallback, useEffect, useState } from 'react'
import { setToken } from '../../../../core/server.core'

export const AuthContext = createContext(null)

export function AuthContextProvider({ children }) {
	const [currentUser, setCurrentUser] = useState<firebase.User>(firebase.auth().currentUser)

	const onAuthStateChanged = useCallback(async function onAuthStateChanged(user: firebase.User) {
		if (user) {
			if (user.emailVerified) {
				const token = await user.getIdToken(true)

				setToken(token)
				setCurrentUser(user)
			} else {
				firebase.auth().signOut()
				setCurrentUser(null)
			}
		} else {
			setCurrentUser(null)
		}
	}, [])

	useEffect(() => {
		const unsubscribe = firebase.auth().onAuthStateChanged(onAuthStateChanged)

		return () => {
			unsubscribe?.()
		}
	}, [onAuthStateChanged])

	return <AuthContext.Provider value={currentUser}>{children}</AuthContext.Provider>
}
