export function PatientPageHelp() {
	return (
		<div>
			<h4 className="uppercase pb-4 border-b-2 border-main-dark mb-4 font-semibold">Pagina Persona</h4>
			<div className="text-gray-700">
				<p className="pb-2">
					Questa pagina contiene le informazioni sulla persona selezionata. Puoi visualizzare le{' '}
					<strong>Misure</strong> che ha effettuato, le letture di <strong>Peso</strong> e{' '}
					<strong>Intervalli di Respirazione</strong> e associare <strong>Tag</strong> e{' '}
					<strong>Respirazioni</strong> alla persona, oltre che modificare quelle esistenti.
				</p>
				<p className="pb-2">
					Utilizzando il tab <strong>Professionisti</strong> puoi invitare altri professionisti a collaborare
					a questa persona, o visualizzare i professionisti che già hanno accesso alla persona.
				</p>
				<p className="pb-2">
					Utilizza il pulsante <strong className="uppercase bold text-main">'NOTE'</strong> per visualizzare
					le note che sono state inserite su questa persona. Le note sono condivise tra tutti i professionisti
					che partecipano alla persona, ma non sono visibili alla persona stessa.
				</p>
				<p className="pb-2">
					Utilizza il pulsante <strong className="uppercase bold text-main">'TEMPO REALE'</strong> per
					visualizzare la sessione di registrazione che la persona sta seguendo in questo momento, se ce n'è
					una in corso.
				</p>
			</div>
		</div>
	)
}
