import Joi from 'joi'
import { useEffect, useState } from 'react'
import { Mail, Save, Hash } from 'react-feather'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import { server } from '../../../core/server.core'
import { User } from '../../common/app.types'
import { Box } from '../../common/components/box.component'
import { Breadcrumbs } from '../../common/components/breadcrumbs.component'
import { Button } from '../../common/components/button.component'
import { Checkbox } from '../../common/components/checkbox.component'
import { Input, InputAddition, InputAdditionPosition } from '../../common/components/input.component'
import { Spinner } from '../../common/components/spinner.component'
import { Tooltip } from '../../common/components/tooltip.component'
import { useForm, useNestedForm } from '@polaritybit/use-form'
import { useValidation } from '../../common/hooks/use-validation.hook'
import { useUser } from './hooks/use-user.hook'

enum LoadingStatus {
	ResetPassword,
	UpdateUser,
}

function makeId(length: number) {
	var result = ''
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
	var charactersLength = characters.length
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}
	return result
}

const ProfileValidationRules = {
	name: [
		{
			rule: (v) => v && v.length >= 3,
			message: 'Il nome deve essere lungo almeno 3 caratteri',
		},
	],
	email: [
		{
			rule: function (v) {
				try {
					Joi.assert(
						v,
						Joi.string().email({
							tlds: { allow: false },
						}),
					)

					return true
				} catch (error) {
					return false
				}
			},
			message: 'Email non valida',
		},
	],
	idCode: [
		{
			rule: function (v) {
				if (!v || v.length === 0) {
					return true
				}

				return v.length === 6
			},
			message: 'Il codice deve essere lungo 6 caratteri',
		},
	],
	gender: (v) => v && typeof v !== 'undefined' && v !== null,
	age: (v) => v && typeof v !== 'undefined' && v !== null,
}

export function UserPage() {
	const params = useParams<{ id: string }>()
	const [loading, setLoading] = useState<LoadingStatus>(null)
	const [user, fetchUser, userLoadingStatus] = useUser(params.id)
	const [data, onChange] = useForm<User>(null, (data) => {
		return {
			...data,
			idCode: data?.idCode?.toUpperCase(),
		}
	})
	const [claims, setClaims] = useNestedForm([data, onChange], 'claims')
	const [validationStatus, { validate, resetValidationStatus }] = useValidation(ProfileValidationRules)

	useEffect(() => {
		if (user) {
			onChange(user, true)
		}
	}, [user, onChange])

	useEffect(() => {
		resetValidationStatus()
	}, [data, resetValidationStatus])

	if (userLoadingStatus.loading) {
		return (
			<div className="w-full h-64 flex justify-center items-center">
				<Spinner margin="" color="text-black" />
			</div>
		)
	}

	async function handleUpdateProfile() {
		if (!validate(data)) {
			return
		}

		setLoading(LoadingStatus.UpdateUser)

		try {
			await server().post(`/users/${params.id}`, data)
			await fetchUser()

			toast.success('Utente aggiornato correttamente!')
		} catch (error) {
			console.log(error)
			toast.error(`Si è verificato un errore durante l'aggiornamento del profilo: ${error}`)
		} finally {
			setLoading(null)
		}
	}

	async function handleResetPassword() {
		try {
			setLoading(LoadingStatus.ResetPassword)
			await server().get(`/reset`, {
				params: { email: user.email },
			})

			toast.success('Email di reset password inviata correttamente!')
		} catch (error) {
			console.log(error)
			toast.error(`Si è verificato un errore durante la richiesta di reimpostazione password: ${error}`)
		} finally {
			setLoading(null)
		}
	}

	return (
		<div>
			<Breadcrumbs
				items={[
					{
						title: 'Gestione Utenti',
						link: '/users',
					},
					{ title: user?.email, link: '' },
				]}
			/>
			<Box className="lg:mx-4" title="Gestione Utente" bodyClassName="mx-4">
				<div className="mb-2">
					<div className="mb-2 text-sm">Tipo di utenza</div>
					<div className="grid grid-cols-1 lg:grid-cols-3">
						<Checkbox label="Amministratore" name="admin" checked={claims.admin} onChange={setClaims} />
						<Checkbox
							label="Professionista (Abbonato)"
							name="doctor"
							checked={claims.doctor}
							onChange={setClaims}
						/>
					</div>
				</div>
				<div className={`grid grid-cols-1 lg:grid-cols-3 gap-2`}>
					<Input
						name="name"
						onChange={onChange}
						value={data.name}
						label="Nome"
						disableAutoComplete
						error={validationStatus.name?.error}
						errorText={validationStatus.name?.message}
					/>
					<Input
						disabled
						name="email"
						onChange={onChange}
						value={data.email}
						label="Indirizzo Email"
						disableAutoComplete
						error={validationStatus.email?.error}
						errorText={validationStatus.email?.message}
					/>
					{user?.idCode && (
						<Input
							disabled
							name="idCode"
							onChange={onChange}
							value={data.idCode}
							label="Codice Professionista"
							disableAutoComplete
							error={validationStatus.idCode?.error}
							errorText={validationStatus.idCode?.message}
						/>
					)}
					{!user.idCode && (claims.doctor || claims.admin) && (
						<Input
							name="idCode"
							onChange={onChange}
							value={data.idCode}
							inputProps={{
								maxLength: 6,
							}}
							label="Codice Professionista"
							disableAutoComplete
							error={validationStatus.idCode?.error}
							errorText={validationStatus.idCode?.message}
							append={
								<InputAddition position={InputAdditionPosition.End} condensed>
									<Tooltip content="Genera">
										<Button variant="primary" onClick={() => onChange('idCode', makeId(6))}>
											<Hash size={18} />
										</Button>
									</Tooltip>
								</InputAddition>
							}
						/>
					)}
				</div>
				<div className="grid grid-cols-1 lg:grid-cols-4 gap-2">
					<Input
						name="age"
						onChange={onChange}
						value={data.age}
						label="Età"
						type="number"
						error={validationStatus.age?.error}
						errorText={validationStatus.age?.message}
					/>
					<Input
						name="gender"
						onChange={onChange}
						value={data.gender}
						label="Sesso"
						select
						error={validationStatus.gender?.error}
						errorText={validationStatus.gender?.message}
					>
						<option value="">Seleziona...</option>
						<option value="female">Femmina</option>
						<option value="male">Maschio</option>
						<option value="nonbinary">Non Binario</option>
					</Input>
				</div>
				<div>
					<Checkbox
						className="my-2"
						name="acceptTerms"
						disabled={user.acceptTerms}
						onChange={onChange}
						checked={data.acceptTerms}
						error={validationStatus.acceptTerms?.error}
						errorText={validationStatus.acceptTerms?.message}
						label={
							<>
								Accetta i{' '}
								<a
									href="https://app.mindtomove.it/terms"
									className="link"
									target="_blank"
									rel="noopener nofollow noreferrer"
								>
									termini e le condizioni d'uso del servizio
								</a>
							</>
						}
					/>
					<Checkbox
						className="my-2"
						name="acceptPrivacy"
						disabled={user.acceptPrivacy}
						onChange={onChange}
						checked={data.acceptPrivacy}
						error={validationStatus.acceptPrivacy?.error}
						errorText={validationStatus.acceptPrivacy?.message}
						label={
							<>
								Ha preso visione della{' '}
								<a
									href="https://app.mindtomove.it/privacy"
									className="link"
									target="_blank"
									rel="noopener nofollow noreferrer"
								>
									privacy policy
								</a>
							</>
						}
					/>
					<Checkbox
						name="acceptMarketing"
						onChange={onChange}
						checked={data.acceptMarketing}
						className="my-2"
						label={<>Desidera essere contattato per finalità di marketing</>}
					/>
					<div className="w-full text-right my-4">
						<Button
							disabled={loading !== null}
							loading={loading === LoadingStatus.ResetPassword}
							onClick={handleResetPassword}
							variant="primary"
							className="w-full lg:w-auto"
						>
							<Mail size={18} className="mr-2" /> Reimposta Password
						</Button>
						<Button
							disabled={loading !== null}
							loading={loading === LoadingStatus.UpdateUser}
							onClick={handleUpdateProfile}
							variant="success"
							className="w-full lg:w-auto"
						>
							<Save size={18} className="mr-2" /> Aggiorna Utente
						</Button>
					</div>
				</div>
			</Box>
		</div>
	)
}
