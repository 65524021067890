import { useState } from 'react'
import { Mail } from 'react-feather'
import toast from 'react-hot-toast'
import { Navigate } from 'react-router'
import { Link } from 'react-router-dom'
import { server } from '../../../core/server.core'

import { Button } from '../../common/components/button.component'
import { Input, InputAdditionPosition, InputInnerAddition } from '../../common/components/input.component'
import { Logo } from '../../common/components/logo.component'
import { useForm } from '@polaritybit/use-form'
import { useAuth } from './hooks/use-auth.hook'

export function RecoverPassword() {
	const [data, onChange] = useForm({ email: '' })
	const [user] = useAuth()
	const [loading, setLoading] = useState(false)

	async function handleLogin(event) {
		setLoading(true)
		event.preventDefault()
		event.stopPropagation()

		try {
			await server().get('/reset', { params: { email: data.email } })

			toast.success(
				'Abbiamo ricevuto la tua richiesta. Riceverai a breve un messaggio con le istruzioni per reimpostare la password.',
			)
		} catch (error) {
			toast.error('Si è verificato un errore durante la richiesta. Verifica i dati inseriti e riprova.')
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className="h-screen w-full lg:w-1/3 lg:mx-auto flex items-center justify-center flex-col p-4">
			{user && <Navigate to="/" />}
			<Logo />
			<h1 className="my-4 font-bold text-3xl text-main text-center">Portale del Professionista</h1>
			<form className="lg:border border-gray-100 lg:shadow p-4 mt-8 w-full" onSubmit={handleLogin}>
				<h2 className="text-xl mb-2 font-bold">Recupera Password</h2>

				<p className="py-4">
					Se non ricordi la tua password, puoi inserire qui l'email con la quale ti sei registrato. Se l'email
					è nei nostri sistemi, riceverai un messaggio con le istruzioni per reimpostare la password!
				</p>

				<Input
					before={
						<InputInnerAddition position={InputAdditionPosition.Start}>
							<Mail size={18} className="text-gray-500" />
						</InputInnerAddition>
					}
					label="Email"
					value={data.email}
					onChange={onChange}
					name="email"
				/>
				<div className="flex mt-4 flex-row w-full justify-between">
					<Link className="p-2 link" to="/login">
						Effettua l'accesso
					</Link>
					<Button loading={loading} variant="success" type="submit">
						Recupera Password
					</Button>
				</div>
			</form>
			<div className="my-4">
				Non hai un account?{' '}
				<Link to="/signup" className="link">
					Registrati ora!
				</Link>
			</div>
			<div className="flex-1"></div>
			<div className="text-xs">
				<p>&copy; 2021 Mind &amp; Move SRL</p>
				<p>
					<a
						className="link"
						href="https://www.mindtomove.it"
						rel="nofollow noopener noreferrer"
						target="_blank"
					>
						https://www.mindtomove.it
					</a>
				</p>
			</div>
		</div>
	)
}
