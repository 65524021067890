import { Spinner } from './spinner.component'

export const Loading = function () {
	return (
		<div className="h-64 flex justify-center items-center flex-col">
			<Spinner color="text-main" margin="" className="mb-4" />
			<p className="text-main">Caricamento in corso...</p>
		</div>
	)
}
