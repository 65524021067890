import { AlertCircle, Users } from 'react-feather'
import { useNavigate } from 'react-router'

import { Box } from '../../common/components/box.component'
import { Breadcrumbs } from '../../common/components/breadcrumbs.component'
import { Button } from '../../common/components/button.component'
import { PopModal } from '../../common/components/pop-modal.component'
import { ManageInvites } from './manage-invites.component'
import { usePatients } from './hooks/use-patients.hook'
import { PatientList } from './patient-list.component'
import { Loading } from '../../common/components/loading.component'
import { PopForm } from '../../common/components/pop-form.component'
import { MassTagForm, MassTagFormData } from './mass-tag-form.component'
import { MassTagValidationRules } from './validation-rules/mass-tag.validation-rules'
import { server } from '../../../core/server.core'
import toast from 'react-hot-toast'
import { MassBreathingValidationRules } from './validation-rules/mass-breathing.validation-rules'
import { MassBreathingForm } from './mass-breathing-form.component'
import { MassBreathingFormData } from './mass-breathing-form.component'

export function Patients() {
	const [patients, , patientsLoadingStatus] = usePatients()
	const navigate = useNavigate()

	function handlePatientClick(item) {
		navigate(`/patients/${item.uid}`)
	}

	async function handleMassAssignTag(data) {
		const { _patients, ...rest } = data

		try {
			await Promise.all(_patients.map((id) => server().post(`/patients/${id}/tags`, rest)))

			toast.success('Tag assegnato correttamente alle persone selezionate')
		} catch (error) {
			toast.error('Impossibile assegnare il tag alle persone selezionate')

			return Promise.reject()
		}
	}

	async function handleMassAssignBreathing(data) {
		const { _patients, ...rest } = data

		try {
			await Promise.all(_patients.map((id) => server().post(`/patients/${id}/breathings`, rest)))

			toast.success('Respirazione assegnata correttamente alle persone selezionate')
		} catch (error) {
			toast.error('Impossibile assegnare la respirazione alle persone selezionate')

			return Promise.reject()
		}
	}

	if (patientsLoadingStatus.loading) {
		return <Loading />
	}

	return (
		<div>
			<Breadcrumbs items={[{ title: 'Persone', link: '/patients' }]} />
			<Box
				title="Persone"
				icon={<Users size={22} />}
				actions={
					<div className="flex items-center">
						<PopModal
							title="Inviti Ricevuti"
							icon={<AlertCircle size={22} className="mr-2" />}
							content={<ManageInvites />}
							actions={(onClose) => <Button onClick={onClose}>Chiudi</Button>}
						>
							<Button variant="primary" className="w-full md:w-auto">
								<AlertCircle className="mr-2" size={18} /> Gestisci Inviti Ricevuti
							</Button>
						</PopModal>
					</div>
				}
			>
				<PatientList patients={patients} onPatientClick={handlePatientClick} />

				<div className="flex-1 flex flex-col md:flex-row justify-end items-center mt-4">
					<PopForm
						title="Assegnazione Massiva Tag"
						onConfirm={handleMassAssignTag}
						validationRules={MassTagValidationRules}
						form={(data: MassTagFormData, onChange, validationStatus) => {
							return <MassTagForm data={data} onChange={onChange} validationStatus={validationStatus} />
						}}
						containerClassName="w-full md:w-auto"
					>
						<Button variant="info" className="w-full md:w-auto">
							Assegnazione Massiva Tag
						</Button>
					</PopForm>
					<PopForm
						title="Assegnazione Massiva Tag"
						onConfirm={handleMassAssignBreathing}
						validationRules={MassBreathingValidationRules}
						form={(data: MassBreathingFormData, onChange, validationStatus) => {
							return (
								<MassBreathingForm
									data={data}
									onChange={onChange}
									validationStatus={validationStatus}
								/>
							)
						}}
						containerClassName="w-full md:w-auto"
					>
						<Button variant="info" className="w-full md:w-auto">
							Assegnazione Massiva Respirazioni
						</Button>
					</PopForm>
				</div>
			</Box>
		</div>
	)
}
