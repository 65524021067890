export function Privacy() {
	return (
		<div className="p-8">
			<p>
				<strong>
					<u>PRIVACY POLICY</u>
				</strong>
			</p>
			<p>
				<strong>(Informazioni sul trattamento dei dati personali all&rsquo;Interessato)</strong>
			</p>
			<p>&nbsp;</p>
			<p>Gentile Utente,</p>
			<p>
				con la presente Informativa sulla privacy Ti informiamo che garantiamo la salvaguardia e la riservatezza
				dei dati personali forniti nel rispetto della normativa in materia e protezione dei dati personali di
				cui al Reg. Ue 2016/679 (General Data Protection Regulation - GDPR) al D. Lgs. n. 196/2003 (Codice
				Privacy) e ai Provvedimenti del Garante.
			</p>
			<p>&nbsp;</p>
			<ol>
				<li>
					<strong>IDENTITA&rsquo; E DATI DI CONTATTO DEL TITOLARE DEL TRATTAMENTO</strong>
				</li>
			</ol>
			<p>
				MIND &amp; MOVE S.r.l. (P.IVA: 12103310012), &egrave; una StartUp Innovativa e Spin Off Accademico, con
				sede in Torino, Via Vela n. 42, amministrazione@mindtomove.it.
			</p>
			<p>&nbsp;</p>
			<ol start={2}>
				<li>
					<strong>DATI DI CONTATTO DEL RESPONSABILE DELLA PROTEZIONE DEI DATI</strong>
				</li>
			</ol>
			<p>
				Il Titolare del trattamento ha designato il Responsabile della Protezione dei Dati (DPO), i cui dati di
				contatto sono: Avv. Rudy Caltagirone, email: caltagirone@studiolegalemcc.it.
			</p>
			<p>&nbsp;</p>
			<ol start={3}>
				<li>
					<strong>FINALIT&Agrave;, BASI GIURIDICHE DEL TRATTAMENTO E COMUNICAZIONE DEI DATI PERSONALI</strong>
				</li>
			</ol>
			<p>
				I dati degli utenti che usufruiscono delle funzionalit&agrave; delle App (Applicazione) sono utilizzati
				per determinate finalit&agrave;, la finalit&agrave; &egrave; l&rsquo;obiettivo, ovvero lo scopo per il
				quale i dati personali vengono trattati, MIND &amp; MOVE espone quali sono le finalit&agrave; del
				trattamento:
			</p>
			<p>&nbsp;</p>
			<p>
				- <strong>FUNZIONALITA&rsquo; DELL&rsquo;APP</strong> la finalit&agrave; della corretta e completa
				esecuzione del servizio affidato ovvero l&rsquo;utilizzo delle funzionalit&agrave;
				dell&rsquo;applicazione relative al monitoraggio delle onde cardiache al fine di ottenere una risultante
				psicofisica.
			</p>
			<p>
				BASE GIURIDICA PER I DATI PERSONALI: il trattamento &egrave; basato sui dati biometrici, per i quali
				&egrave; necessario il consenso, la comunicazione dei dati &egrave; obbligatoria, diversamente non
				potr&agrave; essere erogato il servizio.
			</p>
			<p>&nbsp;</p>
			<p>
				- <strong>ATTIVITA&rsquo; DI MARKETING (NEWSLETTER)</strong> per ricevere, anche attraverso la
				newsletter, comunicazioni promozionali per la vendita diretta di prodotti e servizi.
			</p>
			<p>
				BASE GIURIDICA PER I DATI PERSONALI: il trattamento &egrave; basato sul consenso prestato per questa
				specifica finalit&agrave; e fino alla revoca, la comunicazione dei dati non &egrave; obbligatoria.
			</p>
			<p>&nbsp;</p>
			<p>
				In ogni momento potrai rileggere l&rsquo;informativa e modificare i consensi precedentemente forniti,
				verificare e/o modificare lo stato dei servizi attivi ed eventualmente richiedere servizi aggiuntivi.
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<ol start={4}>
				<li>
					<strong>TRATTAMENTO BASATO SU CATEGORIE PARTICOLARI DI DATI PERSONALI.</strong>
				</li>
			</ol>
			<p>
				I dati conferiti per il corretto espletamento dell&rsquo;attivit&agrave; prestata sono di natura
				particolare, ovvero dati che rivelino l'origine razziale o etnica, le opinioni politiche, le convinzioni
				religiose o filosofiche, o l'appartenenza sindacale, nonch&eacute; trattare dati genetici, dati
				biometrici intesi a identificare in modo univoco una persona fisica, dati relativi alla salute o alla
				vita sessuale o all'orientamento sessuale della personale e deve avvenire solo nel caso in cui
				l&rsquo;interessato abbia prestato il proprio consenso, che potr&agrave; in ogni momento revocare.
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<ol start={5}>
				<li>
					<strong>DESTINATARI DEI DATI PERSONALI</strong>
				</li>
			</ol>
			<p>
				I dati non saranno diffusi ma comunicati a Soggetti Terzi/Partner ove necessario per l&rsquo;erogazione
				del servizio, nonch&eacute; a soggetti che svolgono per conto di MIND &amp; MOVE compiti di natura
				tecnica od organizzativa strumentali alla fornitura dei servizi richiesti, tali soggetti sono stati
				autorizzati Responsabili del trattamento dei dati attraverso specifico atto scritto. Le categorie di
				destinatari dei dati personali sono: operatori del settore medico sanitario, del settore informatico,
				provider, agenzie di comunicazione, studi professionali e societ&agrave; di consulenza aziendale,
				istituti universitari, &nbsp;bancari e assicurativi.
			</p>
			<p>&nbsp;</p>
			<ol start={6}>
				<li>
					<strong>PERIODO DI CONSERVAZIONE DEI DATI E CRITERI UTILIZZATI PER LA SUA DETERMINAZIONE</strong>
				</li>
			</ol>
			<p>
				I dati personali degli utenti saranno conservati per un periodo di tempo non superiore a quello
				necessario agli scopi per i quali sono stati raccolti e trattati. In particolare, i dati personali
				dell&rsquo;utente/cliente saranno conservati e trattati fintanto che l&rsquo;utente manterr&agrave; la
				propria utenza e dal momento della cancellazione dell&rsquo;utenza, a fronte della richiesta
				dell&rsquo;interessato, MIND &amp; MOVE si impegna a provvedere alla rettifica e alla cancellazione dei
				dati personali dell&rsquo;utente, entro 30 giorni. Nel rispetto dei principi di proporzionalit&agrave; e
				necessit&agrave;, i dati personali saranno conservati in una forma che consenta l&rsquo;identificazione
				degli interessati per un arco di tempo non superiore al conseguimento delle finalit&agrave; per le quali
				gli stessi sono trattati, ossia tenendo in considerazione: la necessit&agrave; di continuare a
				conservare i dati personali raccolti per offrire i servizi concordati con l&rsquo;utente o per tutelare
				l&rsquo;interesse legittimo del Titolare, cos&igrave; come descritto nelle finalit&agrave; sopraindicate
				e l&rsquo;esistenza di specifici obblighi normativi o contrattuali che rendono necessario il trattamento
				e la conservazione dei dati per determinati periodi di tempo.
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<ol start={7}>
				<li>
					<strong>
						DIRITTI DELL&rsquo;INTERESSATO E DIRITTO DI PROPORRE RECLAMO ALL&rsquo;AUTORITA&rsquo; DI
						CONTROLLO
					</strong>
				</li>
			</ol>
			<p>
				L&rsquo;Interessato potr&agrave; in qualsiasi momento, inviando una comunicazione a MIND &amp; MOVE ai
				riferimenti sopra elencati, esercitare i diritti, fra cui:
			</p>
			<p>&nbsp;</p>
			<p>
				la <strong>revoca</strong> del consenso
			</p>
			<p>
				l&rsquo;<strong>accesso</strong> ai dati personali
			</p>
			<p>
				la <strong>rettifica</strong> dei dati personali
			</p>
			<p>
				la <strong>cancellazione</strong> (c.d. diritto all&rsquo;<strong>oblio</strong>) dei dati personali
			</p>
			<p>
				la <strong>limitazione</strong> del trattamento dei dati personali
			</p>
			<p>
				l&rsquo;<strong>opposizione</strong> al trattamento dei dati personali e la portabilit&agrave; dei dati
				personali,
			</p>
			<p>
				la proposizione del <strong>reclamo</strong> al Garante per la Protezione dei Dati Personali e/o ad
				altra Autorit&agrave; Garante, qualora ritenga che i diritti siano stati violati dal Titolare e/o da un
				terzo.
			</p>
			<p>&nbsp;</p>
			<ol start={8}>
				<li>
					<strong>MODALIT&Agrave; DEL TRATTAMENTO DEI DATI PERSONALI</strong>
				</li>
			</ol>
			<p>
				I dati personali inviati mediante le procedure di registrazione ai nostri servizi sono trattati da MIND
				&amp; MOVE con strumenti prevalentemente elettronici, registrati e custoditi su database elettronici e
				specifiche misure di sicurezza sono osservate dal Titolare del trattamento per prevenire la perdita dei
				dati, usi illeciti o non corretti ed accessi non autorizzati. Oltre al Titolare, potrebbero avere
				accesso ai dati altri soggetti coinvolti nell&rsquo;organizzazione del Titolare ovvero soggetti esterni
				autorizzati quali Responsabili del Trattamento. Il trattamento per fini di marketing avverr&agrave;
				mediante posta elettronica che sar&agrave; utilizzabile anche a fini di vendita diretta di prodotti o
				servizi richiesti.
			</p>
			<p>&nbsp;</p>
			<ol start={9}>
				<li>
					<strong>LUOGO DEL TRATTAMENTO</strong>
				</li>
			</ol>
			<p>
				I dati personali rilasciati dagli interessati potranno essere trattati presso la sede legale e
				secondarie del Titolare del trattamento e presso i soggetti autorizzati Responsabili del trattamento dei
				dati, conservati su server nel territorio di Paesi appartenenti all&rsquo;Unione Europea (UE) nel
				rispetto della normativa in materia di protezione dei dati personali. Non &egrave; intenzione di MIND
				&amp; MOVE trasferire i dati personali nel territorio extra UE.
			</p>
			<p>&nbsp;</p>
			<ol start={10}>
				<li>
					<strong>
						INFORMAZIONI DA FORNIRE QUALORA I DATI SIANO OTTENUTI PRESSO ALTRI TITOLARI DEL TRATTAMENTO ( 14
						GDPR)
					</strong>
				</li>
			</ol>
			<p>
				Nell'eventualit&agrave; che i dati personali siano stati forniti dall&rsquo;interessato ad un Titolare
				diverso dal presente, oltre alle Informazioni sopra illustrate, MIND &amp; MOVE comunica, a semplice
				richiesta dell&rsquo;interessato, la fonte da cui hanno avuto origine i dati personali.
			</p>
			<p>&nbsp;</p>
			<ol start={11}>
				<li>
					<strong>TRATTAMENTO DATI PERSONALI DEI MINORI DI 16 ANNI</strong>
				</li>
			</ol>
			<p>
				Come previsto dalla normativa vigente, i minori infra sedicenni non devono conferire informazioni o Dati
				Personali al Titolare in assenza del consenso degli esercenti la responsabilit&agrave; genitoriale e in
				mancanza, non sar&agrave; possibile interagire in alcun modo.
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<ol start={12}>
				<li>
					<strong>VARIAZIONE DELLA PRIVACY POLICY</strong>
				</li>
			</ol>
			<p>
				MIND &amp; MOVE si riserva il diritto di modificare l&rsquo;informativa sulla privacy o di aggiornarne i
				contenuti, invitiamo a controllare le informazioni periodicamente.
			</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
		</div>
	)
}
