import { User } from 'react-feather'
import { Patient } from '../../common/app.types'

type PatientItemProps = {
	patient: Patient
	icon: React.ReactNode
	onClick?: React.MouseEventHandler<HTMLDivElement>
}

export function PatientItem({ patient, icon, onClick }: PatientItemProps) {
	return (
		<div
			className="select-none border border-gray-100 bg-white shadow p-3 mb-3 lg:hover:bg-main lg:hover:text-white lg:cursor-pointer"
			onClick={onClick}
		>
			<div className="flex items-center justify-between">
				<div className="mr-4">
					<User />
				</div>
				<div className="flex-1">
					<p className="font-bold overflow-ellipsis">{patient.email}</p>
					<p className="italic overflow-ellipsis">{patient.name ?? 'Non identificato'}</p>
				</div>
				<div className="ml-4">{icon}</div>
			</div>
		</div>
	)
}
