export const TagValidationRules = {
	title: [
		{
			rule: (v) =>
				typeof v !== 'undefined' && v !== null && v.length !== 0,
			message: 'Campo obbligatorio',
		},
		{
			rule: (v) => v?.length >= 3,
			message: 'Il titolo del tag deve essere lungo almeno 3 caratteri',
		},
	],
	time: [
		{
			rule: (v) => typeof v !== 'undefined' && v !== null,
			message: 'Campo obbligatorio',
		},
	],
	type: [
		{
			rule: (v) => typeof v !== 'undefined' && v !== null,
			message: 'Campo obbligatorio',
		},
		{
			rule: (v) => ['open', 'breathing', 'training'].includes(v),
			message: 'Tipo non valido',
		},
	],
}
