export function Terms() {
	return (
		<div className="p-8">
			<h1>CONDIZIONI GENERALI DI UTILIZZO</h1>
			<p>
				<strong>1. Termini di servizio</strong>
			</p>
			<p>
				Benvenuto! L’App “MindTOMoveHRV” è di proprietà di MIND & MOVE s.r.l. , via Vela 42 - 10100 Torino
				(Italia), P. IVA 12103310012, email: info@mindtomove.it, pec: mindmove@legalmail.it.
			</p>
			<p>
				Utilizzando, registrandoti o accedendo a MindTOMoveHRV (il "Servizio"), accetti di essere legalmente
				vincolato dai presenti Termini di servizio (i "Termini")
			</p>
			<p>
				Ci riserviamo il diritto di modificare o sostituire i Termini in qualsiasi momento. L'uso continuato del
				Servizio dopo la notifica di eventuali modifiche ai presenti Termini costituirà l'accettazione dei
				Termini modificati. I Termini sono stati aggiornati l'ultima volta il 30 gennaio 2021.
			</p>
			<br />
			<p>
				<strong>2. Nessun consiglio medico o sanitario</strong>
			</p>
			<p>
				(a) Il Servizio non ha lo scopo di diagnosticare, trattare, curare o prevenire alcuna malattia o
				problema di salute. Il Servizio non ha lo scopo di fornire consulenza sanitaria o medica. b) In caso di
				emergenza, interrompi immediatamente l'utilizzo del Servizio e chiama l'assistenza medica. Non saremo in
				alcun modo responsabili per eventuali problemi medici o di salute che potrebbero derivare dall'utilizzo
				del Servizio, dall'interazione con altri utenti o dall'affidamento a consigli o informazioni resi
				disponibili tramite il Servizio. Accetti di utilizzare il Servizio a Tuo esclusivo rischio.
			</p>
			<br />
			<p>
				<strong>3. Licenza</strong>
			</p>
			<p>
				La licenza è limitata, non esclusiva, non trasferibile e non cedibile in sub licenza. Per attivare la
				licenza, è necessario registrarsi per ottenere un account utente. La tua licenza e il tuo account
				possono essere eliminati in qualsiasi momento senza preavviso per qualsiasi violazione del presente
				accordo. Registrandoti per l’account, accetti quanto segue:
				<ol>
					<li>Hai almeno 18 anni o hai il consenso dei tuoi genitori all’utilizzo del Servizio;</li>
					<li>
						Tutte le informazioni che invii o condividi con il Servizio saranno sempre veritiere, accurate e
						complete;
					</li>
					<li>
						Non utilizzerai il Servizio per condividere caricamenti, informazioni, messaggi o altri dati
						(collettivamente, "Dati utente”) illegali, diffamatori, inappropriati o offensivi per altri
						utenti;
					</li>
					<li>
						Non utilizzerai in modo improprio o traviserai la proprietà intellettuale, inclusi, ma non
						limitati a, copyright, marchi o brevetti;
					</li>
					<li>
						Non interromperai o tenterai di interrompere la sicurezza, la connettività, i dati o
						l'accessibilità del Servizio.
					</li>
				</ol>
			</p>
			<br />
			<p>
				<strong>4. Proprietà intellettuale</strong>
			</p>
			<p>
				Noi ed i nostri licenziatari possediamo i diritti su tutta la proprietà intellettuale presente in tutto
				il Servizio, inclusi copyright, marchi e brevetti. Non ti è stata concessa alcuna licenza, assegnazione
				o vendita di tale proprietà intellettuale. Ci riserviamo tutti i diritti sulla proprietà intellettuale
				del Servizio.
			</p>
			<br />
			<p>
				<strong>5. Dati Utente</strong>
			</p>
			<p>
				<ol>
					<li>
						Il Servizio può, tramite l'uso del telefono o di un dispositivo periferico, raccogliere dati
						sanitari e personali su di te (“Portale Utente”). “Portale Utente” includerà anche qualsiasi
						informazione, fotografia, video o altri dati caricati in qualsiasi modo sul Servizio. Sei
						l'unico responsabile di “Portale Utente”.
					</li>
					<li>
						I dati di “Portale Utente” vengono utilizzati nel Servizio per fornire funzionalità e
						approfondimenti basati su tali dati (ad esempio, stime basate sull'età).
					</li>
					<li>
						Puoi chiederci di eliminare tutti i tuoi dati da “Portale Utente” ("diritto alla
						cancellazione").
					</li>
					<li>
						Non saremo in alcun modo responsabili per eventuali perdite di dati da “Portale Utente” o altri
						dati.
					</li>
					<li>Il “Portale Utente” potrebbe essere reso anonimo e utilizzato a fini di ricerca.</li>
					<li>
						I tuoi dati non vengono condivisi, distribuiti, utilizzati o venduti a terze parti a meno che tu
						non autorizzi esplicitamente il trasferimento dei dati a un servizio di terze parti all'interno
						dell'app (ad esempio se autorizzi l'invio dei tuoi dati ad altri servizi).
					</li>
				</ol>
			</p>
			<br />
			<p>
				<strong>6. Informazioni a terze parti</strong>
			</p>
			<p>
				Il Servizio può contenere collegamenti a siti Web o applicazioni di terzi. Inoltre, gli utenti possono
				fornire collegamenti a siti Web o applicazioni di terze parti. Non abbiamo alcun controllo sul contenuto
				di questi collegamenti di terze parti e l'utente accetta di visitare o accedere a questi collegamenti a
				proprio rischio. Si prega di prestare attenzione quando si accede a qualsiasi collegamento non affiliato
				al Servizio
			</p>
			<br />
			<p>
				<strong>7. Limitazione di garanzie e danni</strong>
			</p>
			<p>
				<ul>
					<li>
						1. NELLA MISURA MASSIMA CONSENTITA DALLA LEGGE VIGENTE, IL SERVIZIO VIENE FORNITO "COSÌ COM'È",
						"DOVE SI TROVA" E "COME DISPONIBILE" CON TUTTI I DIFETTI E SENZA GARANZIE DI ALCUN TIPO,
						ESPLICITE O IMPLICITE, INCLUSE TUTTE LE GARANZIE O CONDIZIONI DI COMMERCIABILITÀ, PRECISIONE,
						IDONEITÀ PER UN PARTICOLARE SCOPO, TITOLO, TRANQUILLO GODIMENTO E NON VIOLAZIONE. NOI (EI NOSTRI
						FORNITORI E LICENZIATARI) NON GARANTIAMO CHE IL SERVIZIO SODDISFERÀ I TUOI REQUISITI, SARÀ
						DISPONIBILE PER TE IN MODO TEMPESTIVO, ININTERROTTO, SICURO O PRIVO DI ERRORI, O SARÀ ACCURATO,
						COMPLETO, VERITÀ, AFFIDABILE O PRIVO DI VIRUS, WORM O ALTRI CODICI DANNOSI.
					</li>
					<li>
						2. IN NESSUN CASO NOI O I NOSTRI AFFILIATI, FORNITORI O LICENZIANTI SAREMO RESPONSABILI PER
						ALCUN DANNO INDIRETTO, CONSEQUENZIALE, ACCIDENTALE, SPECIALE, PUNITIVO O ALTRI DANNI DI
						QUALSIASI TIPO DERIVANTI O IN QUALSIASI MODO COLLEGATO AL TUO UTILIZZO DEL SERVIZIO, LA TUA
						AFFIDAMENTO SULLE INFORMAZIONI TROVATE NEL SERVIZIO O LA TUA INTERAZIONE CON QUALUNQUE UTENTE
						DEL SERVIZIO, SIA BASATA SU CONTRATTO, TORTO, NEGLIGENZA, FRODE, RESPONSABILITÀ STRETTA O ALTRO,
						ANCHE SE SEI STATO AVVISATO DELLA POSSIBILITÀ DI DANNI.
					</li>
					<li>
						3. LE PARTI ACCETTANO DI RINUNCIARE AI LORO DIRITTI DI PARTECIPARE A QUALSIASI AZIONE COLLETTIVA
						O ARBITRATO DI GRUPPO IN RELAZIONE AL SERVIZIO O AI TERMINI.
					</li>
					<li>
						4. È POSSIBILE USARE ALCUNI DISPOSITIVI PERIFERICI IN COMBINAZIONE CON IL SERVIZIO. NON
						APPROVIAMO O GARANTIAMO L'USO DI ALCUNI DI QUESTI DISPOSITIVI. UTILIZZATE QUESTI DISPOSITIVI A
						VOSTRO ESCLUSIVO RISCHIO.
					</li>
				</ul>
			</p>
			<br />
			<p>
				<strong>8. Termini Aggiuntivi</strong>
			</p>
			<p>
				I Termini devono essere interpretati e interpretati dalle leggi italiane. Per qualsiasi controversia
				derivante da o correlata al tuo utilizzo del Servizio o dei presenti Termini sarà competente il Foro di
				Torino.
			</p>
			<br />
			<p>
				<strong>9. Contatti</strong>
			</p>
			<p>
				Per esercitare i diritti di cui sopra o per qualsiasi altra domanda relativa alle presenti condizioni
				generali di utilizzo, ti preghiamo di contattarci via e-mail all'indirizzo:
				amministrazione@mindtomove.it
			</p>
		</div>
	)
}
