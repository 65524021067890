export function UsersHelp() {
	return (
		<div>
			<h4 className="uppercase pb-4 border-b-2 border-main-dark mb-4 font-semibold">
				Elenco Utenti
			</h4>
			<div className="text-gray-700">
				<p className="pb-2">
					Elenco di tutti gli utenti presenti nel sistema.
				</p>
				<p className="pb-2">
					Puoi <strong>cercare un utente per nome o per email</strong>{' '}
					utilizzando la casella di ricerca, e visualizzarne i
					dettagli cliccando sulla cella corrispondente all'interno
					dell'elenco.
				</p>
				<p className="pb-2">
					I filtri <strong>"Amministratori"</strong> e{' '}
					<strong>"Professionisti (Abbonati)"</strong> ti permettono
					di visualizzare solo gli utenti che hanno quel ruolo
					all'interno del sistema.
				</p>
			</div>
		</div>
	)
}
