import { useNavigate } from 'react-router'

import { PatientList } from '../patients/patient-list.component'

export function GroupPagePatients({ group, patients }) {
	const navigate = useNavigate()

	function handlePatientClick(item) {
		navigate(`/patients/${item.uid}?fromGroup=${group.uid}&fromGroupName=${group.name}`)
	}

	return <PatientList patients={patients} onPatientClick={handlePatientClick} />
}
