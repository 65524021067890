import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRef } from 'react'

import { HandleChangeFunction, useForm } from '@polaritybit/use-form'
import { useStableCallbackReference } from '../hooks/use-stable-callback-reference.hook'
import { useValidation } from '../hooks/use-validation.hook'
import { Button } from './button.component'
import { Modal } from './modal.component'
import { Spinner } from './spinner.component'
import { Tooltip, TooltipPlacement, TooltipPlacementPosition } from './tooltip.component'

const noop = function () {}

type FooterAdditionProps = {
	onConfirm?: Function
	onCancel?: Function
	data?: any
	loading?: boolean
}

type PopFormProps<T = any> = {
	title: React.ReactNode
	icon?: React.ReactNode
	form: (data: T, onChange: HandleChangeFunction<T>, validationStatus?: any, onClose?: Function) => React.ReactNode
	cancelLabel?: React.ReactNode
	confirmLabel?: React.ReactNode
	tooltip?: React.ReactNode
	tooltipPlacement?: TooltipPlacement
	onConfirm?: Function
	onCancel?: Function
	cancelVariant?: string
	confirmVariant?: string
	disabled?: boolean
	validationRules?: any
	initialData?: T
	containerClassName?: string
	footerAddition?: ({ onCancel, onConfirm, data }: FooterAdditionProps) => React.ReactNode
	children: React.ReactNode
}

export const PopForm = function <T>({
	title,
	icon,
	form,
	cancelLabel = 'Annulla',
	confirmLabel = 'Ok',
	tooltip,
	tooltipPlacement = {
		vertical: TooltipPlacementPosition.Top,
		horizontal: TooltipPlacementPosition.Center,
	},
	onConfirm,
	onCancel,
	cancelVariant = 'default',
	confirmVariant = 'success',
	disabled = false,
	validationRules = {},
	initialData = {} as T,
	containerClassName = '',
	footerAddition,
	children,
}: PopFormProps<T>) {
	const [showForm, setShowForm] = useState(false)
	const [confirming, setConfirming] = useState(false)
	const [cancelling, setCancelling] = useState(false)
	const loading = useMemo(() => {
		return confirming || cancelling
	}, [confirming, cancelling])

	const [data, onChange] = useForm<T>(initialData)
	const [validationStatus, { validate, resetValidationStatus }] = useValidation(validationRules)

	const stableOnConfirm = useStableCallbackReference(onConfirm)
	const stableOnCancel = useStableCallbackReference(onCancel)

	useEffect(() => {
		resetValidationStatus()
	}, [data, resetValidationStatus])

	const handleConfirm = useCallback(
		async (e) => {
			e.preventDefault()
			e.stopPropagation()

			try {
				if (!validate(data)) {
					return
				}

				const result = stableOnConfirm?.(data) ?? noop()
				if (result instanceof Promise) {
					setConfirming(true)
					await result
				}
				setShowForm(false)
			} catch (error) {
				console.error(error)
				setConfirming(false)
			}
		},
		[stableOnConfirm, validate, data],
	)

	const handleCancel = useCallback(async () => {
		try {
			const result = stableOnCancel?.() ?? noop()
			if (result instanceof Promise) {
				setCancelling(true)
				await result
			}
			setShowForm(false)
		} catch {
			setCancelling(false)
		}
	}, [stableOnCancel])

	const handleOnClosed = useCallback(() => {
		onChange(null, true)
		setCancelling(false)
		setConfirming(false)
	}, [onChange])

	const handleShowForm = useCallback(() => {
		setShowForm(true)
	}, [])

	const formRef = useRef<any>()

	return (
		<>
			<Modal open={showForm} onClose={handleCancel} onClosed={handleOnClosed}>
				<Modal.Title>
					{icon} {title}
				</Modal.Title>

				<Modal.Content>
					<form ref={formRef} onSubmit={handleConfirm}>
						{form(data, onChange, validationStatus, handleCancel)}
					</form>
				</Modal.Content>
				<Modal.Footer
					additionalChildren={footerAddition?.({
						onCancel: handleCancel,
						onConfirm: handleConfirm,
						data,
						loading,
					})}
				>
					<Button
						disabled={loading || disabled}
						variant={cancelVariant ?? 'default'}
						onClick={handleCancel}
						className="mr-2"
					>
						{cancelling && <Spinner />}
						{cancelLabel}
					</Button>
					{onConfirm && (
						<Button
							disabled={loading || disabled}
							variant={confirmVariant ?? 'success'}
							onClick={handleConfirm}
						>
							{confirming && <Spinner />}
							{confirmLabel}
						</Button>
					)}
				</Modal.Footer>
			</Modal>

			<span onClick={!disabled ? handleShowForm : noop} className={containerClassName}>
				<Tooltip placement={tooltipPlacement} content={tooltip}>
					{children}
				</Tooltip>
			</span>
		</>
	)
}
