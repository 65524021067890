import { format } from 'date-fns'
import numeral from 'numeral'
import { useMemo, useState } from 'react'
import { ArrowDown, ArrowUp } from 'react-feather'

import { Patient } from '../../common/app.types'
import { Button } from '../../common/components/button.component'
import { Input, InputAddition, InputAdditionPosition } from '../../common/components/input.component'
import { Table, TableColumn } from '../../common/components/table.component'
import { SortDirection } from '../../common/hooks/use-sort.hook'
import { PatientChart } from './patient-chart.component'

const sortPropertyFnMap = {
	type: (item) => item.type,
	time: (item) => item.time,
	value: (item) => +item.value,
}

function getSortProperty(key) {
	return sortPropertyFnMap[key] ?? 'type'
}

export type PatientPageReadingsTableProps = {
	patient: Patient
	type: string
	onSaveReading: Function
}

export function PatientPageReadingsTable({ patient, type, onSaveReading }: PatientPageReadingsTableProps) {
	const [mobileSortProperty, setMobileSortProperty] = useState('time')

	const readingsTableColumns = useMemo((): TableColumn[] => {
		return [
			{
				key: 'mobile-cell',
				renderHeader({ column, onSort, sortProperties }) {
					return (
						<th className="table-cell lg:hidden">
							<Input
								label="Ordina per"
								select
								onChange={(e) => {
									onSort(getSortProperty(e.currentTarget.value))
									setMobileSortProperty(e.currentTarget.value)
								}}
								value={mobileSortProperty}
								append={
									<InputAddition position={InputAdditionPosition.End} condensed>
										<Button
											variant="primary"
											onClick={() => onSort(getSortProperty(mobileSortProperty))}
										>
											{sortProperties.direction === SortDirection.Ascending ? (
												<ArrowUp size={18} />
											) : (
												<ArrowDown size={18} />
											)}
										</Button>
									</InputAddition>
								}
							>
								<option value="time">Data</option>
								<option value="value">Valore</option>
							</Input>
						</th>
					)
				},
				headerClassName: 'lg:hidden',
				cellClassName: 'table-cell lg:hidden',
				title: '',
				getValue(item) {
					const date = new Date(item.time)

					return (
						<div className="shadow border p-4 mb-2 bg-white flex">
							<div className="flex-1 flex flex-col">
								<div className="flex flex-col justify-between items-start flex-1">
									<span className="font-bold">{format(date, 'dd/MM/yyyy HH:mm')}</span>
								</div>
							</div>
							<div className="flex justify-center items-center ml-4 pl-2 border-l w-24">
								<span className="font-bold text-center text-lg text-yellow-900">
									{numeral(item.value).format('0,0.00')} {item.type === 'weight' ? 'Kg' : 's'}
								</span>
							</div>
						</div>
					)
				},
			},
			{
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell p-4',
				key: 'time',
				title: 'Data Lettura',
				getSortProperty: getSortProperty('time'),
				getValue(item) {
					return format(new Date(item.time), 'dd/MM/yyyy HH:mm')
				},
			},
			{
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell p-4',
				key: 'value',
				title: 'Valore',
				getSortProperty: getSortProperty('value'),
				getValue(item) {
					return `${item.value} ${item.type === 'weight' ? 'Kg' : 's'}`
				},
			},
		]
	}, [mobileSortProperty])

	const validReadings = useMemo(() => {
		if (!patient) {
			return []
		}

		return patient.readings.filter((r) => r.type === type)
	}, [patient, type])

	if (!patient) {
		return null
	}

	return (
		<div>
			{validReadings.length === 0 && <div className="p-4 shadow text-center">Nessuna lettura disponibile</div>}
			{validReadings.length > 0 && (
				<div className="">
					<PatientChart
						items={validReadings}
						getValue={(x) => x.value}
						getTime={(x) => x.time}
						label={type === 'weight' ? 'Peso' : 'Intervallo'}
						tooltipUnit={type === 'weight' ? 'Kg' : 's'}
					/>
					<Table
						items={validReadings}
						columns={readingsTableColumns}
						getItemKey={(m) => m.uid}
						rowClassName="lg:hover:bg-gray-100 cursor-pointer lg:odd:bg-gray-50"
						headerRowClassName="lg:border-b"
						defaultSortProperties={{
							property: getSortProperty('time'),
							direction: SortDirection.Descending,
						}}
					/>
				</div>
			)}
			{/* <div className="flex justify-end mt-4">
				<PopForm
					title="Aggiungi Lettura"
					validationRules={ReadingValidationRules}
					form={(data, onChange, validationStatus) => (
						<ReadingForm
							data={data}
							onChange={onChange}
							validationStatus={validationStatus}
							type={type}
						/>
					)}
					onConfirm={onSaveReading}
				>
					<Button variant="success">
						<Plus size={18} className="mr-2" /> Aggiungi Lettura
					</Button>
				</PopForm>
			</div> */}
		</div>
	)
}
