type StatBoxProps = {
	title: string
	subtitle?: string
	value: React.ReactNode
}

export function StatBox({ title, subtitle = '', value }: StatBoxProps) {
	return (
		<div className="flex flex-col items-center justify-center p-4 mb-2 bg-white shadow">
			<div className="text-md text-gray-700">{subtitle}</div>
			<div className="font-bold text-xl">{value}</div>
			<div className="text-sm text-gray-500">{title}</div>
		</div>
	)
}
