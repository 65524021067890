import useSWR from 'swr'

import { Invite } from '../../../common/app.types'

const empty = []

export function useInvites(): [Invite[], () => Promise<boolean>, { error; loading }] {
	const { data, error, mutate } = useSWR('/me/invites')

	return [data ?? empty, () => mutate(), { error, loading: !data && !error }]
}
