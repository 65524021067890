import { useEffect, useState } from 'react'
import { Key } from 'react-feather'
import toast from 'react-hot-toast'
import { Navigate, useNavigate, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { ResetPasswordDto } from '../../common/app.types'
import { parse } from 'query-string'

import { Button } from '../../common/components/button.component'
import { Input, InputAdditionPosition, InputInnerAddition } from '../../common/components/input.component'
import { Logo } from '../../common/components/logo.component'
import { useForm } from '@polaritybit/use-form'
import { useValidation } from '../../common/hooks/use-validation.hook'
import { useAuth } from './hooks/use-auth.hook'
import firebase from 'firebase'

const ResetPasswordValidationRules = {
	password: (v) => v && v.length >= 8,
	passwordConfirm: [
		{
			rule: (v, d) => v && v === d.password,
			message: 'Le password non coincidono',
		},
	],
}

export function ResetPassword() {
	const [data, onChange] = useForm<ResetPasswordDto>({
		password: '',
		passwordConfirm: '',
	})
	const [user] = useAuth()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const location = useLocation()

	const search: { code?: string } = parse(location.search)

	const [validationStatus, { validate, resetValidationStatus }] = useValidation(ResetPasswordValidationRules)

	useEffect(() => {
		resetValidationStatus()
	}, [data, resetValidationStatus])

	async function handleResetPassword(event) {
		event.preventDefault()
		event.stopPropagation()

		if (!search.code) {
			return
		}

		if (!validate(data)) {
			return
		}

		setLoading(true)
		try {
			await firebase.app().auth().verifyPasswordResetCode(search.code)
			await firebase.app().auth().confirmPasswordReset(search.code, data.password)

			toast.success(
				"Reimpostazione password avvenuta correttamente. Puoi effettuare l'accesso utilizzando la nuova password.",
			)

			navigate('/login')
		} catch (error) {
			console.log(error)

			toast.error(
				'Si è verificato un errore durante la reimpostazione della password. Verifica i dati inseriti e riprova.',
			)
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className="h-screen w-full lg:w-1/3 lg:mx-auto flex items-center justify-center flex-col p-4">
			{user && <Navigate to="/" />}

			<Logo />

			<form className="lg:border border-gray-100 lg:shadow p-4 mt-8 w-full" onSubmit={handleResetPassword}>
				<h2 className="text-xl mb-2 font-bold">Reimposta Password</h2>

				<Input
					before={
						<InputInnerAddition position={InputAdditionPosition.Start}>
							<Key size={18} className="text-gray-500" />
						</InputInnerAddition>
					}
					label="Nuova Password"
					type="password"
					value={data.password}
					onChange={onChange}
					name="password"
					error={validationStatus.password?.error}
					errorText={validationStatus.password?.message}
				/>
				<Input
					before={
						<InputInnerAddition position={InputAdditionPosition.Start}>
							<Key size={18} className="text-gray-500" />
						</InputInnerAddition>
					}
					label="Ripeti Password"
					type="password"
					value={data.passwordConfirm}
					onChange={onChange}
					name="passwordConfirm"
					error={validationStatus.passwordConfirm?.error}
					errorText={validationStatus.passwordConfirm?.message}
				/>

				<div className="flex mt-4 flex-row w-full justify-between">
					<Button loading={loading} block variant="success" type="submit">
						Reimposta Password
					</Button>
				</div>
			</form>
			<div className="my-4">
				Ti ricordi la password?{' '}
				<Link to="/login" className="link">
					Effettua l'accesso!
				</Link>
			</div>
			<div className="flex-1"></div>
			<div className="text-xs">
				<p>&copy; 2021 Mind &amp; Move SRL</p>
				<p>
					<a
						className="link"
						href="https://www.mindtomove.it"
						rel="nofollow noopener noreferrer"
						target="_blank"
					>
						https://www.mindtomove.it
					</a>
				</p>
			</div>
		</div>
	)
}
