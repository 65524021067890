export function GroupsHelp() {
	return (
		<div>
			<h4 className="uppercase pb-4 border-b-2 border-main-dark mb-4 font-semibold">Elenco Gruppi</h4>
			<div className="text-gray-700">
				<p className="pb-2">
					Elenco dei gruppi creati. Cliccando su{' '}
					<strong className="bold text-main uppercase">'Crea nuovo gruppo'</strong> puoi creare un nuovo
					gruppo, assegnandogli un nome e le persone che ne faranno parte. Una persona{' '}
					<strong>può appartenere ad un solo gruppo alla volta.</strong>
				</p>
				<p className="pb-2">
					Puoi <strong>cercare un gruppo per nome</strong> utilizzando la casella di ricerca, e visualizzarne
					i dettagli cliccando sulla cella corrispondente all'interno dell'elenco.
				</p>
			</div>
		</div>
	)
}
