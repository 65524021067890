import { useMemo, useState } from 'react'
import { Check, Hash } from 'react-feather'
import toast from 'react-hot-toast'

import { server } from '../../../core/server.core'
import { Invite } from '../../common/app.types'
import { Button } from '../../common/components/button.component'
import {
	Input,
	InputAddition,
	InputAdditionPosition,
	InputInnerAddition,
} from '../../common/components/input.component'
import { Spinner } from '../../common/components/spinner.component'
import { Table, TableColumn } from '../../common/components/table.component'
import { useStableCallbackReference } from '../../common/hooks/use-stable-callback-reference.hook'
import { useInvites } from './hooks/use-invites.hook'

enum LoadingStatus {
	ACCEPT_CODE,
	ACCEPT_INVITE,
	REJECT_INVITE,
}

export function ManageInvites() {
	const [invites, fetchInvites, invitesLoadingStatus] = useInvites()
	const [inviteCode, setInviteCode] = useState('')
	const [loading, setLoading] = useState<LoadingStatus>(null)

	async function handleAcceptPatientInviteCode() {
		try {
			setLoading(LoadingStatus.ACCEPT_CODE)
			await server().post(`/patients/invite/${inviteCode}`)
			await fetchInvites()

			toast.success(
				"Invito accettato correttamente! Il gruppo sarà ora visibile nell'elenco dei gruppi disponibili.",
			)
		} catch (error: any) {
			toast.error(`Si è verificato un errore durante l'accettazione dell'invito: ${error.response.data.error}`)
		} finally {
			setLoading(null)
		}
	}

	const handleAcceptPatientInvite = useStableCallbackReference(async function handleAcceptPatientInvite(
		invite: Invite,
	) {
		try {
			setLoading(LoadingStatus.ACCEPT_INVITE)
			await server().post(`/patients/invite/${invite.code}`)
			await fetchInvites()

			toast.success(
				"Invito accettato correttamente! Il gruppo sarà ora visibile nell'elenco dei gruppi disponibili.",
			)
		} catch (error: any) {
			toast.error(`Si è verificato un errore durante l'accettazione dell'invito: ${error.message}`)
		} finally {
			setLoading(null)
		}
	})

	const inviteTableColumns = useMemo((): TableColumn[] => {
		return [
			{
				title: '',
				key: 'group_cell',
				getValue(item) {
					return (
						<div className="select-none border border-gray-100 bg-white shadow p-4 mb-4 grid grid-cols-3 grid-rows-2 lg:grid-rows-1 grid-flow-col lg:grid-flow-row gap-2">
							<div className="col-span-3 lg:col-span-2">
								<div>
									<strong>
										{item.patient.name} ({item.patient.email})
									</strong>
								</div>
								<div className="text-sm">
									Ricevuto da{' '}
									<span className="italic">
										{item.patient.ownerName} ({item.patient.ownerEmail})
									</span>
								</div>
							</div>

							<div className="col-span-3 lg:col-span-1 lg:text-right flex">
								{/* <Button
									loading={
										loading === LoadingStatus.REJECT_INVITE
									}
									disabled={loading !== null}
									variant="danger"
									className="flex-1"
								>
									<X />
								</Button> */}
								<Button
									loading={loading === LoadingStatus.ACCEPT_INVITE}
									disabled={loading !== null}
									variant="success"
									className="flex-1"
									onClick={() => handleAcceptPatientInvite(item)}
								>
									<Check />
								</Button>
							</div>
						</div>
					)
				},
			},
		]
	}, [handleAcceptPatientInvite, loading])

	const filteredInvites = useMemo(() => {
		return invites.filter((i) => i.pending)
	}, [invites])

	if (invitesLoadingStatus.loading) {
		return (
			<div className="w-full h-64 flex justify-center items-center">
				<Spinner margin="" color="text-black" />
			</div>
		)
	}

	return (
		<div>
			<p className="py-2">
				Se hai ricevuto un invito su una email diversa da quella che utilizzi per accedere a questa dashboard,
				puoi utilizzare il codice in essa contenuto per accettare l'invito con questo account.
			</p>
			<Input
				label="Codice Invito"
				value={inviteCode}
				onChange={(e) => setInviteCode(e.currentTarget.value)}
				before={
					<InputInnerAddition position={InputAdditionPosition.Start}>
						<Hash size={18} className="text-gray-500" />
					</InputInnerAddition>
				}
				append={
					<InputAddition position={InputAdditionPosition.End} condensed>
						<Button
							disabled={inviteCode.trim().length === 0 || loading !== null}
							loading={loading === LoadingStatus.ACCEPT_CODE}
							variant="success"
							onClick={() => handleAcceptPatientInviteCode()}
						>
							Accetta
						</Button>
					</InputAddition>
				}
			/>

			<hr className="mt-8 mb-4" />
			<h4 className="text-lg font-bold">Inviti in attesa</h4>

			{filteredInvites.length === 0 && <p className="p-4 text-gray-600 text-center">Nessun invito in attesa</p>}

			<Table items={filteredInvites} getItemKey={(i: Invite) => i.uid} columns={inviteTableColumns} />
		</div>
	)
}
