import useSWR from 'swr'

import { Therapy } from '../../../common/app.types'

const empty = []
export function useTherapies(): [Therapy[], () => Promise<boolean>, { loading; error }] {
	const { data, mutate, error } = useSWR('/therapies')

	return [data ?? empty, () => mutate(), { error, loading: !data && !error }]
}
