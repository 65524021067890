import { useEffect } from 'react'
import { useCallback, useMemo } from 'react'
import { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'

import { usePagination } from '../hooks/use-pagination.hook'
import { SortDirection, SortPredicate, SortPredicateResult, useSort } from '../hooks/use-sort.hook'
import { Paginator } from './paginator.component'

export type TableColumn<T = any> = {
	key: string
	title: string
	getValue?: (item: T) => React.ReactNode
	getSortProperty?: SortPredicateResult<T>
	width?: number | string
	headerClassName?: string
	cellClassName?: string | ((item: T) => string)
	renderHeader?: (props: TableHeaderProps<T>) => React.ReactNode
	value?: React.ReactNode
}

export type SortPredicateMap<T> = { [key: string]: SortPredicate<T> }

export type SortProperties<T> = {
	direction: SortDirection
	property: SortPredicate<T>
}

export type TableHeaderProps<T> = {
	column: TableColumn<T>
	sortProperties: SortProperties<T>
	onSort: (any) => void
}

export type TableProps<T> = {
	columns: TableColumn<T>[]
	items: T[]
	getItemKey?: (item: T) => string | number
	fixedWidth?: boolean
	fullWidth?: boolean
	tableClassName?: string
	rowClassName?: string | ((item: T) => string)
	commonCellClassName?: string | ((item: T) => string)
	headerRowClassName?: string
	onRowClick?: (item: T) => void
	parseDatasetValue?: (value: string) => any
	defaultSortProperties?: SortProperties<T>
}

export function TableHeader<T>({ column, sortProperties, onSort }: TableHeaderProps<T>) {
	const isSortingSelected = useMemo(() => {
		return column.getSortProperty === sortProperties.property
	}, [column.getSortProperty, sortProperties.property])

	if (!column.getSortProperty) {
		return (
			<th key={column.key} className={column.headerClassName}>
				{column.title}
			</th>
		)
	}

	return (
		<th
			key={column.key}
			onClick={() => onSort(column.getSortProperty)}
			className={`${column.headerClassName} ${
				!!column.getSortProperty
					? `font-bold cursor-pointer text-main ${isSortingSelected ? 'underline' : ''}`
					: ''
			}`}
		>
			{column.title}
			{!!column.getSortProperty &&
				isSortingSelected &&
				(sortProperties.direction === SortDirection.Ascending ? (
					<ChevronDown size={16} className="ml-2 inline" />
				) : (
					<ChevronUp size={16} className="ml-2 inline" />
				))}
		</th>
	)
}

export function Table<T = any>({
	columns,
	items = [],
	getItemKey,
	fixedWidth = false,
	fullWidth = true,
	tableClassName = '',
	rowClassName = '',
	commonCellClassName = '',
	headerRowClassName = '',
	onRowClick,
	parseDatasetValue = (value) => value,
	defaultSortProperties,
}: TableProps<T>) {
	const [sortProperties, setSortProperties] = useState({
		property: defaultSortProperties?.property ?? getItemKey,
		direction: defaultSortProperties?.direction ?? SortDirection.Ascending,
	})

	const sortedItems = useSort(items, sortProperties.property, sortProperties.direction)

	const { pageItems, pageCount, activePage, setActivePage } = usePagination(sortedItems)

	useEffect(() => {
		setActivePage(0)
	}, [items, setActivePage])

	const handleSortPropertyChange = useCallback(
		function handleSortPropertyChange(property: SortPredicate) {
			if (sortProperties.property === property) {
				setSortProperties((p) => {
					return {
						...p,
						direction:
							p.direction === SortDirection.Ascending
								? SortDirection.Descending
								: SortDirection.Ascending,
					}
				})

				return
			}

			setSortProperties({
				property,
				direction: SortDirection.Ascending,
			})
		},
		[sortProperties],
	)

	const handleRowClick = useCallback(
		(event) => {
			if (onRowClick) {
				const item = items.find((i) => getItemKey(i) === parseDatasetValue(event.currentTarget.dataset.itemkey))

				if (item) {
					onRowClick(item)
				}
			}
		},
		[items, onRowClick, getItemKey, parseDatasetValue],
	)

	const validColumns = useMemo(() => {
		return columns.filter((c) => !!c)
	}, [columns])

	return (
		<table
			className={`${fixedWidth ? 'table-fixed' : 'table-auto'} ${fullWidth ? 'w-full' : ''} ${tableClassName}`}
		>
			<thead>
				<tr className={headerRowClassName}>
					{validColumns.map((column) => {
						if (column.renderHeader) {
							return column.renderHeader({
								column,
								sortProperties: sortProperties,
								onSort: handleSortPropertyChange,
							})
						}

						return (
							<TableHeader
								column={column}
								sortProperties={sortProperties}
								onSort={handleSortPropertyChange}
								key={column.key}
							/>
						)
					})}
				</tr>
			</thead>
			<tbody>
				{pageItems.map((item) => {
					const rowClass = typeof rowClassName === 'function' ? rowClassName(item) : rowClassName

					return (
						<tr
							onClick={handleRowClick}
							data-itemkey={getItemKey(item)}
							key={getItemKey(item)}
							className={rowClass}
						>
							{validColumns.map((column) => {
								const commonCellClass =
									typeof commonCellClassName === 'function'
										? commonCellClassName(item)
										: commonCellClassName

								const cellClass =
									typeof column.cellClassName === 'function'
										? column.cellClassName(item)
										: column.cellClassName

								return (
									<td
										key={column.key}
										className={`${commonCellClass} ${cellClass}`}
										width={column.width}
									>
										{column.getValue?.(item) ?? item[column.key] ?? column.value}
									</td>
								)
							})}
						</tr>
					)
				})}
			</tbody>
			<tfoot>
				<tr>
					<td className="text-right" colSpan={validColumns.length}>
						<Paginator activePage={activePage} pageCount={pageCount} onSetActivePage={setActivePage} />
					</td>
				</tr>
			</tfoot>
		</table>
	)
}
