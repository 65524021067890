import axios, { AxiosInstance } from 'axios'
import firebase from 'firebase'

let _http: AxiosInstance

export function setToken(token: string) {
	server().defaults.headers['authorization'] = `Bearer ${token}`
}

export function clearToken() {
	delete server().defaults.headers['authorization']
}

export function server() {
	if (!_http) {
		_http = axios.create({ baseURL: process.env.REACT_APP_API_ENDPOINT })

		_http.interceptors.response.use(
			function onFulfilled(response) {
				return Promise.resolve(response)
			},
			async function onRejected(error) {
				if (error.response.status === 401) {
					if (error.response.data.error === 'ERR_INVALID_TOKEN') {
						try {
							const token = await firebase.auth().currentUser.getIdToken(true)

							setToken(token)
						} catch (error) {
							console.log(error)
							window.location.reload()
						}
					} else {
						window.location.reload()
					}
				}

				return Promise.reject(error)
			},
		)
	}

	return _http
}
