import React, { useCallback, useState } from 'react'

import { Modal, ModalFooterAlign } from './modal.component'
import { Tooltip, TooltipPlacement, TooltipPlacementPosition } from './tooltip.component'

const noop = function () {}

interface PopModalProps {
	title: React.ReactNode
	icon?: React.ReactNode
	content: React.ReactNode
	tooltip?: React.ReactNode
	tooltipPlacement?: TooltipPlacement
	disabled?: boolean
	children: React.ReactNode
	actions: (onClose: Function) => React.ReactNode
	footerAlignment?: ModalFooterAlign
	containerClassName?: string
}

export const PopModal = function ({
	title,
	icon,
	content,
	tooltip,
	tooltipPlacement = {
		vertical: TooltipPlacementPosition.Top,
		horizontal: TooltipPlacementPosition.Center,
	},
	disabled = false,
	footerAlignment = ModalFooterAlign.Right,
	actions,
	containerClassName,
	children,
}: PopModalProps) {
	const [showContent, setShowContent] = useState(false)

	const handleClose = useCallback(async (onClose?: () => any | Promise<any>) => {
		try {
			const result = onClose && typeof onClose === 'function' ? onClose() : noop()
			if (result instanceof Promise) {
				await result
			}
			setShowContent(false)
		} catch (error) {}
	}, [])

	const handleShowContent = useCallback(() => {
		setShowContent(true)
	}, [])

	return (
		<>
			<Modal open={showContent} onClose={handleClose} onClosed={noop}>
				<Modal.Title>
					{icon} {title}
				</Modal.Title>

				<Modal.Content>{content}</Modal.Content>
				<Modal.Footer align={footerAlignment}>{actions(handleClose)}</Modal.Footer>
			</Modal>

			<span className={containerClassName} onClick={!disabled ? handleShowContent : noop}>
				<Tooltip placement={tooltipPlacement} content={tooltip}>
					{children}
				</Tooltip>
			</span>
		</>
	)
}
