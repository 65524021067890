import { Check } from 'react-feather'
import { Patient } from '../../common/app.types'
import { HandleChangeFunction, useFormList } from '@polaritybit/use-form'
import { TagForm } from '../tools/tag-form.component'
import { usePatients } from './hooks/use-patients.hook'
import { PatientList } from './patient-list.component'

export type MassTagFormData = {
	_patients: string[]
}

export type MassTagFormProps = {
	data: MassTagFormData
	onChange: HandleChangeFunction<MassTagFormData>
	validationStatus: any
}

export function MassTagForm({ data, onChange, validationStatus }: MassTagFormProps) {
	const [patients] = usePatients()
	const [selectedPatients, { onAdd, onRemove }] = useFormList([data, onChange], '_patients', (i) => i)

	function handleTogglePatient(p: Patient) {
		if (selectedPatients.includes(p.uid)) {
			return onRemove(p.uid)
		}

		onAdd(p.uid)
	}

	return (
		<div>
			<h3 className="text-lg font-bold mb-2">Definisci il tag:</h3>
			<TagForm data={data} onChange={onChange} validationStatus={validationStatus} />
			<h3 className="text-lg font-bold mb-2">Seleziona le persone a cui assegnarlo:</h3>
			{validationStatus._patients?.error && (
				<p className="text-red-500">È necessario selezionare almeno una persona</p>
			)}
			<PatientList
				patients={patients}
				onPatientClick={handleTogglePatient}
				patientItemIcon={(patient) => (selectedPatients.includes(patient.uid) ? <Check /> : false)}
			/>
		</div>
	)
}
