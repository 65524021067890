import { addSeconds } from 'date-fns'
import { useEffect, useState } from 'react'
import { Button } from '../../common/components/button.component'
import { Checkbox } from '../../common/components/checkbox.component'
import { Input } from '../../common/components/input.component'
import { AdditionOptions } from '../../common/components/modal-form.component'

export enum TagAdditionalLoading {
	ClearCalibration = 1,
	Delete = 2,
}

type TagFormProps = {
	tag?: any
	data: any
	onChange: Function
	validationStatus: any
	options?: AdditionOptions
	onClearCalibration?: Function
}

enum TimeValue {
	Seconds,
	Minutes,
	Hours,
}

type Time = [number, number, number]

function durationToTime(duration: number): Time {
	const date = addSeconds(new Date(0), duration)

	return [date.getUTCSeconds(), date.getUTCMinutes(), date.getUTCHours()]
}

function timeToDuration(time: Time): number {
	return time[TimeValue.Seconds] + time[TimeValue.Minutes] * 60 + time[TimeValue.Hours] * 60 * 60
}

export function TagForm({ tag, data, onChange, validationStatus, options, onClearCalibration }: TagFormProps) {
	useEffect(() => {
		if (!tag) {
			onChange(
				{
					uid: Math.floor(+new Date() * Math.random() * 100).toString(32),
					title: '',
					time: 120,
					type: 'training',
					dedicatedCalibration: false,
					hasReminder: false,
					reminder: null,
					notificationId: null,
				},
				true,
			)
		}
		if (tag) {
			onChange(tag, true)
			if (tag.time !== -1) {
				setTime(durationToTime(tag.time))
			}
		}
	}, [tag, onChange])

	const [time, setTime] = useState<Time>(tag?.time === -1 ? [-1, 0, 0] : durationToTime(tag?.time ?? 120))
	function updateTime(type: TimeValue, value) {
		setTime((time) => {
			const updatedTime: Time = [...time]
			updatedTime[type] = value

			return updatedTime
		})
	}

	function handleChangeOpenSession() {
		if (data.time === -1) {
			onChange({ time: 120, type: 'training' })
		} else {
			onChange({ time: -1, type: 'training' })
		}
	}

	useEffect(() => {
		if (time[TimeValue.Minutes] !== -1) {
			onChange('time', timeToDuration(time))
		}
	}, [time, onChange])

	useEffect(() => {
		if (!data) {
			return
		}

		if (data.hasReminder && !data.reminder) {
			onChange({
				reminder: '15:00',
				notificationId: Math.floor(Math.random() * 1000000),
			})
		} else if (!data.hasReminder && data.reminder) {
			onChange({
				reminder: null,
				notificationId: null,
			})
		}
	}, [data, onChange])

	return (
		<div>
			<Input
				name="title"
				label="Nome Tag"
				value={data?.title}
				onChange={onChange}
				error={validationStatus.title?.error}
				errorText={validationStatus.title?.message}
				inputProps={{ autoComplete: 'off' }}
			/>
			{!tag && (
				<Checkbox
					className="mt-4 mb-2"
					label="Sessione Libera"
					checked={data?.time === -1}
					onChange={handleChangeOpenSession}
				/>
			)}
			{data?.time !== -1 && (
				<div>
					<p className="text-sm font-bold">Durata Sessione</p>
					<div className="grid grid-cols-2">
						{/* <Input
							label="Ore"
							min={0}
							type="number"
							value={time[TimeValue.Hours]}
							onChange={(e) =>
								updateTime(TimeValue.Hours, +e.currentTarget.value)
							}
						/> */}
						<Input
							label="Minuti"
							type="number"
							min={0}
							max={59}
							value={time[TimeValue.Minutes]}
							onChange={(e) => updateTime(TimeValue.Minutes, +e.currentTarget.value)}
						/>
						<Input
							label="Secondi"
							type="number"
							min={0}
							max={59}
							value={time[TimeValue.Seconds]}
							onChange={(e) => updateTime(TimeValue.Seconds, +e.currentTarget.value)}
						/>
						{validationStatus.time?.error && (
							<div className="col-span-3 text-red-900">validationStatus.time?.message</div>
						)}
					</div>
				</div>
			)}
			<Input
				disabled={data?.time === -1 || !!tag}
				name="type"
				label="Tipo Tag"
				value={data?.type}
				onChange={onChange}
				error={validationStatus.type?.error}
				errorText={validationStatus.type?.message}
				select
			>
				{data?.time === -1 && <option value="training">Libera</option>}
				<option value="training">Registrazione</option>
				<option value="breathing">Respirazione</option>
			</Input>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-2">
				<Checkbox
					className="mt-4 mb-2"
					label="Utilizza Calibrazione Dedicata"
					name="dedicatedCalibration"
					checked={data?.dedicatedCalibration}
					onChange={onChange}
				/>
				{!!tag && tag?.rrlist && onClearCalibration && (
					<Button
						type="button"
						variant="primary"
						className="mt-2 w-full"
						disabled={options.additionalLoading}
						loading={options.additionalLoading === TagAdditionalLoading.ClearCalibration}
						onClick={() => {
							onClearCalibration(tag, options)
						}}
					>
						Cancella Calibrazione
					</Button>
				)}
			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-2">
				<Checkbox
					className="mt-4 mb-2"
					label="Attiva notifiche quotidiane"
					name="hasReminder"
					checked={data?.hasReminder}
					onChange={onChange}
				/>
				{data?.hasReminder && (
					<div className="grid grid-cols-1 md:grid-cols-2 gap-2">
						<Input label="Ora" type="time" name="reminder" value={data?.reminder} onChange={onChange} />
					</div>
				)}
			</div>
		</div>
	)
}
