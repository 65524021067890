import { pick } from 'ramda'
import { useEffect, useMemo } from 'react'
import { CheckCircle, Circle } from 'react-feather'
import { Group, Patient } from '../../common/app.types'

import { Input, InputAdditionPosition, InputInnerAddition } from '../../common/components/input.component'
import { HandleChangeFunction, useFormList } from '@polaritybit/use-form'
import { usePatients } from '../patients/hooks/use-patients.hook'
import { PatientList } from '../patients/patient-list.component'

type GroupFormProps = {
	group?: Partial<Group>
	data: Group
	onChange: HandleChangeFunction<Group>
	validationStatus: any
}

export function GroupForm({ group, data, onChange, validationStatus }: GroupFormProps) {
	const [patients] = usePatients()
	const [components, { onAdd, onRemove }] = useFormList([data, onChange], 'components', (i) => i)

	useEffect(() => {
		if (group) {
			onChange(pick(['uid', 'components', 'name'], group), true)
		} else {
			onChange({}, true)
		}
	}, [group, onChange])

	function handleTogglePatient(item) {
		if (components.includes(item.uid)) {
			onRemove(item.uid)
		} else {
			onAdd(item.uid)
		}
	}

	const availablePatients = useMemo(() => {
		return patients.filter(
			(p: Patient) => p.groupIds.includes(group?.uid) || !p.groupIds || p.groupIds.length === 0,
		)
	}, [patients, group])

	return (
		<div>
			<Input
				name="name"
				value={data?.name}
				onChange={onChange}
				label="Nome Gruppo"
				before={
					<InputInnerAddition position={InputAdditionPosition.Start}>
						<Circle size={18} className="text-gray-500" />
					</InputInnerAddition>
				}
				error={validationStatus.name?.error}
				errorText={validationStatus.name?.message}
				inputProps={{ autoComplete: 'off' }}
				disableAutoComplete
			/>
			<h3 className="text-sm my-2">Seleziona Partecipanti</h3>
			{validationStatus.components?.error && (
				<p className="text-sm text-red-500 mb-2">{validationStatus.components.message}</p>
			)}
			{availablePatients.length === 0 && (
				<p className="mt-4 text-center text-gray-600 m-auto lg:w-2/3">
					Nessuna persona disponibile. È possibile che tutti le persone siano già assegnati ad altri gruppi.
				</p>
			)}

			<PatientList
				patients={availablePatients}
				onPatientClick={handleTogglePatient}
				patientItemIcon={(patient) => components.includes(patient.uid) && <CheckCircle />}
			/>
		</div>
	)
}
