import React, { useCallback, useMemo } from 'react'
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'react-feather'

const MAX_PAGES = 5

type PaginatorItemProps = {
	active?: boolean
	onClick: (Event) => void
	children: React.ReactNode
	[x: string]: any
}

const PaginatorItem = function ({ active = false, onClick, children, ...rest }: PaginatorItemProps) {
	return (
		<div
			onClick={onClick}
			className={`select-none h-10 flex justify-center items-center border border-r-0 last:border-r border-gray-300 w-12 cursor-pointer ${
				active ? 'bg-main text-white font-bold' : 'bg-white hover:bg-gray-100'
			}`}
			{...rest}
		>
			{children}
		</div>
	)
}

export type PaginatorProps = {
	activePage: number
	pageCount: number
	onSetActivePage: (number) => void
}

export const Paginator = ({ activePage, pageCount, onSetActivePage }: PaginatorProps) => {
	const shownPages = useMemo(() => {
		return pageCount > MAX_PAGES ? MAX_PAGES : pageCount
	}, [pageCount])

	const threshold = useMemo(() => {
		let threshold = activePage - Math.floor(MAX_PAGES / 2) > 0 ? activePage - Math.floor(MAX_PAGES / 2) : 0

		if (activePage > pageCount - MAX_PAGES) {
			threshold = pageCount - MAX_PAGES
		}

		return threshold > 0 ? threshold : 0
	}, [activePage, pageCount])

	const handleSetActivePage = useCallback(
		function (event) {
			event.preventDefault()
			event.stopPropagation()

			const { currentTarget } = event
			const page = +currentTarget.dataset.page

			if (page < 0) {
				return onSetActivePage(0)
			}

			if (page > pageCount - 1) {
				return onSetActivePage(pageCount - 1)
			}

			return onSetActivePage(page)
		},
		[onSetActivePage, pageCount],
	)

	if (pageCount < 2) {
		return null
	}

	return (
		<div className="flex justify-end mt-4">
			<PaginatorItem data-page={0} onClick={handleSetActivePage}>
				<ChevronsLeft size={18} />
			</PaginatorItem>
			<PaginatorItem data-page={activePage - 1} onClick={handleSetActivePage}>
				<ChevronLeft size={18} />
			</PaginatorItem>
			{threshold + activePage >= MAX_PAGES && (
				<PaginatorItem data-page={activePage - MAX_PAGES} onClick={handleSetActivePage}>
					...
				</PaginatorItem>
			)}
			{Array.from(Array(shownPages)).map((_, i) => {
				return (
					<PaginatorItem
						data-page={i + threshold}
						key={i + threshold}
						active={i + threshold === activePage}
						onClick={handleSetActivePage}
					>
						{i + 1 + threshold}
					</PaginatorItem>
				)
			})}
			{threshold + MAX_PAGES < pageCount && (
				<PaginatorItem data-page={activePage + MAX_PAGES} onClick={handleSetActivePage}>
					...
				</PaginatorItem>
			)}
			<PaginatorItem data-page={activePage + 1} onClick={handleSetActivePage}>
				<ChevronRight size={18} />
			</PaginatorItem>
			<PaginatorItem data-page={pageCount - 1} onClick={handleSetActivePage}>
				<ChevronsRight size={18} />
			</PaginatorItem>
		</div>
	)
}
