import { Toaster } from 'react-hot-toast'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import { AuthContextProvider } from './modules/auth/contexts/auth.context'
import { Login } from './modules/auth/login.component'
import { ProfilePage } from './modules/auth/profile-page.component'
import { RecoverPassword } from './modules/auth/recover.component'
import { ResetPassword } from './modules/auth/reset.component'
import { Signup } from './modules/auth/signup.component'
import { GroupPage } from './modules/groups/group-page.component'
import { Groups } from './modules/groups/groups.component'
import { JoinGroup } from './modules/groups/join-group.component'
import { ProtocolPage } from './modules/groups/protocol-page.component'
import { Dashboard } from './modules/main/dashboard.component'
import { Privacy } from './modules/main/privacy.component'
import { Terms } from './modules/main/terms.component'
import { PatientPage } from './modules/patients/patient-page.component'
import { Patients } from './modules/patients/patients.component'
import { Protocol } from './modules/protocols/protocol.component'
import { Protocols } from './modules/protocols/protocols.component'
import { UserPage } from './modules/users/user-page.component'
import { Users } from './modules/users/users.component'

export function App() {
	return (
		<AuthContextProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/recover" element={<RecoverPassword />} />
					<Route path="/reset" element={<ResetPassword />} />
					<Route path="/login" element={<Login />} />
					<Route path="/signup" element={<Signup />} />
					<Route path="/privacy" element={<Privacy />} />
					<Route path="/terms" element={<Terms />} />
					<Route path="/join/:id" element={<JoinGroup />} />
					<Route path="/" element={<Dashboard />}>
						<Route path="/patients/:id" element={<PatientPage />} />
						<Route path="/patients" element={<Patients />} />
						<Route path="/groups/:id/protocol" element={<ProtocolPage />} />
						<Route path="/groups/:id" element={<GroupPage />} />
						<Route path="/groups" element={<Groups />} />
						<Route path="/profile" element={<ProfilePage />} />
						<Route path="/users/:id" element={<UserPage />} />
						<Route path="/users" element={<Users />} />
						<Route path="/protocols" element={<Protocols />} />
						<Route path="/protocols/:id" element={<Protocol />} />

						<Route index element={<Navigate to="/patients" />} />
					</Route>
				</Routes>
			</BrowserRouter>
			<Toaster
				toastOptions={{
					className: 'w-screen',
				}}
				position="top-center"
			/>
		</AuthContextProvider>
	)
}
