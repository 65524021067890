import './index.css'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

import firebase from 'firebase/app'

import numeral from 'numeral'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { SWRConfig } from 'swr'

import { App } from './app/app.component'
import { FirebaseConfig } from './config/firebase.config'
import { fetcher } from './core/fetcher.core'
import { setToken } from './core/server.core'

async function bootstrap() {
	try {
		numeral.register('locale', 'it', {
			delimiters: {
				thousands: '.',
				decimal: ',',
			},
			abbreviations: {
				billion: 'mld',
				million: 'mil',
				thousand: 'k',
				trillion: 'tld',
			},
			ordinal: (x) => x.toString(),
			currency: {
				symbol: '€',
			},
		})
		numeral.locale('it')

		firebase.initializeApp(FirebaseConfig)
		if (process.env.REACT_APP_LOCAL_API === 'true') {
			firebase.firestore().useEmulator('localhost', 8080)
			firebase.auth().useEmulator('http://localhost:9099')
			firebase.functions().useEmulator('localhost', 5001)
			firebase.storage().useEmulator('localhost', 9199)
		}

		const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
			if (user?.emailVerified) {
				const tokenResult = await user.getIdTokenResult(true)
				setToken(tokenResult.token)
			} else if (user) {
				await firebase.auth().signOut()
			}

			unsubscribe()

			const root = createRoot(document.getElementById('root'))

			root.render(
				<SWRConfig value={{ fetcher: fetcher }}>
					<App />
				</SWRConfig>,
			)
		})
	} catch (error) {
		console.log(error)
	}
}

bootstrap()
