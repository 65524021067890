import { format } from 'date-fns'
import { Table, TableColumn } from '../../common/components/table.component'
import { SortDirection } from '../../common/hooks/use-sort.hook'

export type PovBoxProps = {
	title: string
	category: string
	data: any
	getPovElement: any
}

export function PovBox({ title, category, data, getPovElement }: PovBoxProps) {
	const categoryData = data.find((item) => item.category === category)
	if (!categoryData) {
		return null
	}

	const columns: TableColumn<any>[] = [
		{
			title: 'Variable',
			key: 'label',
			headerClassName: 'border p-2 text-left',
			getValue: (item) => {
				return (
					<div>
						<p>{item.label}</p>
						<small className="text-gray-700">{item.id}</small>
					</div>
				)
			},
		},
		{
			title: 'Calcolata',
			key: 'computed',
			getValue: (item) => (item.computed ? 'Si' : 'No'),
			headerClassName: 'border p-2 text-left',
		},
		{
			title: 'Data',
			key: 'date',
			getValue: (item) => (item.date ? format(new Date(item.date), 'dd/MM/yyyy') : 'N/A'),
			cellClassName: 'text-center',
			headerClassName: 'border p-2',
		},
		{
			title: 'Valore',
			key: 'value',
			getValue: (item) => item.value,
			headerClassName: 'border p-2 text-left',
			width: '200px',
		},
	]

	return (
		<div className="p4">
			<div className="m-4">
				<h1 className="text-xl font-bold text-center">{title}</h1>
				<h2 className="text-sm text-gray-700 text-center">Ultime letture</h2>
			</div>
			<Table
				columns={columns}
				items={categoryData.values}
				getItemKey={(i) => i.id}
				commonCellClassName="border p-2"
				defaultSortProperties={{
					property: 'computed',
					direction: SortDirection.Ascending,
				}}
			/>
		</div>
	)
}
