import firebase from 'firebase'
import { useContext } from 'react'
import toast from 'react-hot-toast'

import { server } from '../../../../core/server.core'
import { SignupDto } from '../../../common/app.types'
import { AuthContext } from '../contexts/auth.context'

export function useAuth(): [
	firebase.User,
	{ onLogin; onLogout; onSignup; onGoogleLogin },
] {
	const currentUser = useContext(AuthContext)

	async function onGoogleLogin() {
		const googleProvider = new firebase.auth.GoogleAuthProvider()
		const credentials = await firebase
			.app()
			.auth()
			.signInWithPopup(googleProvider)

		if (!credentials.user.emailVerified) {
			toast.error(
				'Il tuo indirizzo email non è ancora verificato. Segui il link nella email che hai ricevuto per attivare il tuo account!',
			)
			await server().get('/signup/verify', {
				params: { email: credentials.user.email },
			})

			throw new Error()
		}
	}

	async function onLogin({ email, password }) {
		const credentials = await firebase
			.auth()
			.signInWithEmailAndPassword(email, password)

		if (!credentials.user.emailVerified) {
			toast.error(
				'Il tuo indirizzo email non è ancora verificato. Segui il link nella email che hai ricevuto per attivare il tuo account!',
			)
			await server().get('/signup/verify', {
				params: { email: credentials.user.email },
			})

			throw new Error()
		}
	}

	async function onLogout() {
		await firebase.auth().signOut()
	}

	function onSignup(data: SignupDto) {
		return server().post('/signup', data)
	}

	return [currentUser, { onLogin, onLogout, onSignup, onGoogleLogin }]
}
