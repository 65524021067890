import useSWR from 'swr'

import { Group } from '../../../common/app.types'

const empty = []

export function useGroups(): [Group[], () => Promise<boolean>, { error; loading }] {
	const { data, error, mutate } = useSWR('/groups')

	return [data ?? empty, () => mutate(), { error, loading: !data && !error }]
}
