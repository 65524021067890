import { format } from 'date-fns'
import qs from 'query-string'
import { useMemo, useState } from 'react'
import { CornerUpLeft, PenTool, Tool, User, Wifi } from 'react-feather'
import toast from 'react-hot-toast'
import { useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import useSWR from 'swr'

import { server } from '../../../core/server.core'
import { Measure } from '../../common/app.types'
import { Box } from '../../common/components/box.component'
import { Breadcrumbs } from '../../common/components/breadcrumbs.component'
import { Button } from '../../common/components/button.component'
import { Loading } from '../../common/components/loading.component'
import { AdditionOptions } from '../../common/components/modal-form.component'
import { Modal } from '../../common/components/modal.component'
import { PopForm } from '../../common/components/pop-form.component'
import { PopModal } from '../../common/components/pop-modal.component'
import { Tabs } from '../../common/components/tabs.component'
import { isSet } from '../../common/utils/is-set.util'
import { TagAdditionalLoading } from '../tools/tag-form.component'
import { useConfiguration } from './hooks/use-configuration.hook'
import { usePatient } from './hooks/use-patient.hook'
import { PatientCalibrationForm } from './patient-calibration-form.component'
import { PatientNotes } from './patient-notes.component'
import { PatientPageBreathings } from './patient-page-breathings.component'
import { PatientPageDoctors } from './patient-page-doctors.component'
import { PatientPageMeasures } from './patient-page-measures.component'
import { PatientPageReadingsTable } from './patient-page-readings-table.component'
import { PatientPageStatistics } from './patient-page-statistics.component'
import { PatientPageTags } from './patient-page-tags.component'
import { PatientRealtimeChart } from './patient-realtime-chart'
import { PatientPagePov } from './patient-page-pov.component'

enum TabIndexes {
	POV = 0,
	Measures = 1,
	Weight = 2,
	Interval = 3,
	Tags = 4,
	Breathings = 5,
	Statistics = 6,
	Doctors = 7,
}

const ConfigurationValidationRules = {
	kappa_zero: (v) => isSet(v) && !isNaN(Number(v)),
	kappa_uno: (v) => isSet(v) && !isNaN(Number(v)),
	kappa_due: (v) => isSet(v) && !isNaN(Number(v)),
	kappa_tre: (v) => isSet(v) && !isNaN(Number(v)),
	kappa_quattro: (v) => isSet(v) && !isNaN(Number(v)),
	kappa_cinque: (v) => isSet(v) && !isNaN(Number(v)),
}

function useRealtimeSession(
	id: string,
	autoRefresh: boolean = false,
): [Measure, () => Promise<boolean>, { loading; error }] {
	const { data, error, mutate } = useSWR(id ? `/patients/${id}/realtime` : null, {
		refreshInterval: autoRefresh ? 10000 : 0,
	})

	return [data, () => mutate(), { loading: !error && !data, error }]
}

export function PatientPage() {
	const [isRealtimeModalOpen, setIsRealtimeModalOpen] = useState(false)
	const params = useParams<{ id: string }>()
	const [patient, fetchPatient, patientLoadingStatus] = usePatient(params?.id)
	const [realtimeSession, fetchRealtimeSession] = useRealtimeSession(params?.id, !isRealtimeModalOpen)
	const [configuration, fetchConfiguration] = useConfiguration(params?.id)
	const [selectedTab, setSelectedTab] = useState(0)
	const location = useLocation()
	const [saving, setSaving] = useState(false)

	const search = useMemo(() => {
		return qs.parse(location?.search)
	}, [location])

	async function handleInviteDoctor({ email }) {
		try {
			await server().post(`/patients/${params.id}/invite`, {
				email,
			})
			await fetchPatient()

			toast.success('Invito inviato correttamente!')
		} catch (error) {
			console.log(error)

			toast.error("Si è verificato un errore durante l'invio dell'invito.")

			return Promise.reject()
		}
	}

	async function handleSaveTag(data) {
		try {
			await server().post(`/patients/${params.id}/tags`, data)
			await fetchPatient()

			toast.success('Tag salvato correttamente!')
		} catch (error) {
			console.log(error)
			toast.error('Si è verificato un errore durante il salvataggio del tag!')
		}
	}

	async function handleSaveBreathing(data) {
		try {
			await server().post(`/patients/${params.id}/breathings`, data)
			await fetchPatient()

			toast.success('Respirazione salvata correttamente!')
		} catch (error) {
			console.log(error)
			toast.error('Si è verificato un errore durante il salvataggio della respirazione!')
		}
	}

	async function handleSaveConfiguration(data) {
		try {
			await server().post(`/patients/${params.id}/configuration`, data)
			await fetchConfiguration()

			toast.success('Calibrazione salvata correttamente!')
		} catch (error) {
			console.log(error)
			toast.error('Si è verificato un errore durante il salvataggio della calibrazione!')
		}
	}

	async function handleSaveReading(data) {
		try {
			await server().post(`/patients/${params.id}/`)

			toast.success('Lettura salvata correttamente!')
		} catch (error) {
			toast.error('Impossibile salvare la lettura.')
			return Promise.reject(error)
		}
	}

	async function handleResetCalibration() {
		try {
			await server().post(`/patients/${params.id}/resetCalibration`)

			toast.success('Calibrazione reimpostata correttamente!')
		} catch (error) {
			toast.error('Impossibile reimpostare la calibrazione.')
			return Promise.reject(error)
		}
	}

	async function handleDeleteOrRestoreTag(
		uid,
		{ setLoading = null, onCancel = null, forceStatus = null, silent = false },
	) {
		setLoading?.(TagAdditionalLoading.Delete)
		try {
			let url = `/patients/${params.id}/tags/${uid}`
			if (forceStatus) {
				url += `?forceStatus=${forceStatus}`
			}

			await server().delete(url)
			await fetchPatient()

			if (!silent) {
				toast.success('Tag modificato correttamente!')
				onCancel?.()
			}
		} catch (error) {
			if (!silent) {
				toast.error('Impossibile modificare il tag.')
				onCancel?.()
			}
		} finally {
			setLoading?.(false)
		}
	}

	async function handleDeleteOrRestoreBreathing(
		uid,
		{ setLoading = null, onCancel = null, forceStatus = null, silent = false },
	) {
		setLoading?.(TagAdditionalLoading.Delete)
		try {
			let url = `/patients/${params.id}/breathings/${uid}`
			if (forceStatus) {
				url += `?forceStatus=${forceStatus}`
			}

			await server().delete(url)
			await fetchPatient()

			if (!silent) {
				toast.success('Respirazione modificata correttamente!')
				onCancel?.()
			}
		} catch (error) {
			if (!silent) {
				toast.error('Impossibile modificare la respirazione.')
				onCancel?.()
			}
		} finally {
			setLoading?.(false)
		}
	}

	async function handleClearCalibration(tag, options: AdditionOptions) {
		options.setLoading(TagAdditionalLoading.ClearCalibration)
		try {
			await server().delete(`/patients/${params.id}/tags/${tag.uid}/calibration`)
			await fetchPatient()

			toast.success('Calibrazione tag cancellata correttamente!')
			options.onCancel()
		} catch (error) {
			toast.error('Impossibile cancellare la calibrazione del tag.')
			options.onCancel()
		} finally {
			options.setLoading(false)
		}
	}

	if (patientLoadingStatus.loading) {
		return <Loading />
	}

	if (!patient) {
		return null
	}

	return (
		<div>
			<Modal
				open={isRealtimeModalOpen}
				onClose={() => setIsRealtimeModalOpen(false)}
				onClosed={() => {
					fetchRealtimeSession()
				}}
			>
				<Modal.Title>Tempo Reale</Modal.Title>
				<Modal.Content>
					<PatientRealtimeChart
						session={realtimeSession}
						patient={patient}
						label="HRV"
						title={
							realtimeSession
								? `Sessione del ${format(+new Date(+realtimeSession.uid), 'dd/MM/yyyy')}`
								: ''
						}
					/>
				</Modal.Content>
				<Modal.Footer>
					<Button variant="info" onClick={() => setIsRealtimeModalOpen(false)}>
						Chiudi
					</Button>
				</Modal.Footer>
			</Modal>

			{!search.fromGroup && (
				<Breadcrumbs
					items={[
						{ title: 'Persone', link: '/patients' },
						{ title: patient.name ?? patient.email, link: '' },
					]}
				/>
			)}
			{search.fromGroup && (
				<Breadcrumbs
					items={[
						{ title: 'Gruppi', link: '/groups' },
						{
							title: search.fromGroupName as string,
							link: `/groups/${search.fromGroup}`,
						},
						{ title: patient.name ?? patient.email, link: '' },
					]}
				/>
			)}
			<Box
				title={
					<span>
						<span className="hidden md:inline">Persona: </span>
						<span className="font-bold text-main">{patient.name ?? 'N/A'}</span>
					</span>
				}
				icon={<User size={22} />}
			>
				<div className="flex flex-col lg:flex-row justify-between lg:align-center">
					<div>
						<p>
							Email: <span className="font-bold">{patient.email}</span>
						</p>
						{patient.invited && (
							<p>
								In visione da: <span className="font-bold">{patient.owner.name}</span>
							</p>
						)}
						{patient.groups?.[0] && (
							<p>
								Appartiene al gruppo:{' '}
								<Link to={`/groups/${patient.groups[0].uid}`} className="link font-bold">
									{patient.groups[0].name}
								</Link>{' '}
							</p>
						)}
					</div>
					{!patient.view && (
						<div className="mt-2 lg:mt-0">
							<PopModal
								actions={(onClose) => (
									<Button variant="primary" onClick={onClose}>
										Chiudi
									</Button>
								)}
								title="Note"
								icon={<PenTool size={18} className="mr-2" />}
								content={<PatientNotes patient={patient} />}
								containerClassName="w-full lg:w-auto"
							>
								<Button variant="info" className="w-full lg:w-auto">
									<PenTool size={18} className="mr-2" /> Note
								</Button>
							</PopModal>
							<PopForm
								title="Parametri"
								icon={<Tool size={18} className="mr-2" />}
								validationRules={ConfigurationValidationRules}
								cancelLabel="Chiudi"
								disabled={saving}
								containerClassName="w-full lg:w-auto"
								footerAddition={({ data, loading }) => (
									<Button
										loading={saving}
										disabled={loading}
										variant="success"
										outline
										onClick={async () => {
											setSaving(true)
											try {
												await handleSaveConfiguration(data)
											} finally {
												setSaving(false)
											}
										}}
									>
										Salva
									</Button>
								)}
								form={(data, onChange, validationStatus) => {
									return (
										<PatientCalibrationForm
											patient={patient}
											data={data}
											onChange={onChange}
											validationStatus={validationStatus}
											configuration={configuration}
											realtimeSession={realtimeSession}
										/>
									)
								}}
							>
								<Button variant="info" className="w-full lg:w-auto">
									<Tool size={18} className="mr-2" /> Parametri
								</Button>
							</PopForm>
							<PopForm
								title="Reimposta Calibrazione"
								icon={<CornerUpLeft size={18} className="mr-2" />}
								cancelLabel="No"
								confirmLabel="Sì"
								onConfirm={handleResetCalibration}
								disabled={saving}
								containerClassName="w-full lg:w-auto"
								form={() => {
									return (
										<div>
											<p className="mb-4">Vuoi veramente reimpostare la calibrazione?</p>
											<p className="text-gray-600">
												Questa operazione avrà effetto solamente sulla calibrazione globale. Per
												reimpostare la calibrazione di un tag specifico, utilizza il pulsante
												dedicato all'interno della scheda di modifica del tag stesso.
											</p>
										</div>
									)
								}}
							>
								<Button variant="info" className="w-full lg:w-auto">
									<CornerUpLeft size={18} className="mr-2" /> Reimposta Calibrazione
								</Button>
							</PopForm>

							<Button
								variant="info"
								disabled={!realtimeSession}
								className="w-full lg:w-auto"
								onClick={() => setIsRealtimeModalOpen(true)}
							>
								<Wifi size={18} className="mr-2" /> Tempo Reale
							</Button>
							{/* </PopModal> */}
						</div>
					)}
				</div>
			</Box>

			<Box bodyClassName="">
				<Tabs selectedTab={selectedTab} onSelectTab={setSelectedTab}>
					<Tabs.Tab title="POV" />
					<Tabs.Tab title="Misure" />
					<Tabs.Tab
						title={
							<span>
								Letture
								<br />
								<small>Peso</small>
							</span>
						}
					/>
					<Tabs.Tab
						title={
							<span>
								Letture
								<br />
								<small>Intervallo</small>
							</span>
						}
					/>
					<Tabs.Tab title="Tag" />
					<Tabs.Tab title="Respirazioni" />
					<Tabs.Tab title="Statistiche" />
					{!patient.invited && <Tabs.Tab title="Professionisti" />}
				</Tabs>
				<div className="bg-white border-b p-4">
					{selectedTab === TabIndexes.POV && <PatientPagePov patient={patient} />}
					{selectedTab === TabIndexes.Measures && <PatientPageMeasures patient={patient} />}

					{selectedTab === TabIndexes.Weight && (
						<PatientPageReadingsTable patient={patient} type="weight" onSaveReading={handleSaveReading} />
					)}
					{selectedTab === TabIndexes.Interval && (
						<PatientPageReadingsTable patient={patient} type="interval" onSaveReading={handleSaveReading} />
					)}

					{selectedTab === TabIndexes.Tags && (
						<PatientPageTags
							patient={patient}
							onSaveTag={handleSaveTag}
							onDeleteOrRestoreTag={handleDeleteOrRestoreTag}
							onClearCalibration={handleClearCalibration}
						/>
					)}
					{selectedTab === TabIndexes.Breathings && (
						<PatientPageBreathings
							patient={patient}
							onSaveBreathing={handleSaveBreathing}
							onDeleteOrRestoreBreathing={handleDeleteOrRestoreBreathing}
						/>
					)}
					{selectedTab === TabIndexes.Statistics && <PatientPageStatistics patient={patient} />}
					{selectedTab === TabIndexes.Doctors && (
						<PatientPageDoctors patient={patient} onInvite={handleInviteDoctor} />
					)}
				</div>
			</Box>
		</div>
	)
}
