import hotkey from 'hotkeys-js'
import { useEffect } from 'react'

export function useHotkey(key, action, options = {}) {
	useEffect(() => {
		if (key) {
			hotkey(key, options, action)
		}

		return () => {
			if (key) {
				hotkey.unbind(key)
			}
		}
	}, [key, action, options])

	return hotkey.unbind.bind(hotkey, key)
}
