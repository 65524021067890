import { useEffect, useState } from 'react'
import { Claims } from '../../../common/app.types'
import { useAuth } from './use-auth.hook'

export function useClaims() {
	const [user] = useAuth()
	const [claims, setClaims] = useState<Claims>({})

	useEffect(() => {
		async function fetchClaims() {
			if (!user) {
				setClaims({})
				return
			}

			const tokenResult = await user.getIdTokenResult()
			setClaims({
				admin: tokenResult.claims?.admin ?? false,
				doctor: tokenResult.claims?.doctor ?? false,
				guest: tokenResult.claims?.guest ?? false,
			})
		}

		fetchClaims()
	}, [user])

	return claims
}
