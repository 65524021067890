import { useRef, useCallback, useEffect } from 'react'

export function useStableCallbackReference(callback: CallableFunction) {
	const ref = useRef<CallableFunction>(callback)

	useEffect(() => {
		ref.current = callback
	}, [callback])

	const stableCallback = useCallback((...args: any[]) => {
		return ref.current?.(...args)
	}, [])

	return stableCallback
}
