export function PatientsHelp() {
	return (
		<div>
			<h4 className="uppercase pb-4 border-b-2 border-main-dark mb-4 font-semibold">Elenco Persone</h4>
			<div className="text-gray-700">
				<p className="pb-2">
					Elenco delle persone attualmente seguite. Cliccando su{' '}
					<strong className="bold text-main uppercase">'Gestisci inviti ricevuti'</strong> puoi visualizzare
					gli inviti che hai ricevuto da altri professionisti per seguire le loro persone.
				</p>
				<p className="pb-2">
					Puoi <strong>cercare una persona per nome oppure per email di registrazione</strong>, e
					visualizzarne i dettagli cliccando sulla cella corrispondente all'interno dell'elenco.
				</p>
				<p className="pb-2">
					Se vuoi <strong>assegnare un tag o una respirazione a più di una persona</strong> puoi utilizzare i
					pulsanti <strong className="bold text-main uppercase">'Assegnazione Massiva Tag'</strong> o{' '}
					<strong className="bold text-main uppercase">'Assegnazione Massiva Respirazioni'</strong>.
				</p>
			</div>
		</div>
	)
}
