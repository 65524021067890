import { useMemo } from 'react'
import useSWR from 'swr'
import { Therapy } from '../../../common/app.types'

export function useProtocols(): [Therapy[], () => Promise<boolean>, { loading; error }] {
	const { data, mutate, error } = useSWR('/therapies')

	const protocols = useMemo(() => {
		if (!data) {
			return []
		}

		return data
	}, [data])

	return [protocols, () => mutate(), { loading: !data && !error, error }]
}
