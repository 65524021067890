import { format } from 'date-fns'
import numeral from 'numeral'
import { sum } from 'ramda'
import { useMemo, useState } from 'react'
import { ArrowDown, ArrowUp } from 'react-feather'
import { Measure } from '../../common/app.types'

import { Button } from '../../common/components/button.component'
import { Input, InputAddition, InputAdditionPosition } from '../../common/components/input.component'
import { Modal } from '../../common/components/modal.component'
import { Table, TableColumn } from '../../common/components/table.component'
import { useFilter } from '../../common/hooks/use-filter.hook'
import { useForm } from '@polaritybit/use-form'
import { SortDirection } from '../../common/hooks/use-sort.hook'
import { getDurationString } from '../../common/utils/get-duration-string.util'
import { PatientPageCharts } from './patient-page-charts.component'
import { PatientSessionOverviewChart } from './patient-session-overview'
import { getType } from './utils/get-type.util'

const sortPropertyFnMap = {
	title: (item) => item.title,
	type: (item) => item.type,
	duration: (item) => sum(item.stats),
	uid: (item) => +item.uid,
	hrv: (item) => +item.hrv,
}

function getSortProperty(key) {
	return sortPropertyFnMap[key] ?? 'title'
}

const MeasureFilterRules = {
	title: (v, f) => {
		if (f === '0') {
			return true
		}

		return v === f
	},
	type: (v, f) => {
		if (f === '0') {
			return true
		}

		return v === f
	},
}

export function PatientPageMeasures({ patient }) {
	const [mobileSortProperty, setMobileSortProperty] = useState('uid')
	const [openSession, setOpenSession] = useState<Measure>(null)
	const [filter, setFilter] = useForm({ title: '0', type: '0' })

	const filteredMeasures = useFilter(MeasureFilterRules, filter, patient?.measures ?? [])

	const measureTableColumns = useMemo((): TableColumn[] => {
		return [
			{
				key: 'mobile-cell',
				renderHeader({ column, onSort, sortProperties }) {
					return (
						<th className="table-cell lg:hidden">
							<Input
								label="Ordina per"
								select
								onChange={(e) => {
									onSort(getSortProperty(e.currentTarget.value))
									setMobileSortProperty(e.currentTarget.value)
								}}
								value={mobileSortProperty}
								append={
									<InputAddition position={InputAdditionPosition.End} condensed>
										<Button variant="primary" onClick={() => onSort(sortProperties.property)}>
											{sortProperties.direction === SortDirection.Ascending ? (
												<ArrowUp size={18} />
											) : (
												<ArrowDown size={18} />
											)}
										</Button>
									</InputAddition>
								}
							>
								<option value="title">Titolo</option>
								<option value="type">Tag</option>
								<option value="duration">Durata</option>
								<option value="uid">Data Inizio</option>
								<option value="hrv">HRV</option>
							</Input>
						</th>
					)
				},
				headerClassName: 'lg:hidden',
				cellClassName: 'table-cell lg:hidden',
				title: '',
				getValue(item) {
					const duration = sum(item.stats)
					const date = new Date(0)
					date.setMilliseconds(duration)

					const durationString = getDurationString(sum(item.stats))

					return (
						<div className="shadow border p-4 mb-2 bg-white flex">
							<div className="flex-1">
								<div className="flex justify-between">
									<span className="font-bold">{item.title}</span>
									<span className="italic">{durationString}</span>
								</div>
								<div className="flex justify-between">
									<span className="text-xs">{getType(item.type)}</span>
									<span className="text-xs">{format(new Date(+item.uid), 'dd/MM/yyyy HH:mm')}</span>
								</div>
							</div>
							<div className="flex justify-center items-center ml-4 pl-2 border-l w-20">
								<span className="font-bold text-xl text-yellow-900">
									{numeral(item.hrv).format('0,0.00')}
								</span>
							</div>
						</div>
					)
				},
			},
			{
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell p-4',
				key: 'title',
				title: 'Tag',
				getSortProperty: getSortProperty('title'),
			},
			{
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell p-4',
				key: 'type',
				title: 'Tipo',
				getSortProperty: getSortProperty('type'),
				getValue(item) {
					return getType(item.type)
				},
			},
			{
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell p-4',
				key: 'duration',
				title: 'Durata',
				getSortProperty: getSortProperty('duration'),
				getValue(item) {
					return getDurationString(sum(item.stats))
				},
			},
			{
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell p-4',
				key: 'uid',
				title: 'Data Inizio',
				getSortProperty: getSortProperty('uid'),
				getValue(item) {
					return format(new Date(+item.uid), 'dd/MM/yyyy hh:mm')
				},
			},
			{
				headerClassName: 'hidden lg:table-cell text-right p-4',
				cellClassName: 'hidden lg:table-cell text-right p-4',
				key: 'hrv',
				title: 'HRV',
				getSortProperty: getSortProperty('hrv'),
				getValue(item) {
					return numeral(item.hrv).format('0,0.00')
				},
			},
		]
	}, [mobileSortProperty])

	if (!patient) {
		return null
	}
	return (
		<>
			<Modal
				open={!!openSession}
				onClose={() => {
					setOpenSession(null)
				}}
				onClosed={() => {}}
			>
				<Modal.Title>
					Sessione {openSession?.title} del{' '}
					{openSession ? format(new Date(+openSession.uid), 'dd/MM/yyyy') : ''}
				</Modal.Title>
				<Modal.Content>
					<PatientSessionOverviewChart session={openSession} patient={patient} label="HRV" title="" />
				</Modal.Content>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={() => {
							setOpenSession(null)
						}}
					>
						Chiudi
					</Button>
				</Modal.Footer>
			</Modal>

			<div className="grid grid-cols-1 md:grid-cols-3">
				<div className="grid md:col-start-3 grid-cols-2">
					<Input label="Tag" name="title" onChange={setFilter} select value={filter.title}>
						<option value={'0'}>Tutti</option>
						{patient.tags?.map((tag) => {
							return (
								<option key={tag.uid} value={tag.title}>
									{tag.title}
								</option>
							)
						})}
					</Input>
					<Input label="Tipo" name="type" onChange={setFilter} select value={filter.type}>
						<option value="0">Tutti</option>
						<option value="open">Libera</option>
						<option value="training">Registrazione</option>
						<option value="breathing">Respirazione</option>
					</Input>
				</div>
			</div>

			<PatientPageCharts patient={patient} measures={filteredMeasures} />
			{filteredMeasures?.length > 0 && (
				<Table
					items={filteredMeasures}
					columns={measureTableColumns}
					getItemKey={(m) => m.uid}
					onRowClick={(item) => setOpenSession(item)}
					rowClassName="lg:hover:bg-gray-100 cursor-pointer lg:odd:bg-gray-50"
					headerRowClassName="lg:border-b"
					defaultSortProperties={{
						property: getSortProperty('uid'),
						direction: SortDirection.Descending,
					}}
				/>
			)}
		</>
	)
}
