export function getIntervalColor(interval) {
	switch (interval) {
		case 0: {
			return '#f44336'
		}
		case 1: {
			return '#ffab40'
		}
		case 2: {
			return '#8bc34a'
		}
		case 3: {
			return '#ffab40'
		}
		case 4: {
			return '#f44336'
		}

		default: {
			return '#333'
		}
	}
}
