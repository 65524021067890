import { useEffect } from 'react'
import { Mail } from 'react-feather'

import { Input, InputAdditionPosition, InputInnerAddition } from '../../common/components/input.component'

export function PatientPageInviteDoctorForm({ data, onChange, validationStatus }) {
	useEffect(() => {
		if (!data) {
			onChange({}, true)
		}
	}, [data, onChange])

	return (
		<div>
			<p className="text-main-dark mb-4">Puoi invitare un altro professionista a seguire questa persona.</p>
			<p className="text-gray-900 mb-4">
				Inserisci l'indirizzo email del professionista da invitare. Se è già registrato a MindToMove, potrà
				accettare l'invito con il suo account; in caso contrario, potrà creare un nuovo account gratuitamente
				per seguire la persona.
			</p>
			<Input
				label="Email Professionista da invitare"
				name="email"
				before={
					<InputInnerAddition position={InputAdditionPosition.Start}>
						<Mail size={18} className="text-gray-500" />
					</InputInnerAddition>
				}
				value={data?.email}
				onChange={onChange}
				error={validationStatus.email?.error}
				errorText={validationStatus.email?.message}
				inputProps={{ autoComplete: 'off' }}
			/>
		</div>
	)
}
