import useSWR from 'swr'

import { User } from '../../../common/app.types'

const empty = []

export function useUsers(): [User[], () => Promise<boolean>, { loading; error }] {
	const { data, error, mutate } = useSWR('/users')

	return [data ?? empty, () => mutate(), { loading: !data && !error, error }]
}
