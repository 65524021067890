import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRef } from 'react'

import { useForm } from '@polaritybit/use-form'
import { useStableCallbackReference } from '../hooks/use-stable-callback-reference.hook'
import { useValidation } from '../hooks/use-validation.hook'
import { Button } from './button.component'
import { Modal } from './modal.component'
import { Spinner } from './spinner.component'

const noop = function () {}

export type AdditionOptions = {
	onConfirm?: Function
	onCancel?: Function
	data?: any
	loading?: boolean
	additionalLoading: number | boolean
	setLoading?: Function
}

export type ModalFormProps = {
	title: React.ReactNode
	icon?: React.ReactNode
	form: (data: any, onChange: Function, validationStatus?: any, options?: AdditionOptions) => React.ReactNode
	cancelLabel?: React.ReactNode
	confirmLabel?: React.ReactNode
	onConfirm?: Function
	onCancel?: Function
	onClose: () => void
	onClosed?: () => void
	open: boolean
	cancelVariant?: string
	confirmVariant?: string
	validationRules?: any
	initialData?: any
	footerAddition?: ({ onCancel, onConfirm, data }: AdditionOptions) => React.ReactNode
}

export const ModalForm = function ({
	title,
	icon,
	form,
	cancelLabel = 'Cancel',
	confirmLabel = 'Ok',
	onConfirm,
	onCancel,
	cancelVariant = 'default',
	confirmVariant = 'success',
	validationRules = {},
	footerAddition,
	open,
	onClose,
	onClosed,
}: ModalFormProps) {
	const [confirming, setConfirming] = useState(false)
	const [cancelling, setCancelling] = useState(false)
	const [additionalLoading, setAdditionalLoading] = useState<number | boolean>(false)
	const loading = useMemo(() => {
		return confirming || cancelling || !!additionalLoading
	}, [confirming, cancelling, additionalLoading])

	const [data, onChange] = useForm()
	const [validationStatus, { validate, resetValidationStatus }] = useValidation(validationRules)

	const stableOnConfirm = useStableCallbackReference(onConfirm)
	const stableOnCancel = useStableCallbackReference(onCancel)
	const stableOnClose = useStableCallbackReference(onClose)
	const stableOnClosed = useStableCallbackReference(onClosed)

	useEffect(() => {
		resetValidationStatus()
	}, [data, resetValidationStatus])

	const handleConfirm = useCallback(
		async (e) => {
			e.preventDefault()
			e.stopPropagation()

			try {
				if (!validate(data)) {
					return
				}

				const result = stableOnConfirm?.(data) ?? noop()
				if (result instanceof Promise) {
					setConfirming(true)
					await result
				}
				stableOnClose()
			} catch (error) {
				console.error(error)
				setConfirming(false)
			}
		},
		[stableOnConfirm, data, validate, stableOnClose],
	)

	const handleCancel = useCallback(async () => {
		try {
			const result = stableOnCancel?.() ?? noop()
			if (result instanceof Promise) {
				setCancelling(true)
				await result
			}
			stableOnClose()
		} catch {
			setCancelling(false)
		}
	}, [stableOnCancel, stableOnClose])

	const handleOnClosed = useCallback(() => {
		onChange(null, true)
		setCancelling(false)
		setConfirming(false)
		setAdditionalLoading(false)
		stableOnClosed?.()
	}, [stableOnClosed, onChange])

	const formRef = useRef<any>()

	return (
		<Modal open={open} onClose={handleCancel} onClosed={handleOnClosed}>
			<Modal.Title>
				{icon} {title}
			</Modal.Title>

			<Modal.Content>
				<form ref={formRef} onSubmit={handleConfirm}>
					{form(data, onChange, validationStatus, {
						onCancel: handleCancel,
						onConfirm: handleConfirm,
						setLoading: setAdditionalLoading,
						data,
						loading,
						additionalLoading,
					})}
				</form>
			</Modal.Content>
			<Modal.Footer
				additionalChildren={footerAddition?.({
					onCancel: handleCancel,
					onConfirm: handleConfirm,
					setLoading: setAdditionalLoading,
					data,
					loading,
					additionalLoading,
				})}
			>
				<Button disabled={loading} variant={cancelVariant ?? 'default'} onClick={handleCancel} className="mr-2">
					{cancelling && <Spinner />}
					{cancelLabel}
				</Button>
				<Button disabled={loading} variant={confirmVariant ?? 'success'} onClick={handleConfirm}>
					{confirming && <Spinner />}
					{confirmLabel}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
