import { useState, useMemo } from 'react'
import { useStableCallbackReference } from './use-stable-callback-reference.hook'

type UseValidationHookResult = [any, { validate: (any) => boolean; resetValidationStatus: () => void }]

type ValidationRuleFunction = (value: any, data: any) => boolean
type ValidationRuleObject = { rule: ValidationRuleFunction; message: string }
type ValidationRule = ValidationRuleFunction | ValidationRuleObject[]
type ValidationSchema = { [key: string]: ValidationRule }

function computeInitialValidationStatus(rules) {
	return Object.keys(rules).reduce((initialStatus, key) => {
		return {
			...initialStatus,
			[key]: { error: false },
		}
	}, {})
}

const _v = (rules: ValidationSchema, data, defaultMessage) => {
	if (Object.keys(rules).length === 0) {
		return { validationStatus: {}, validated: true }
	}

	const validationStatus = computeInitialValidationStatus(rules)

	const validated = Object.keys(rules).reduce((result, key) => {
		const ruleData = rules[key]
		if (!ruleData) {
			return result
		}

		if (Array.isArray(ruleData)) {
			for (const { rule, message } of ruleData) {
				const status = rule.call(undefined, data?.[key], data)
				if (!status) {
					validationStatus[key] = {
						error: true,
						message: message ?? defaultMessage,
					}
					break
				}
			}
		} else {
			const status = ruleData.call(undefined, data?.[key], data)
			if (!status) {
				validationStatus[key] = { error: true, message: defaultMessage }
			}
		}

		return result === false ? false : !validationStatus[key].error
	}, true)

	return { validationStatus, validated }
}

export const useValidation = (
	rules: ValidationSchema,
	{ defaultMessage = 'Questo campo è obbligatorio' } = {},
): UseValidationHookResult => {
	const initialValidationStatus = useMemo(() => {
		return computeInitialValidationStatus(rules)
	}, [rules])
	const [validationStatus, setValidationStatus] = useState(computeInitialValidationStatus(initialValidationStatus))
	const resetValidationStatus = useStableCallbackReference(function resetValidationStatus() {
		setValidationStatus(computeInitialValidationStatus(initialValidationStatus))
	})

	if (!rules) {
		throw new Error('Rules are required in order to use validation!')
	}

	const validate = useStableCallbackReference((data) => {
		const { validationStatus, validated } = _v(rules, data, defaultMessage)
		setValidationStatus(validationStatus)

		return validated
	})

	return [validationStatus, { validate, resetValidationStatus }]
}
