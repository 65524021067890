import { useCallback, useMemo, useState } from 'react'
import { ArrowDown, ArrowUp, Bell, Edit, Pause, Plus, RotateCw, Tag, Trash, XCircle } from 'react-feather'
import toast from 'react-hot-toast'
import { Button } from '../../common/components/button.component'
import { Checkbox } from '../../common/components/checkbox.component'
import { Loading } from '../../common/components/loading.component'
import { ModalForm } from '../../common/components/modal-form.component'
import { Spinner } from '../../common/components/spinner.component'

import { Table, TableColumn } from '../../common/components/table.component'
import { Tooltip } from '../../common/components/tooltip.component'
import { SortDirection } from '../../common/hooks/use-sort.hook'
import { BreathingForm } from '../tools/breathing-form.component'
import { BreathingValidationRules } from './validation-rules/breathing.validation-rules'

const sortPropertyFnMap = {
	uid: (item) => item.uid,
	title: (item) => item.title.toLowerCase(),
}

function getSortProperty(key) {
	return sortPropertyFnMap[key] ?? 'title'
}

function useBreathingForm(): [boolean, any, { onOpen; onClose; onClosed }] {
	const [isOpen, setIsOpen] = useState(false)
	const [breathing, setBreathing] = useState()

	const onOpen = useCallback(async function onOpen(item) {
		setBreathing(item)
		setIsOpen(true)
	}, [])
	const onClose = useCallback(function onClose() {
		setIsOpen(false)
	}, [])
	const onClosed = useCallback(function onClosed() {
		setBreathing(null)
	}, [])

	return [isOpen, breathing, { onOpen, onClose, onClosed }]
}

export function GroupPageBreathings({ breathings, onSaveBreathing, onDeleteOrRestoreBreathing }) {
	const [isBreathingFormOpen, breathingFormData, breathingFormHandlers] = useBreathingForm()
	const [checkedBreathings, setCheckedBreathings] = useState<string[]>([])
	const [loading, setLoading] = useState(false)

	const tagsTableColumns = useMemo((): TableColumn[] => {
		return [
			{
				key: 'mobile-cell',
				title: '',
				headerClassName: 'hidden',
				cellClassName: 'table-cell lg:hidden',
				getValue(item) {
					return (
						<div className="shadow border p-4 mb-2 bg-white flex">
							<div className="flex-1 flex justify-between items-center">
								<span className="font-bold">{item.title}</span>
								<div className="grid grid-cols-4 gap-4">
									<div className="flex flex-col items-center justify-center">
										<ArrowUp size={16} /> {item.inspiration} s
									</div>
									<div className="flex flex-col items-center justify-center">
										<Pause size={16} /> {item.inspause} s
									</div>
									<div className="flex flex-col items-center justify-center">
										<ArrowDown size={16} /> {item.expiration} s
									</div>
									<div className="flex flex-col items-center justify-center">
										<Pause size={16} /> {item.expause} s
									</div>
								</div>
							</div>
						</div>
					)
				},
			},
			{
				key: 'checkbox',
				title: '',
				cellClassName: 'hidden lg:table-cell w-8',
				renderHeader(props: any) {
					return (
						<div className="hidden lg:block p-4 cursor-pointer">
							<Checkbox
								className="cursor-pointer"
								checked={checkedBreathings.length === breathings.length}
								onChange={(e) => {
									if (e.target.checked) {
										setCheckedBreathings(breathings.map((t) => t.uid))
									} else {
										setCheckedBreathings([])
									}
								}}
								inputProps={{ readOnly: true }}
							/>
						</div>
					)
				},
				getValue(item) {
					if (item.globalTagId) {
						return null
					}

					return (
						<Checkbox
							checked={checkedBreathings.includes(item.uid)}
							inputProps={{ readOnly: true }}
							preventBubbling={false}
						/>
					)
				},
			},
			{
				key: 'isDeleted',
				title: '',
				cellClassName: 'hidden lg:table-cell w-16',
				getValue(item) {
					if (item.isDeleted) {
						return (
							<Tooltip content="Oscurato">
								<XCircle size={16} className="inline text-red-500" />
							</Tooltip>
						)
					}

					return null
				},
			},
			{
				key: 'title',
				title: 'Nome Respirazione',
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell',
				getSortProperty: getSortProperty('title'),
			},
			{
				key: 'inspiration',
				title: 'Inspirazione',
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell',
				getValue(item) {
					return `${+item.inspiration} sec.`
				},
			},
			{
				key: 'inspause',
				title: 'Pausa Inspirazione',
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell',
				getValue(item) {
					return `${+item.inspause} sec.`
				},
			},
			{
				key: 'expiration',
				title: 'Espirazione',
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell',
				getValue(item) {
					return `${+item.expiration} sec.`
				},
			},
			{
				key: 'expause',
				title: 'Pausa Espirazione',
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell',
				getValue(item) {
					return `${+item.expause} sec.`
				},
			},
			{
				key: 'actions',
				title: '',
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell lg:p-0 lg:px-4 text-right w-8',
				getValue(item) {
					return (
						<>
							<Button
								variant="primary"
								onClick={(e) => {
									e.stopPropagation()
									breathingFormHandlers.onOpen(item)
								}}
							>
								<Edit size={16} />
							</Button>
						</>
					)
				},
			},
		]
	}, [breathingFormHandlers, breathings, checkedBreathings])

	async function handleDeleteMultipleBreathings() {
		for (const uid of checkedBreathings) {
			await onDeleteOrRestoreBreathing(uid, { setLoading, silent: true, forceStatus: 'delete' })
		}
		toast.success('Operazione completata!')
	}

	async function handleRestoreMultipleBreathings() {
		for (const uid of checkedBreathings) {
			await onDeleteOrRestoreBreathing(uid, { setLoading, silent: true, forceStatus: 'restore' })
		}
		toast.success('Operazione completata!')
	}

	function handleDeleteBreathing({ setLoading, onCancel }) {
		if (breathingFormData) {
			onDeleteOrRestoreBreathing(breathingFormData.uid, { setLoading, onCancel })
		}
	}

	return (
		<div>
			{loading && (
				<div className="absolute inset-0 bg-white bg-opacity-95">
					<Loading />
				</div>
			)}
			<ModalForm
				open={isBreathingFormOpen}
				onClose={breathingFormHandlers.onClose}
				onClosed={breathingFormHandlers.onClosed}
				form={(data, onChange, validationStatus) => {
					return (
						<BreathingForm
							breathing={breathingFormData}
							data={data}
							onChange={onChange}
							validationStatus={validationStatus}
						/>
					)
				}}
				title={breathingFormData ? 'Modifica Respirazione' : 'Nuova Respirazione'}
				confirmLabel="Salva"
				confirmVariant="success"
				cancelLabel="Annulla"
				cancelVariant="default"
				icon={<Tag size={18} className="mr-2 inline" />}
				onConfirm={(data) => {
					return onSaveBreathing(data)
				}}
				validationRules={BreathingValidationRules}
				footerAddition={({ loading, additionalLoading, setLoading, onCancel }) => {
					return (
						<>
							{!breathingFormData?.isDeleted && (
								<Button
									disabled={loading}
									variant="danger"
									onClick={() => handleDeleteBreathing({ setLoading, onCancel })}
								>
									{additionalLoading && <Spinner />}
									<Trash className="mr-2" size={16} /> Oscura Respirazione
								</Button>
							)}
							{breathingFormData?.isDeleted && (
								<Button
									disabled={loading}
									variant="info"
									onClick={() => handleDeleteBreathing({ setLoading, onCancel })}
								>
									{additionalLoading && <Spinner />}
									<RotateCw className="mr-2" size={16} /> Ripristina Respirazione
								</Button>
							)}
						</>
					)
				}}
			/>

			{!breathings?.length && (
				<div className="flex flex-1 h-20 justify-center items-center border shadow text-gray-600 text-center">
					Il gruppo non ha nessuna respirazione associata
				</div>
			)}
			{!!breathings?.length && (
				<Table
					items={breathings}
					columns={tagsTableColumns}
					getItemKey={(m) => m.uid}
					rowClassName="lg:hover:bg-gray-100 cursor-pointer lg:odd:bg-gray-50"
					commonCellClassName="lg:p-4"
					headerRowClassName="lg:border-b"
					onRowClick={(item) => {
						setCheckedBreathings((breathings) => {
							if (breathings.includes(item.uid)) {
								return breathings.filter((tag) => tag !== item.uid)
							}

							return [...breathings, item.uid]
						})
					}}
					defaultSortProperties={{
						property: getSortProperty('title'),
						direction: SortDirection.Ascending,
					}}
				/>
			)}

			<div className="flex justify-between items-center mt-2 pt-4 border-t">
				<div>
					{checkedBreathings.length > 0 && (
						<Button variant="info" className="w-full lg:w-auto" onClick={handleRestoreMultipleBreathings}>
							<RotateCw className="mr-2" size={16} /> Ripristina Selezionati
						</Button>
					)}
					{checkedBreathings.length > 0 && (
						<Button variant="danger" className="w-full lg:w-auto" onClick={handleDeleteMultipleBreathings}>
							<Trash size={16} className="mr-2" /> Oscura Selezioanti
						</Button>
					)}
				</div>
				<Button variant="success" className="w-full lg:w-auto" onClick={() => breathingFormHandlers.onOpen()}>
					<Plus size={16} className="mr-2" /> Nuova Respirazione
				</Button>
			</div>
		</div>
	)
}
