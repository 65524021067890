import { useEffect, useState } from 'react'

import { filterList } from '../utils/filter-list.util'

export function useFilter(rules, filter, data) {
	const [filteredList, setFilteredList] = useState(data)
	useEffect(() => {
		async function getFilteredList() {
			const filteredList = await filterList(rules, filter, data)

			setFilteredList(filteredList)
		}

		getFilteredList()
	}, [rules, filter, data])

	return filteredList
}
