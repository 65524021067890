import { List } from 'react-feather'
import { useNavigate } from 'react-router'

import { Box } from '../../common/components/box.component'
import { Breadcrumbs } from '../../common/components/breadcrumbs.component'
import { Loading } from '../../common/components/loading.component'
import { useUsers } from './hooks/use-users.hook'
import { UserList } from './user-list.component'

export function Users() {
	const [users, , usersLoadingStatus] = useUsers()
	const navigate = useNavigate()

	function handleUserClick(item) {
		navigate(`/users/${item.uid}`)
	}

	if (usersLoadingStatus.loading) {
		return <Loading />
	}

	return (
		<div>
			<Breadcrumbs
				items={[
					{ title: 'Gestione Utenti', link: '' },
				]}
			/>
			<Box
				title="Tutti gli utenti"
				icon={<List size={22} />}
			>
				<UserList
					users={users}
					onUserClick={handleUserClick}
				/>
			</Box>
		</div>
	)
}
