import React, { useEffect, useState } from 'react'
import { Book, Circle, Copy, Edit, Trash } from 'react-feather'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router'

import { server } from '../../../core/server.core'
import { Group } from '../../common/app.types'
import { Box } from '../../common/components/box.component'
import { Breadcrumbs } from '../../common/components/breadcrumbs.component'
import { Button } from '../../common/components/button.component'
import { Loading } from '../../common/components/loading.component'
import { PopForm } from '../../common/components/pop-form.component'
import { Tabs } from '../../common/components/tabs.component'
import { TagAdditionalLoading } from '../tools/tag-form.component'
import { GroupForm } from './group-form.component'
import { GroupPageBreathings } from './group-page-breathings.component'
import { GroupPageCharts } from './group-page-charts.component'
import { GroupPagePatients } from './group-page-patients.component'
import { GroupPageStatistics } from './group-page-statistics.component'
import { GroupPageTags } from './group-page-tags.component'
import { GroupErrorMessages } from './group.types'
import { useGroup } from './hooks/use-group.hook'
import { useGroups } from './hooks/use-groups.hook'
import { GroupValidationRules } from './validation-rules/group.validation-rules'

export function GroupPage() {
	const params = useParams<{ id: string }>()
	const [group, refreshGroup, groupLoadingStatus] = useGroup(params.id)
	const [, fetchGroups] = useGroups()
	const [selectedTab, setSelectedTab] = useState(0)
	const navigate = useNavigate()

	useEffect(() => {
		if (groupLoadingStatus.error) {
			toast.error('Impossibile caricare le informazioni sul gruppo selezionato')

			navigate('/groups')
		}
	}, [groupLoadingStatus.error, navigate])

	async function handleSaveGroup(data) {
		try {
			await server().post(`/groups`, data)
			await refreshGroup()
			await fetchGroups()

			toast.success('Gruppo salvato correttamente!')
		} catch (error: any) {
			toast.error(`Si è verificato un errore: ${GroupErrorMessages[error]}`)

			return Promise.reject()
		}
	}

	async function handleDeleteGroup() {
		try {
			await server().delete(`/groups/${params.id}`)
			navigate('/groups')

			toast.success('Gruppo eliminato correttamente')
		} catch (error) {
			console.log(error)
			toast.error('Si è verificato un errore, impossibile eliminare il gruppo.')

			return Promise.reject()
		}
	}

	function handleOpenProtocol() {
		navigate(`/groups/${params.id}/protocol`)
	}

	async function handleSaveTag(data) {
		try {
			await server().post(`/groups/${group.uid}/tags`, data)
			await refreshGroup()

			toast.success('Tag assegnato correttamente ai partecipanti del grouppo')
		} catch (error) {
			toast.error('Impossibile assegnare il tag ai partecipanti del gruppo')

			return Promise.reject()
		}
	}

	async function handleSaveBreathing(data) {
		try {
			await server().post(`/groups/${group.uid}/breathings`, data)
			await refreshGroup()

			toast.success('Respirazione assegnata correttamente ai partecipanti del grouppo')
		} catch (error) {
			toast.error('Impossibile assegnare la respirazione ai partecipanti del gruppo')

			return Promise.reject()
		}
	}

	async function handleDeleteOrRestoreTag(
		uid,
		{ setLoading = null, onCancel = null, forceStatus = null, silent = false },
	) {
		setLoading?.(TagAdditionalLoading.Delete)
		try {
			let url = `/groups/${params.id}/tags/${uid}`
			if (forceStatus) {
				url += `?forceStatus=${forceStatus}`
			}

			await server().delete(url)
			await refreshGroup()

			if (!silent) {
				toast.success('Tag modificato correttamente!')
				onCancel?.()
			}
		} catch (error) {
			if (!silent) {
				toast.error('Impossibile modificare il tag.')
				onCancel?.()
			}
		} finally {
			setLoading?.(false)
		}
	}

	async function handleDeleteOrRestoreBreathing(
		uid,
		{ setLoading = null, onCancel = null, forceStatus = null, silent = false },
	) {
		setLoading?.(TagAdditionalLoading.Delete)
		try {
			let url = `/groups/${params.id}/breathings/${uid}`
			if (forceStatus) {
				url += `?forceStatus=${forceStatus}`
			}

			await server().delete(url)
			await refreshGroup()

			if (!silent) {
				toast.success('Respirazione modificata correttamente!')
				onCancel?.()
			}
		} catch (error) {
			if (!silent) {
				toast.error('Impossibile modificare la respirazione.')
				onCancel?.()
			}
		} finally {
			setLoading?.(false)
		}
	}

	function handleCopyLink(event: React.MouseEvent<HTMLAnchorElement>) {
		event.preventDefault()
		navigator.clipboard.writeText(event.currentTarget.href)
		toast.success('Link copiato correttamente!')
	}

	if (groupLoadingStatus.loading) {
		return <Loading />
	}

	if (groupLoadingStatus.error) {
		return null
	}

	return (
		<div>
			<Breadcrumbs
				items={[
					{ title: 'Gruppi', link: '/groups' },
					{ title: group.name, link: '' },
				]}
			/>
			<Box
				title={
					<span>
						<span className="hidden md:inline">Gruppo: </span>{' '}
						<span className="font-bold text-main">{group.name ?? 'N/A'}</span>
					</span>
				}
				icon={<Circle size={22} />}
			>
				<div className="flex flex-col lg:flex-row justify-between">
					<div>
						<p>
							Numero Partecipanti: <span className="font-bold">{group.components?.length}</span>
						</p>
						<p className="flex items-center">
							Link per la partecipazione:{' '}
							<span className="font-bold">
								<a
									onClick={handleCopyLink}
									className="underline text-blue-500 inline-flex items-center"
									href={`${process.env.REACT_APP_PUBLIC_URL}join/${group.uid}`}
								>
									<Copy className="inline-block mx-2" size={16} />
									{process.env.REACT_APP_PUBLIC_URL}join/{group.uid}
								</a>
							</span>
						</p>
					</div>
					<div className="mt-2 lg:mt-0">
						<PopForm
							title="Modifica Gruppo"
							icon={<Edit className="mr-2" size={18} />}
							form={(data: Group, onChange, validationStatus) => {
								return (
									<GroupForm
										group={group}
										data={data}
										onChange={onChange}
										validationStatus={validationStatus}
									/>
								)
							}}
							confirmLabel="Salva"
							onConfirm={handleSaveGroup}
							validationRules={GroupValidationRules}
							footerAddition={() => {
								return (
									<PopForm
										title="Elimina Gruppo"
										icon={<Trash className="mr-2" size={18} />}
										form={() => {
											return (
												<div>
													<p>
														Vuoi veramente eliminare il gruppo{' '}
														<strong>{group?.name}</strong>?
													</p>
												</div>
											)
										}}
										onConfirm={handleDeleteGroup}
										cancelLabel="No"
										confirmLabel="Sì"
										confirmVariant="danger"
									>
										<Button variant="danger" className="w-full lg:w-auto">
											<Trash className="mr-2" size={18} /> Elimina Gruppo
										</Button>
									</PopForm>
								)
							}}
						>
							<Button variant="success" className="w-full lg:w-auto">
								<Edit className="mr-2" size={18} /> Modifica Gruppo
							</Button>
						</PopForm>

						<Button variant="primary" className="w-full lg:w-auto" onClick={handleOpenProtocol}>
							<Book className="mr-2" size={18} />
							Gestisci Protocollo
						</Button>
					</div>
				</div>
			</Box>

			<Box bodyClassName="">
				<Tabs selectedTab={selectedTab} onSelectTab={setSelectedTab}>
					<Tabs.Tab title="Partecipanti" />
					<Tabs.Tab title="Evoluzioni" />
					<Tabs.Tab title="Tags" />
					<Tabs.Tab title="Respirazioni" />
					<Tabs.Tab title="Statistiche" />
				</Tabs>
				<div className="bg-white border-b p-4">
					{selectedTab === 0 && <GroupPagePatients group={group} patients={group.patients} />}
					{selectedTab === 1 && <GroupPageCharts patients={group.patients} group={group} />}
					{selectedTab === 2 && (
						<GroupPageTags
							tags={group.tags}
							onSaveTag={handleSaveTag}
							onDeleteOrRestoreTag={handleDeleteOrRestoreTag}
						/>
					)}
					{selectedTab === 3 && (
						<GroupPageBreathings
							breathings={group.breathings}
							onSaveBreathing={handleSaveBreathing}
							onDeleteOrRestoreBreathing={handleDeleteOrRestoreBreathing}
						/>
					)}
					{selectedTab === 4 && <GroupPageStatistics group={group} />}
				</div>
			</Box>
		</div>
	)
}
