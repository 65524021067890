import React from 'react'

export type BoxProps = {
	title?: React.ReactNode
	actions?: React.ReactNode
	collapsed?: boolean
	children: React.ReactNode
	className?: string
	bodyClassName?: string
	icon?: React.ReactNode
}

export const Box = function ({
	title,
	actions,
	collapsed,
	children,
	className,
	bodyClassName = 'p-4',
	icon,
}: BoxProps) {
	return (
		<div className={className}>
			{title && (
				<div className={`flex flex-col flex-1 md:flex-row justify-between md:items-center pt-2`}>
					<span className="flex flex-1 items-center mx-4">
						{icon}
						<h1 className="p-2 text-xl flex-1 font-bold">{title}</h1>
					</span>

					{actions && <div className="w-full md:w-auto px-4">{actions}</div>}
				</div>
			)}

			<div className={`transition-height transform duration-500 overflow-hidden ${collapsed ? 'h-0' : 'h-auto'}`}>
				<div className={bodyClassName}>{children}</div>
			</div>
		</div>
	)
}
