import logoSvg from '../../../resources/images/svg/logo.png'

export function Logo() {
	return (
		<img
			src={logoSvg}
			alt="MindToMove"
			className="h-64 mt-48 md:mt-0 py-4"
		/>
	)
}
