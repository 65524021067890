import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import useSWR from 'swr'
import { server } from '../../../core/server.core'
import { Note } from '../../common/app.types'
import { Button } from '../../common/components/button.component'
import { Input } from '../../common/components/input.component'
import { Spinner } from '../../common/components/spinner.component'
import { sort, descend, prop } from 'ramda'
import { Trash } from 'react-feather'
import { useClaims } from '../auth/hooks/use-claims.hook'
import { useAuth } from '../auth/hooks/use-auth.hook'

enum LoadingStatus {
	AddNote,
	DeleteNote,
}

export function usePatientNotes(id): [Note[], () => Promise<boolean>, { error; loading }] {
	const { data, mutate, error } = useSWR(id ? `/patients/${id}/notes` : null)

	const sortedData = useMemo(() => {
		if (!data) {
			return []
		}

		return sort(descend(prop('createdAt')))(data)
	}, [data])

	return [sortedData, () => mutate(), { loading: !data && !error, error }]
}

export function PatientNotes({ patient }) {
	const [notes, fetchNotes, notesLoadingStatus] = usePatientNotes(patient?.uid)
	const [currentNote, setCurrentNote] = useState('')
	const [loading, setLoading] = useState<LoadingStatus>(null)
	const claims = useClaims()
	const [user] = useAuth()

	async function handleAddNote() {
		try {
			setLoading(LoadingStatus.AddNote)

			await server().post(`/patients/${patient.uid}/notes`, {
				content: currentNote,
			})
			setCurrentNote('')
			await fetchNotes()

			toast.success('Nota inserita correttamente!')
		} catch (error) {
			console.log(error)
			toast.error("Si è verificato un errore durante l'inserimento della nota!")
		} finally {
			setLoading(null)
		}
	}

	async function handleDeleteNote(note: Note) {
		try {
			setLoading(LoadingStatus.DeleteNote)

			await server().delete(`/patients/${patient.uid}/notes/${note.uid}`)
			await fetchNotes()

			toast.success('Nota eliminata correttamente!')
		} catch (error) {
			console.log(error)
			toast.error("Si è verificato un errore durante l'eliminazione della nota!")
		} finally {
			setLoading(null)
		}
	}

	return (
		<div>
			<div className="mb-4">
				<Input
					label="Inserisci una nota su questa persona"
					value={currentNote}
					onChange={(e) => setCurrentNote(e.currentTarget.value)}
					textarea
					rows={3}
				/>
				<Button
					onClick={handleAddNote}
					variant="success"
					block
					loading={loading === LoadingStatus.AddNote}
					disabled={currentNote.trim().length === 0 || loading !== null}
				>
					Inserisci Nota
				</Button>
			</div>

			{notesLoadingStatus.loading && (
				<div className="m-4 text-center">
					<Spinner color="text-black" />
				</div>
			)}

			{!notesLoadingStatus.loading && notes.length === 0 && (
				<div className="text-center p-4 text-gray-600">Nessuna nota su questa persona</div>
			)}
			{!notesLoadingStatus.loading &&
				notes.map((note) => {
					return (
						<div
							key={note.uid}
							className="p-4 mx-2 odd:bg-gray-50 border-t border-l border-r last:border-b"
						>
							<h3 className="grid grid-cols-1 lg:grid-cols-2">
								<span className="font-bold">{note.authorName}</span>
								<div className="flex justify-end items-center">
									<span className="text-left lg:text-right text-sm italic mr-4">
										{format(+new Date(note.createdAt), 'dd/MM/yyyy HH:mm:ss')}
									</span>
									{(claims.admin || user.uid === note.authorId) && (
										<Button
											onClick={() => handleDeleteNote(note)}
											size="xs"
											variant="danger"
											outline
											loading={loading === LoadingStatus.DeleteNote}
											disabled={loading !== null}
										>
											<Trash size={14} />
										</Button>
									)}
								</div>
							</h3>
							{note.content}
						</div>
					)
				})}
		</div>
	)
}
