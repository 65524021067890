import { User as UserIcon } from 'react-feather'
import { User } from '../../common/app.types'

type UserItemProps = {
	user: User
	icon: React.ReactNode
	onClick?: React.MouseEventHandler<HTMLDivElement>
}

export function UserItem({ user, icon, onClick }: UserItemProps) {
	return (
		<div
			className="select-none border border-gray-100 bg-white shadow p-3 mb-3 lg:hover:bg-main lg:hover:text-white lg:cursor-pointer"
			onClick={onClick}
		>
			<div className="flex items-center justify-between">
				<div className="mr-4">
					<UserIcon />
				</div>
				<div className="flex-1">
					<p className="font-bold overflow-ellipsis">{user.email}</p>
					<p className="italic overflow-ellipsis">{user.name ?? 'Non identificato'}</p>
					<p className="overflow-ellipsis text-sm">
						{user.claims.admin
							? 'Amministratore'
							: user.claims.doctor
							? 'Professionista (Abbonato)'
							: 'Ospite / Persona'}
					</p>
				</div>
				<div className="ml-4">{icon}</div>
			</div>
		</div>
	)
}
