import { ArrowRightCircle, Circle } from 'react-feather'
import { Group } from '../../common/app.types'

import { Input } from '../../common/components/input.component'
import { Table } from '../../common/components/table.component'
import { useFilter } from '../../common/hooks/use-filter.hook'
import { useForm } from '@polaritybit/use-form'

const GroupFilterRules = {
	search: (v, f, i) => {
		return !f || i.name?.toLowerCase().includes(f.toLowerCase()) || i.email?.toLowerCase().includes(f.toLowerCase())
	},
}

const GroupFilterDefaultValue = {
	search: '',
}

type GroupFilter = {
	search: string
}

type GroupListProps = {
	groups: Group[]
	onGroupClick?: (group: Group) => void
	groupItemIcon?: (group: Group) => React.ReactNode
}

export function GroupList({ groups, onGroupClick, groupItemIcon }: GroupListProps) {
	const [filter, setFilter] = useForm<GroupFilter>(GroupFilterDefaultValue)

	const filteredGroups = useFilter(GroupFilterRules, filter, groups)

	return (
		<div>
			<div className="mb-8">
				<Input
					label="Cerca tra i tuoi gruppi..."
					onChange={setFilter}
					placeholder="Scrivi il nome del gruppo"
					name="search"
					value={filter.search}
					disableAutoComplete
					inputProps={{ autoComplete: 'off' }}
				></Input>
			</div>

			{filteredGroups.length === 0 && <p className="text-center my-4 text-gray-600">Nessun gruppo trovato.</p>}

			<Table
				getItemKey={(p) => p.uid}
				items={filteredGroups}
				onRowClick={onGroupClick}
				columns={[
					{
						key: 'mobile-view',
						title: '',
						cellClassName: 'block',
						headerClassName: 'hidden',
						getValue(item) {
							return (
								<div
									className={`border bg-white shadow p-3 mb-3 lg:hover:bg-main lg:hover:text-white lg:cursor-pointer`}
								>
									<div className="flex items-center justify-between">
										<div className="mr-4">
											<Circle />
										</div>
										<div className="flex-1">
											<p className="font-bold overflow-ellipsis">{item.name}</p>
											<p className="italic overflow-ellipsis">
												{item.components?.length ?? 0} partecipanti
											</p>
										</div>
										<div className="ml-4">{groupItemIcon?.(item) ?? <ArrowRightCircle />}</div>
									</div>
								</div>
							)
						},
					},
				]}
			/>
		</div>
	)
}
