import { useEffect, useState } from 'react'
import { Book, Check, Copy, Edit, Plus, Save } from 'react-feather'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import { server } from '../../../core/server.core'
import { Box } from '../../common/components/box.component'
import { Breadcrumbs } from '../../common/components/breadcrumbs.component'
import { Button } from '../../common/components/button.component'
import { Input } from '../../common/components/input.component'
import { Loading } from '../../common/components/loading.component'
import { PopForm } from '../../common/components/pop-form.component'
// import { useForm } from '@polaritybit/use-form'
import { useForm } from '@polaritybit/use-form'
import { isSet } from '../../common/utils/is-set.util'
import { CreateProtocolForm } from './create-protocol-form.component'
import { useGroup } from './hooks/use-group.hook'
import { useTherapies } from './hooks/use-therapies.hook'
import { useTherapy } from './hooks/use-therapy.hook'
import { ProtocolForm } from './protocol-form.component'
import { SwitchProtocolForm } from './switch-protocol-form.component'
import { Therapy } from '../../common/app.types'

const CreateProtocolValidationRules = {
	name: [
		{
			rule: (v) => isSet(v),
			message: 'Campo Obbligatorio',
		},
		{
			rule: (v) => v.length >= 3,
			message: 'Il nome deve essere lungo almeno 3 caratteri',
		},
	],
}

type SaveProtocolOptions = {
	implicit?: boolean
}

export function ProtocolPage() {
	const params = useParams<{ id: string }>()
	const [therapies, fetchTherapies, therapiesLoadingStatus] = useTherapies()
	const [group, refreshGroup, groupLoadingStatus] = useGroup(params.id)
	const [therapy] = useTherapy(group?.therapy_id)
	const [selectedTherapy, setSelectedTherapy] = useState<string | null>(null)
	const [data, onChange] = useForm<Therapy>(therapy)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (therapy) {
			onChange(therapy, true)
		}
	}, [therapy, onChange])

	if (groupLoadingStatus.loading || therapiesLoadingStatus.loading || therapiesLoadingStatus.loading) {
		return <Loading />
	}

	async function handleSaveProtocol(data, { implicit = false }: SaveProtocolOptions = {}) {
		setLoading(true)
		try {
			const response = await server().post('/therapies', data)
			await fetchTherapies()

			if (!implicit) {
				toast.success('Protocollo salvato correttamente!')
			}
			if (!data.uid) {
				await handleAssignProtocolToGroup(response.data.uid)
			}
		} catch (error) {
			if (!implicit) {
				toast.error(`Si è verificato un errore durante il salvataggio del protocollo: ${error}`)
			}

			return Promise.reject()
		} finally {
			setLoading(false)
		}
	}

	async function handleAssignProtocolToGroup(protocolId, duplicate = false) {
		let therapyId = protocolId
		try {
			if (duplicate) {
				const response = await server().post(`/therapies/clone`, { uid: protocolId })
				therapyId = response.data.uid
			}

			await server().post(`/groups`, { ...group, therapy_id: therapyId })
			await refreshGroup()

			setSelectedTherapy(protocolId)

			toast.success('Protocollo assegnato correttamente!')
		} catch (error) {
			toast.error(`Impossibile assegnare il protocollo: ${error}`)
		}
	}

	return (
		<div>
			<Breadcrumbs
				items={[
					{ title: 'Gruppi', link: '/groups' },
					{ title: group.name, link: `/groups/${group.uid}` },
					{ title: 'Gestione Protocollo', link: `` },
				]}
			/>
			<Box
				title={
					<span>
						Protocollo Gruppo:{' '}
						<span className="block mt-2 md:inline font-bold text-main">{group.name ?? 'N/A'}</span>
					</span>
				}
				icon={<Book size={22} />}
			>
				<div>
					{group && !group.therapy_id && (
						<div>
							<p className="mb-2">
								Questo gruppo non ha associato nessun protocollo; puoi selezionare un protocollo già
								esistente oppure crearne uno nuovo.
							</p>
							<div className="grid grid-cols-1 lg:grid-cols-5">
								<Input
									onChange={(e) => setSelectedTherapy(e.target.value)}
									value={selectedTherapy}
									disabled={therapies.length === 0}
									containerClassName="lg:col-span-2"
									select
									placeholder="Seleziona un protocollo..."
								>
									{therapies.length === 0 && <option selected>Nessun protocollo disponibile</option>}
									<option value="">Seleziona un protocollo...</option>
									{therapies.map((t) => {
										return (
											<option key={t.uid} value={t.uid}>
												{t.name}
											</option>
										)
									})}
								</Input>
								<Button
									onClick={() => handleAssignProtocolToGroup(selectedTherapy)}
									variant="primary"
									disabled={therapies.length === 0 || !selectedTherapy}
								>
									<Check size={18} className="mr-2" /> Utilizza Protocollo
								</Button>
								<Button
									onClick={() => handleAssignProtocolToGroup(selectedTherapy, true)}
									variant="info"
									disabled={therapies.length === 0 || !selectedTherapy}
								>
									<Copy size={18} className="mr-2" /> Duplica Protocollo
								</Button>
								<PopForm
									title="Crea Nuovo Protocollo"
									icon={<Plus size={18} className="mr-2" />}
									onConfirm={handleSaveProtocol}
									validationRules={CreateProtocolValidationRules}
									form={(data, onChange, validationStatus) => {
										return (
											<CreateProtocolForm
												data={data}
												onChange={onChange}
												validationStatus={validationStatus}
											/>
										)
									}}
								>
									<Button variant="success">
										<Plus size={18} className="mr-2" /> Crea Nuovo Protocollo
									</Button>
								</PopForm>
							</div>
						</div>
					)}

					{therapy && (
						<div>
							<div className="flex flex-col xl:flex-row justify-between items-center">
								<div className="flex-1 flex items-center">
									<span>Protocollo selezionato: </span>
									<input
										onBlur={() => handleSaveProtocol(data, { implicit: true })}
										name="name"
										value={data.name}
										onChange={onChange}
										className="flex-1 mx-4 font-bold border-b border-b-white focus:outline-none focus:border-b-gray-300"
									/>
								</div>
								<div className="mt-2 lg:mt-0">
									<PopForm
										title="Cambia Protocollo"
										icon={<Book className="mr-2" size={18} />}
										form={(data, onChange) => {
											return <SwitchProtocolForm data={data} onChange={onChange} group={group} />
										}}
										onConfirm={(data) => handleAssignProtocolToGroup(data.therapy_id)}
									>
										<Button variant="primary" className="w-full lg:w-auto">
											<Edit className="mr-2" size={18} /> Cambia Protocollo
										</Button>
									</PopForm>
									<Button
										loading={loading}
										onClick={() => handleSaveProtocol(data)}
										variant="success"
										className="w-full lg:w-auto"
									>
										<Save className="mr-2" size={18} /> Salva Modifiche
									</Button>
								</div>
							</div>
							<ProtocolForm
								therapy={therapy}
								data={data}
								onChange={onChange}
								onSave={handleSaveProtocol}
							/>
						</div>
					)}
				</div>
			</Box>
		</div>
	)
}
