import { format } from 'date-fns'
import { Patient } from '../../common/app.types'
import { Loading } from '../../common/components/loading.component'
import { usePatientPov } from './hooks/use-patient-pov.hook'
import { StatBox } from './stat-box.component'
import { PovBox } from './pov-box.component'
import { useState } from 'react'
import { Input } from '../../common/components/input.component'
import { Button } from '../../common/components/button.component'
import { server } from '../../../core/server.core'
import toast from 'react-hot-toast'

type PatientPagePovProps = {
	patient: Patient
}

function createPovElementGetter(data: any) {
	return function getPovElement(path: string, transform = (i) => i) {
		const [category, id] = path.split('.')
		const categoryData = data.find((item) => item.category === category)
		if (!categoryData) {
			return null
		}

		const value = categoryData.values.find((item) => item.id === id)?.value
		if (!value) {
			return null
		}

		return transform(value)
	}
}

function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

const categories = [
	{ value: 'cardiovascular', label: 'Benessere Cardiovascolare' },
	{ value: 'respiratory', label: 'Respiro' },
	{ value: 'diabetes', label: 'Diabete' },
	{ value: 'body-mass', label: 'Massa Corporea' },
	{ value: 'smoke', label: 'Fumo' },
	{ value: 'sleep', label: 'Sonno' },
	{ value: 'activity', label: 'Fitness' },
	{ value: 'nutrition', label: 'Nutrizione' },
	{ value: 'mental', label: 'Felicità' },
]

export function PatientPagePov({ patient }: PatientPagePovProps) {
	const [data, refresh, { loading, error }] = usePatientPov(patient.uid)
	const [currentCategory, setCurrentCategory] = useState('cardiovascular')

	if (loading) {
		return <Loading />
	}

	const getPovElement = createPovElementGetter(data)

	async function handleExport() {
		try {
			const response = await server().get(`/health/?userUid=${patient.uid}`)

			const csv = [
				'id;category;date;value;computed',
				...response.data
					.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
					.map((item) => {
						return `${item.id};${item.category};${item.date};${item.value ?? ''};${item.computed ? 1 : 0}`
					}),
			].join('\n')

			const blob = new Blob([csv], { type: 'text/csv' })
			const url = window.URL.createObjectURL(blob)
			const a = document.createElement('a')
			a.href = url
			a.download = `export_pov_${patient.uid}.csv`
			a.click()
			window.URL.revokeObjectURL(url)

			toast.success('Dati esportati con successo')
		} catch {
			toast.error("Errore durante l'esportazione dei dati")
			console.log(error)
		}
	}

	return (
		<div>
			<div className="p-4">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
					<StatBox
						title="Gruppo Etnico"
						value={getPovElement('onboarding.ethnicity', capitalizeFirstLetter)}
					/>
					<StatBox
						title="Data di Nascita"
						value={getPovElement('onboarding.dob', (d) => format(new Date(d), 'dd/MM/yyyy'))}
					/>
					<StatBox title="Sesso" value={getPovElement('onboarding.sex') == 1 ? 'Maschio' : 'Femmina'} />
					<StatBox
						title="Altezza"
						value={getPovElement('onboarding.height', (x) => Number(x).toFixed(2))}
						subtitle="cm"
					/>
				</div>
			</div>

			<div className="flex justify-end">
				<Button onClick={refresh}>Aggiorna</Button>
				<Button variant="primary" onClick={handleExport}>
					Esporta
				</Button>
			</div>

			<Input
				label="Categoria"
				name="type"
				onChange={(e) => setCurrentCategory(e.target.value)}
				select
				value={currentCategory}
			>
				{categories.map((category) => (
					<option key={category.value} value={category.value}>
						{category.label}
					</option>
				))}
			</Input>

			<PovBox
				title={categories.find((c) => c.value === currentCategory)?.label}
				category={currentCategory}
				data={data}
				getPovElement={getPovElement}
			/>
		</div>
	)
}
