import { useForm } from '@polaritybit/use-form'
import { ArrowRightCircle, Book, BookOpen } from 'react-feather'
import { useNavigate } from 'react-router'

import { Box } from '../../common/components/box.component'
import { Breadcrumbs } from '../../common/components/breadcrumbs.component'
import { Input } from '../../common/components/input.component'
import { Loading } from '../../common/components/loading.component'
import { Table } from '../../common/components/table.component'
import { useFilter } from '../../common/hooks/use-filter.hook'
import { SortDirection } from '../../common/hooks/use-sort.hook'
import { useClaims } from '../auth/hooks/use-claims.hook'
import { useProtocols } from './hooks/use-protocols.hook'

export function ProtocolListItem({ protocol, icon }) {
	const claims = useClaims()

	return (
		<div className="select-none border border-gray-100 bg-white shadow p-3 mb-3 lg:hover:bg-main lg:hover:text-white lg:cursor-pointer">
			<div className="flex items-center justify-between">
				<div className="mr-4">
					<Book />
				</div>
				<div className="flex-1">
					<p className="font-bold overflow-ellipsis">{protocol.name}</p>
					{claims.admin && (
						<p className="text-sm">
							{protocol.doctor} ({protocol.doctor_email})
						</p>
					)}
				</div>
				<div className="ml-4">{icon}</div>
			</div>
		</div>
	)
}

const FilterProtocolRules = {
	search: (v, f, i) => {
		return !f
			? true
			: i.name.toLowerCase().includes(f.toLowerCase()) || i.doctor.toLowerCase().includes(f.toLowerCase())
	},
}

export function Protocols() {
	const [protocols, , protocolsLoadingStatus] = useProtocols()
	const [filter, setFilter] = useForm({
		search: '',
	})
	const navigate = useNavigate()

	const filteredProtocols = useFilter(FilterProtocolRules, filter, protocols)

	function handleProtocolClick(item) {
		navigate(`/protocols/${item.uid}`)
	}

	if (protocolsLoadingStatus.loading) {
		return <Loading />
	}

	return (
		<div>
			<Breadcrumbs items={[{ title: 'Protocolli', link: '/protocols' }]} />
			<Box title="Protocolli" icon={<BookOpen size={22} />}>
				<div className="mb-8">
					<Input
						label="Cerca tra i protocolli..."
						placeholder="Scrivi il nome del protocollo"
						onChange={setFilter}
						name="search"
						value={filter.search}
						inputProps={{ autoComplete: 'off' }}
					></Input>
				</div>
				<Table
					getItemKey={(item) => item.uid}
					columns={[
						{
							key: 'mobile-view',
							title: '',
							cellClassName: 'block',
							headerClassName: 'hidden',
							getValue(item) {
								return <ProtocolListItem protocol={item} icon={<ArrowRightCircle />} />
							},
						},
					]}
					items={filteredProtocols}
					onRowClick={handleProtocolClick}
					defaultSortProperties={{
						property: 'name',
						direction: SortDirection.Ascending,
					}}
				/>
			</Box>
		</div>
	)
}
