import { useMemo } from 'react'
import { Measure, Patient } from '../../common/app.types'
import { PatientChart } from './patient-chart.component'

export type PatientPageChartsProps = {
	patient: Patient
	measures?: Measure[]
}

export function PatientPageCharts({ patient, measures }: PatientPageChartsProps) {
	const validMeasures = useMemo(() => {
		if (!patient) {
			return []
		}

		return (measures ?? patient.measures).filter((m) => !!m.end_date)
	}, [patient, measures])

	return (
		<div>
			{!validMeasures?.length && (
				<div className="flex flex-1 h-20 justify-center items-center border shadow text-gray-600 text-center">
					Nessun risultato disponibile
				</div>
			)}
			{validMeasures?.length > 0 && (
				<div className="grid grid-flow-row grid-cols-1 lg:grid-cols-3">
					<div className="m-2">
						<PatientChart
							title="HRV"
							label="HRV"
							items={validMeasures}
							getValue={(x) => +x.hrv}
							getTime={(x) => +x.uid}
						/>
					</div>
					<div className="m-2">
						<PatientChart
							title="Punteggio STD"
							items={validMeasures}
							getValue={(x) => +x.sigmaScore}
							getTime={(x) => +x.uid}
							label="Sigma Score"
						/>
					</div>
					<div className="m-2">
						<PatientChart
							title="RMSSD"
							items={validMeasures}
							getValue={(x) => +x.sigmaRRDiff}
							getTime={(x) => +x.uid}
							label="RMSSD"
						/>
					</div>
				</div>
			)}
		</div>
	)
}
