import React, { forwardRef, useMemo } from 'react'
import { Spinner } from './spinner.component'

const variants = {
	default: {
		regular: 'bg-gray-100 active:bg-gray-200 text-black',
		disabled: 'bg-gray-100 text-black',
		outline: 'border-gray-800 border-2 active:bg-gray-700 active:text-white font-bold text-gray-800',
	},
	primary: {
		regular: 'bg-main active:bg-very-dark hover:bg-very-dark text-white',
		disabled: 'bg-main opacity-50 text-gray-200',
		outline: 'border-main border-2 active:bg-main text-very-dark font-bold active:text-white',
	},
	danger: {
		regular: 'bg-red-600 active:bg-red-800 hover:bg-red-500 text-white',
		disabled: 'bg-red-400 text-white',
		outline:
			'border border-gray-300 active:bg-red-700 hover:bg-red-500 hover:text-white text-red-600 font-bold active:text-white',
	},
	success: {
		regular: 'bg-green-500 active:bg-green-700 hover:bg-green-400 text-white',
		disabled: 'bg-green-400 text-white',
		outline:
			'border border-gray-300 active:bg-green-700 hover:bg-green-500 hover:text-white text-green-600 font-bold active:text-white',
	},
	warning: {
		regular: 'bg-yellow-600 active:bg-yellow-800 text-white',
		disabled: 'bg-yellow-400 text-white',
		outline: 'border-yellow-600 border-2 active:bg-yellow-700 text-yellow-600 font-bold active:text-white',
	},
	info: {
		regular: 'bg-white active:bg-gray-300 text-black border border-gray-300 hover:bg-main hover:text-white',
		disabled: 'bg-white text-gray-400',
		outline:
			'border border-gray-300 active:bg-red-700 hover:bg-black hover:text-white text-black font-bold active:text-white ',
	},
	link: {
		regular: 'text-main hover:underline border border-transparent hover:bg-gray-100',
		disabled: 'text-blue-200',
		outline: 'text-main border border-gray-200 hover:underline hover:bg-gray-100 ',
	},
}

export type ButtonProps = {
	children: React.ReactNode
	className?: string
	variant?: string
	disabled?: boolean
	outline?: boolean
	block?: boolean
	loading?: boolean
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full-height-md'
	[x: string]: any
}

export const Button = forwardRef(function (
	{
		children,
		className,
		variant = 'default',
		disabled = false,
		outline = false,
		block = false,
		loading = false,
		size = 'md',
		...rest
	}: ButtonProps,
	ref,
) {
	const { as: tagName = 'button', ...other } = rest
	const variantClassName = useMemo(() => {
		const selectedVariant = variants[variant] ?? variants['default']
		if (disabled || loading) {
			return selectedVariant.disabled
		}

		if (outline) {
			return selectedVariant.outline ?? selectedVariant.regular
		}

		return selectedVariant.regular
	}, [variant, disabled, loading, outline])

	const sizeClassName = useMemo(() => {
		switch (size) {
			case 'xs': {
				return 'h-6 text-xs'
			}

			case 'sm': {
				return 'h-8 text-sm'
			}

			case 'md': {
				return 'h-10 text-md'
			}

			case 'lg': {
				return 'h-12 text-lg'
			}

			case 'xl': {
				return 'h-14 text-xl'
			}

			case 'full-height-md': {
				return 'h-full text-md'
			}
		}
	}, [size])

	return React.createElement(tagName, {
		className: `focus:outline-none px-4 py-2 ${sizeClassName} ${
			disabled ? 'cursor-default' : 'cursor-pointer'
		} rounded-none inline-flex items-center justify-center inline-block ${
			block ? 'w-full' : ''
		} ${variantClassName} ${className ?? ''}`,
		children: (
			<>
				{loading && <Spinner />} {children}
			</>
		),
		ref,
		disabled: disabled || loading,
		...other,
	})
})
