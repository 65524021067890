import React, { useCallback } from 'react'
import { BookOpen, Circle, List, LogOut, Settings, Users } from 'react-feather'
import { useNavigate, useLocation } from 'react-router'

import Logo from '../../../resources/images/svg/logo.png'
import { Spinner } from '../../common/components/spinner.component'
import { useAuth } from '../auth/hooks/use-auth.hook'
import { useClaims } from '../auth/hooks/use-claims.hook'
import { useProfile } from '../auth/hooks/use-profile.hook'

type SidebarItemProps = {
	icon?: React.ReactNode
	title: React.ReactNode
	href?: string
	onClick: Function
	className?: string
	active?: boolean
}

function SidebarItem({
	icon = null,
	title = '',
	href = '',
	onClick,
	className = '',
	active = false,
}: SidebarItemProps) {
	const handleClick = useCallback(
		(event) => {
			event.preventDefault()
			event.stopPropagation()

			onClick(event)
		},
		[onClick],
	)

	return (
		<li>
			<a
				className={`transition duration-300 ${
					active ? 'bg-main-middle text-main' : ''
				} hover:bg-main-middle p-4 font-bold flex-row flex items-center ${className}`}
				href={href}
				data-href={href}
				onClick={handleClick}
			>
				{icon} {title}
			</a>
		</li>
	)
}

type SidebarProps = {
	onNavigate?: (string) => void
}

export function Sidebar({ onNavigate }: SidebarProps) {
	const [user, authHandlers] = useAuth()
	const [profile, profileLoadingStatus] = useProfile()
	const claims = useClaims()
	const navigate = useNavigate()
	const location = useLocation()

	function handleLogout() {
		authHandlers.onLogout()
	}

	function handleNavigate(event) {
		navigate(event.currentTarget.dataset.href)
		if (onNavigate) {
			onNavigate(event.currentTarget.dataset.href)
		}
	}

	if (profileLoadingStatus.loading) {
		return (
			<div className="flex justify-center items-center h-full ">
				<Spinner color="text-main" margin="" />
			</div>
		)
	}

	if (!user || !profile) {
		return null
	}

	return (
		<div className="flex flex-col h-full bg-main-very-light">
			<img src={Logo} alt="Logo" className="p-8" />
			<p className="p-4 text-gray-500 text-sm text-center">
				Benvenuto, {profile.name ?? profile.email}
				<br />
				{profile.idCode && (
					<>
						Codice Professionista: <span className="font-bold text-main">{profile.idCode}</span>
					</>
				)}
			</p>
			<ul className="flex flex-col h-full overflow-y-auto">
				<SidebarItem
					title="Persone"
					icon={<Users className="mr-4" />}
					onClick={handleNavigate}
					href="/patients"
					active={location.pathname.includes('/patients')}
				/>

				{(claims.admin || claims.doctor) && (
					<SidebarItem
						title="Gruppi"
						icon={<Circle className="mr-4" />}
						onClick={handleNavigate}
						href="/groups"
						active={location.pathname.includes('/groups')}
					/>
				)}
				<SidebarItem
					title="Profilo"
					icon={<Settings className="mr-4" />}
					onClick={handleNavigate}
					href="/profile"
					active={location.pathname.includes('/profile')}
				/>
				{claims.admin && (
					<SidebarItem
						title="Utenti"
						icon={<List className="mr-4" />}
						onClick={handleNavigate}
						href="/users"
						active={location.pathname.includes('/users')}
					/>
				)}

				<SidebarItem
					title="Protocolli"
					icon={<BookOpen className="mr-4" />}
					onClick={handleNavigate}
					href="/protocols"
					active={location.pathname.includes('/protocols')}
				/>

				<li className="flex-1"></li>
				<SidebarItem
					title="Esci"
					icon={<LogOut className="mr-4" />}
					onClick={handleLogout}
					className="bg-red-100 text-red-900 justify-center"
				/>
			</ul>
		</div>
	)
}
