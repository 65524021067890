import { Check } from 'react-feather'

import { Patient } from '../../common/app.types'
import { HandleChangeFunction, useFormList } from '@polaritybit/use-form'
import { BreathingForm } from '../tools/breathing-form.component'
import { usePatients } from './hooks/use-patients.hook'
import { PatientList } from './patient-list.component'

export type MassBreathingFormData = {
	_patients: string[]
}

export type MassBreathingFormProps = {
	data: MassBreathingFormData
	onChange: HandleChangeFunction<MassBreathingFormData>
	validationStatus: any
}

export function MassBreathingForm({ data, onChange, validationStatus }: MassBreathingFormProps) {
	const [patients] = usePatients()
	const [selectedPatients, { onAdd, onRemove }] = useFormList([data, onChange], '_patients', (i) => i)

	function handleTogglePatient(p: Patient) {
		if (selectedPatients.includes(p.uid)) {
			return onRemove(p.uid)
		}

		onAdd(p.uid)
	}

	return (
		<div>
			<h3 className="text-lg font-bold mb-2">Definisci la respirazione:</h3>
			<BreathingForm data={data} onChange={onChange} validationStatus={validationStatus} />
			<h3 className="text-lg font-bold mb-2">Seleziona le persone a cui assegnarla:</h3>
			{validationStatus._patients?.error && (
				<p className="text-red-500">È necessario selezionare almeno una persona</p>
			)}
			<PatientList
				patients={patients}
				onPatientClick={handleTogglePatient}
				patientItemIcon={(patient) => (selectedPatients.includes(patient.uid) ? <Check /> : false)}
			/>
		</div>
	)
}
