import { useEffect } from 'react'
import { Measure, Patient } from '../../common/app.types'
import { Input } from '../../common/components/input.component'
import { PatientRealtimeChart } from './patient-realtime-chart'

export type PatientCalibrationFormProps = {
	patient: Patient
	configuration: any
	data: any
	onChange: Function
	validationStatus: any
	realtimeSession?: Measure
}

export function PatientCalibrationForm({
	patient,
	configuration,
	data,
	onChange,
	validationStatus,
	realtimeSession,
}: PatientCalibrationFormProps) {
	useEffect(() => {
		if (configuration) {
			onChange(configuration, true)
		}
	}, [configuration, onChange])

	return (
		<div>
			{realtimeSession && (
				<PatientRealtimeChart
					patient={patient}
					session={realtimeSession}
					label="HRV"
					title="Sessione in corso"
				/>
			)}
			{!realtimeSession && <p className="p-4 mb-4 text-center text-gray-600">Nessuna sessione in corso</p>}
			<div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
				<Input
					label="Kappa Zero"
					name="kappa_zero"
					onChange={onChange}
					value={data?.kappa_zero}
					error={validationStatus.kappa_zero?.error}
					errorText={validationStatus.kappa_zero?.message}
					type="number"
				/>
				<Input
					label="Kappa Uno"
					name="kappa_uno"
					onChange={onChange}
					value={data?.kappa_uno}
					error={validationStatus.kappa_uno?.error}
					errorText={validationStatus.kappa_uno?.message}
					type="number"
				/>
				<Input
					label="Kappa Due"
					name="kappa_due"
					onChange={onChange}
					value={data?.kappa_due}
					error={validationStatus.kappa_due?.error}
					errorText={validationStatus.kappa_due?.message}
					type="number"
				/>
				<Input
					label="Kappa Tre"
					name="kappa_tre"
					onChange={onChange}
					value={data?.kappa_tre}
					error={validationStatus.kappa_tre?.error}
					errorText={validationStatus.kappa_tre?.message}
					type="number"
				/>
				<Input
					label="Kappa Quattro"
					name="kappa_quattro"
					onChange={onChange}
					value={data?.kappa_quattro}
					error={validationStatus.kappa_quattro?.error}
					errorText={validationStatus.kappa_quattro?.message}
					type="number"
				/>
				<Input
					label="Kappa Cinque"
					name="kappa_cinque"
					onChange={onChange}
					value={data?.kappa_cinque}
					error={validationStatus.kappa_cinque?.error}
					errorText={validationStatus.kappa_cinque?.message}
					type="number"
				/>
			</div>
		</div>
	)
}
