import React, { MutableRefObject, ReactNode, useState } from 'react'
import { useEffect } from 'react'
import { useMemo } from 'react'
import { useRef } from 'react'
import { createPortal } from 'react-dom'

export enum TooltipPlacementPosition {
	Top = -1,
	Bottom = 1,
	Middle = 0,
	Left = -1,
	Right = 1,
	Center = 0,
}

export type TooltipPlacement = {
	vertical: TooltipPlacementPosition
	horizontal: TooltipPlacementPosition
}

interface TooltipElementProps {
	render: boolean
	placement: TooltipPlacement
	show: boolean
	content: ReactNode
	rect: DOMRect
}

const TooltipElement = function ({
	render,
	placement,
	show,
	content,
	rect = { top: 0, height: 0, left: 0, width: 0 } as DOMRect,
}: TooltipElementProps) {
	const tooltipRef = useRef() as MutableRefObject<HTMLDivElement>
	const tooltipRect: DOMRect = tooltipRef.current?.getBoundingClientRect() ?? rect

	const style = {
		top:
			rect.top +
			(rect.height / 2 - tooltipRect?.height / 2) +
			(rect.height / 2 + tooltipRect?.height / 2) * placement.vertical +
			2 * placement.vertical,
		left:
			rect.left +
			(rect.width / 2 - tooltipRect?.width / 2) +
			(rect.width / 2 + tooltipRect?.width / 2) * placement.horizontal +
			2 * placement.horizontal,
	}

	if (!render || !content) {
		return null
	}

	return createPortal(
		<div
			ref={tooltipRef}
			className={`transform transition-opacity ease-in duration-150 fixed z-10 bg-black bg-opacity-75 text-white p-2 text-xs pointer-events-none ${
				show ? 'opacity-1' : 'opacity-0'
			}`}
			style={style}
		>
			{content}
		</div>,
		document.body,
	)
}

export const Tooltip = function ({
	content,
	placement = {
		vertical: TooltipPlacementPosition.Top,
		horizontal: TooltipPlacementPosition.Center,
	},
	children,
}) {
	const child = React.Children.only(children)
	const childRef = useRef() as MutableRefObject<HTMLElement>
	const [shouldRenderTooltip, setShouldRenderTooltip] = useState(false)
	const [shouldShowTooltip, setShouldShowTooltip] = useState(false)
	const timeoutRef = useRef() as MutableRefObject<NodeJS.Timeout>

	useEffect(() => {
		if (shouldRenderTooltip) {
			requestAnimationFrame(() => setShouldShowTooltip(true))
		}
	}, [shouldRenderTooltip])

	useEffect(() => {
		if (!shouldShowTooltip) {
			timeoutRef.current = setTimeout(() => {
				setShouldRenderTooltip(false)
			}, 150)
		}
	}, [shouldShowTooltip])

	const childClone = useMemo(() => {
		return React.cloneElement(child, {
			ref: childRef,
			onMouseEnter: () => {
				if (timeoutRef.current) {
					requestAnimationFrame(() => setShouldShowTooltip(true))
					clearTimeout(timeoutRef.current)
				}

				setShouldRenderTooltip(true)
			},
			onMouseLeave: () => setShouldShowTooltip(false),
		})
	}, [child, childRef])

	return (
		<>
			{childClone}
			<TooltipElement
				render={shouldRenderTooltip}
				show={shouldShowTooltip}
				content={content}
				rect={childRef.current?.getBoundingClientRect()}
				placement={placement}
			/>
		</>
	)
}
