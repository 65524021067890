import { ArrowRightCircle } from 'react-feather'

import { Profile } from '../../common/app.types'
import { Checkbox } from '../../common/components/checkbox.component'
import { Input } from '../../common/components/input.component'
import { Table } from '../../common/components/table.component'
import { useFilter } from '../../common/hooks/use-filter.hook'
import { useForm } from '@polaritybit/use-form'
import { UserItem } from './user-item.component'

const UserFilterRules = {
	search: (v, f, i) => {
		return !f || i.name?.toLowerCase().includes(f.toLowerCase()) || i.email?.toLowerCase().includes(f.toLowerCase())
	},
	admin: (v, f, i) => {
		return f === true ? i.claims.admin : true
	},
	doctor: (v, f, i) => {
		return f === true ? i.claims.doctor : true
	},
}

const UserFilterDefaultValue = {
	search: '',
	admin: false,
	doctor: true,
}

type UserFilter = {
	search: string
	admin: boolean
	doctor: boolean
}

type UserListProps = {
	users: Profile[]
	onUserClick?: (user: Profile) => void
}

export function UserList({ users, onUserClick }: UserListProps) {
	const [filter, setFilter] = useForm<UserFilter>(UserFilterDefaultValue)
	const filteredUsers = useFilter(UserFilterRules, filter, users)

	function handleUserClick(item) {
		onUserClick?.(item)
	}

	return (
		<div>
			<div className="mb-2">
				<Input
					label="Cerca tra gli utenti..."
					onChange={setFilter}
					name="search"
					placeholder="Scrivi il nome o l'email dell'utente"
					value={filter.search}
					inputProps={{ autoComplete: 'off' }}
				/>
			</div>
			<div className="grid lg:grid-cols-3 grid-cols-1 mb-8">
				<Checkbox label="Amministratori" name="admin" checked={filter.admin} onChange={setFilter} />
				<Checkbox
					label="Professionisti (Abbonati)"
					name="doctor"
					checked={filter.doctor}
					onChange={setFilter}
				/>
			</div>

			{filteredUsers.length === 0 && <p className="text-center my-4 text-gray-600">Nessun utente trovato.</p>}

			<Table
				getItemKey={(p) => p.uid}
				items={filteredUsers}
				onRowClick={handleUserClick}
				columns={[
					{
						key: 'mobile-view',
						title: '',
						cellClassName: 'block',
						headerClassName: 'hidden',
						getValue(item) {
							return <UserItem user={item} icon={<ArrowRightCircle />} />
						},
					},
				]}
			/>
		</div>
	)
}
