import { useState, useEffect } from 'react'
import { useStableCallbackReference } from './use-stable-callback-reference.hook'

const noop = () => {}
export function useDelayedRender(open, animationDuration, onClosed = noop) {
	const [shouldRender, setShouldRender] = useState(false)
	const [isVisible, setIsVisible] = useState(false)

	const stableOnClosed = useStableCallbackReference(onClosed)

	useEffect(() => {
		function delayedClose() {
			setIsVisible(false)
			setTimeout(() => {
				setShouldRender(false)
				if (stableOnClosed) {
					stableOnClosed()
				}
			}, animationDuration)
		}

		function delayedOpen() {
			setShouldRender(true)
			setIsVisible(false)
			setTimeout(() => setIsVisible(true), 34)
		}

		if (open === false) {
			delayedClose()
		} else {
			delayedOpen()
		}
	}, [open, animationDuration, stableOnClosed])

	return [shouldRender, isVisible]
}
