import { useMemo } from 'react'
import { Input } from '../../common/components/input.component'
import { useForm } from '@polaritybit/use-form'
import { GroupChart } from './group-chart.component'
import { uniqBy, prop, sort, ascend, flatten } from 'ramda'
import { Button } from '../../common/components/button.component'
import { Download } from 'react-feather'
import { format } from 'date-fns'
import { unparse } from 'papaparse'
import { Group, Patient } from '../../common/app.types'
import saveAs from 'file-saver'

export function GroupPageCharts({ patients, group }: { patients: Patient[]; group: Group }) {
	const [filter, setFilter] = useForm({ title: '0', type: '0' })
	// const [showAllTags, setShowAllTags] = useState(false)

	// useEffect(() => {
	// 	setFilter({ title: '0', type: '0' })
	// }, [showAllTags])

	const validPatients = useMemo(() => {
		if (!patients) {
			return []
		}

		return patients.filter((p) => p.measures?.length > 0)
	}, [patients])

	const allTags = useMemo(() => {
		return uniqBy(prop('title'), validPatients.map((p) => p.tags).flat())
	}, [validPatients])

	function handleExportData() {
		const sortByDateAscending = sort(ascend(prop('date')))

		const data = sortByDateAscending(
			flatten(
				validPatients.map((patient: Patient) =>
					patient.measures
						.filter((m) => {
							if (filter.title === '0') {
								return true
							}

							return m.title === filter.title
						})
						.filter((m) => {
							if (filter.type === '0') {
								return true
							}

							return m.type === filter.type
						})
						.map((m) => {
							return {
								Data: format(new Date(+m.uid), 'dd/MM/yyyy hh:mm'),
								Persona: patient.uid.substring(-8),
								HRV: m.hrv,
								RR: m.varRR,
								RMSSD: m.sigmaRRDiff,
								Sigma: m.sigmaScore,
							}
						}),
				),
			),
		)

		const result = unparse(data)
		saveAs(new Blob([result]), 'export.csv')
	}

	if (validPatients.length === 0) {
		return (
			<div className="p-4 text-center">
				Non sono al momento presenti registrazioni per nessuno dei utentiti appartenenti al gruppo.
			</div>
		)
	}

	return (
		<div>
			<div className="grid grid-cols-1 md:grid-cols-6">
				<div className="mt-6 mb-4 lg:mb-0 px-2 w-full">
					<Button variant="info" className="w-full" onClick={handleExportData}>
						<Download size={18} className="mr-2" /> Esporta
					</Button>
				</div>
				<div className="grid md:col-start-5 col-span-2 grid-cols-2">
					<Input label="Tag" name="title" onChange={setFilter} select value={filter.title}>
						<option value={'0'}>Tutti</option>
						{allTags?.map((tag) => {
							return (
								<option key={tag.uid} value={tag.title}>
									{tag.title}
								</option>
							)
						})}
					</Input>
					<Input label="Tipo" name="type" onChange={setFilter} select value={filter.type}>
						<option value="0">Tutti</option>
						<option value="open">Libera</option>
						<option value="training">Registrazione</option>
						<option value="breathing">Respirazione</option>
					</Input>
				</div>
			</div>

			<div className="m-4">
				<GroupChart title="HRV" patients={validPatients} getValue={(x) => +x.hrv} label="HRV" filter={filter} />
			</div>
			<div className="m-4">
				<GroupChart
					title="Punteggio STD"
					patients={validPatients}
					getValue={(x) => +x.sigmaScore}
					label="Sigma Score"
					filter={filter}
				/>
			</div>
			<div className="m-4">
				<GroupChart
					title="RMSSD"
					patients={validPatients}
					getValue={(x) => +x.sigmaRRDiff}
					label="RMSSD"
					filter={filter}
				/>
			</div>
		</div>
	)
}
