import { Link } from 'react-router-dom'

export type Breadcrumb = {
	title: string
	link: string
}

export type BreadcrumbsProps = {
	items: Breadcrumb[]
}

export function Breadcrumbs({ items = [] }: BreadcrumbsProps) {
	if (!items) {
		return null
	}

	return (
		<div className="p-4 w-full flex items-center bg-white border-b border-gray-300">
			{items.map((item, index) => {
				if (index === items.length - 1) {
					return (
						<div key={item.title + index} className="font-bold">
							{item.title}
						</div>
					)
				}

				return (
					<div key={item.title + index}>
						<Link className="link" to={item.link}>
							{item.title}
						</Link>
						<span className="mx-4">/</span>
					</div>
				)
			})}
		</div>
	)
}
