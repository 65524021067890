import firebase from 'firebase'
import { useEffect, useRef, useState } from 'react'
import { Folder, Plus, Trash, Upload } from 'react-feather'
import toast from 'react-hot-toast'

import { Button } from '../../common/components/button.component'
import { Input } from '../../common/components/input.component'
import { sortBySeqNumber } from './protocol.util'

function UploadButton({ onUpload }) {
	const uploadRef = useRef<HTMLInputElement>()
	const [loading, setLoading] = useState(false)

	function handleUploadButtonClick() {
		uploadRef.current?.click()
	}

	async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
		if (event.currentTarget.files.length > 0) {
			setLoading(true)
			try {
				await onUpload(event.currentTarget.files.item(0))
			} catch {
			} finally {
				handleClearFile()
				setLoading(false)
			}
		}
	}

	function handleClearFile() {
		uploadRef.current.value = ''
	}

	return (
		<>
			<input
				ref={uploadRef}
				type="file"
				onChange={handleFileChange}
				style={{ position: 'absolute', left: -999999 }}
			/>

			<Button loading={loading} variant="primary" onClick={handleUploadButtonClick}>
				<Upload size={18} className="mr-2" /> Carica File
			</Button>
		</>
	)
}

function OpenStorageLink({ storageId, children }) {
	const [link, setLink] = useState()

	useEffect(() => {
		async function getStoragePath() {
			const url = await firebase.app().storage().ref(storageId).getDownloadURL()
			setLink(url)
		}

		getStoragePath()
	}, [storageId])

	if (!link) {
		return null
	}

	return (
		<a
			href={link}
			target="_blank"
			rel="noopener noreferrer nofollow"
			className="ml-2 link cursor-pointer  flex items-center"
		>
			{children}
		</a>
	)
}

export function ProtocolFormAttachmentTab({
	attachments,
	onCreateAttachment,
	onRemoveAttachment,
	onEditAttachment,
	onBlur,
}) {
	async function handleUploadForAttachment(attachment, file: File) {
		const extension = file.name.replace(/(.*)\./g, '')
		const filename = `${(Math.random() * 10e16).toString(16)}.${extension}`
		const storage_id = `therapies/${attachment.uid}/${filename}`

		try {
			await firebase.app().storage().ref().child(`therapies/${attachment.uid}/${filename}`).put(file)

			toast.success('File caricato correttamente')
		} catch (error) {
			console.log('Upload failed', error)
		}

		onEditAttachment(attachment, { storage_id, title: file.name })
	}

	if (!attachments) {
		return null
	}

	return (
		<div className="bg-white">
			{attachments?.length === 0 && (
				<p className="p-4 text-center text-gray-500">Non sono presenti allegati in questo passaggio</p>
			)}

			{sortBySeqNumber(attachments).map((attachment) => (
				<div key={attachment.uid} className="mb-4 p-4 border-t border-gray-300">
					<div className="flex justify-between items-center">
						<span className="uppercase">Contenuto Allegato:</span>
						<Button variant="danger" size="md" onClick={() => onRemoveAttachment(attachment)}>
							<Trash size={14} />
						</Button>
					</div>
					<Input
						value={attachment.title}
						disableAutoComplete
						label="Nome Allegato"
						inputProps={{
							onBlur,
						}}
						onChange={(e) =>
							onEditAttachment(attachment, {
								title: e.currentTarget.value,
							})
						}
					/>
					<div className="flex items-center">
						<div className="mr-2">
							<UploadButton onUpload={(file) => handleUploadForAttachment(attachment, file)} />
						</div>
						{attachment.storage_id && (
							<div className="flex items-center flex-1">
								<OpenStorageLink storageId={attachment.storage_id}>
									<Folder size={18} className="mr-2" /> Apri
								</OpenStorageLink>
							</div>
						)}
					</div>
				</div>
			))}
			<div className="flex justify-end mb-2 p-4">
				<Button onClick={onCreateAttachment} variant="success" className="w-full lg:w-auto">
					<Plus size={18} className="mr-2" /> Aggiungi Nuovo Allegato
				</Button>
			</div>
		</div>
	)
}
