import { useEffect, useState } from 'react'
import { Circle, Plus } from 'react-feather'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router'

import { server } from '../../../core/server.core'
import { Group } from '../../common/app.types'
import { Box } from '../../common/components/box.component'
import { Breadcrumbs } from '../../common/components/breadcrumbs.component'
import { Button } from '../../common/components/button.component'
import { Loading } from '../../common/components/loading.component'
import { PopForm } from '../../common/components/pop-form.component'

import { useClaims } from '../auth/hooks/use-claims.hook'
import { GroupForm } from './group-form.component'
import { GroupList } from './group-list.component'
import { GroupErrorMessages } from './group.types'
import { useGroups } from './hooks/use-groups.hook'
import { GroupValidationRules } from './validation-rules/group.validation-rules'

export function useCount() {
	const [count, setCount] = useState(0)

	useEffect(() => {
		setCount((c) => c + 1)
	}, [])

	return count
}

export function Groups() {
	const [groups, fetchGroups, groupsLoadingStatus] = useGroups()
	const navigate = useNavigate()
	const claims = useClaims()

	function handleGroupClick(item) {
		navigate(`/groups/${item.uid}`)
	}

	async function handleSaveGroup(data) {
		try {
			await server().post('/groups', data)
			await fetchGroups()

			toast.success('Gruppo creato correttamente!')
		} catch (error: any) {
			toast.error(`Si è verificato un errore: ${GroupErrorMessages[error]}`)

			return Promise.reject()
		}
	}

	if (groupsLoadingStatus.loading) {
		return <Loading />
	}

	return (
		<div>
			<Breadcrumbs items={[{ title: 'Gruppi', link: '/groups' }]} />
			<Box
				title="I Miei Gruppi"
				icon={<Circle size={22} />}
				actions={
					(claims.doctor || claims.admin) && (
						<PopForm
							title="Nuovo Gruppo"
							form={(data: Group, onChange, validationStatus) => (
								<GroupForm data={data} onChange={onChange} validationStatus={validationStatus} />
							)}
							confirmLabel="Salva"
							cancelLabel="Annulla"
							icon={<Circle size={18} className="mr-2" />}
							onConfirm={handleSaveGroup}
							validationRules={GroupValidationRules}
						>
							<Button variant="success" className="w-full lg:w-auto">
								<Plus className="mr-2" size={18} /> Crea Nuovo Gruppo
							</Button>
						</PopForm>
					)
				}
			>
				<div className="w-full text-right mb-2"></div>

				<GroupList groups={groups} onGroupClick={handleGroupClick} />
			</Box>
		</div>
	)
}
