import { Book, Copy, Save, Share } from 'react-feather'
import { useNavigate, useParams } from 'react-router'
import { Therapy } from '../../common/app.types'
import { Box } from '../../common/components/box.component'
import { Breadcrumbs } from '../../common/components/breadcrumbs.component'
import { Loading } from '../../common/components/loading.component'
import { useForm } from '@polaritybit/use-form'
import { ProtocolForm } from '../groups/protocol-form.component'
import { useProtocol } from './hooks/use-protocol.hook'
import { useEffect, useState } from 'react'
import { Button } from '../../common/components/button.component'
import { Input } from '../../common/components/input.component'
import { PopForm } from '../../common/components/pop-form.component'
import { server } from '../../../core/server.core'
import toast from 'react-hot-toast'

export const ShareProtocolValidationRules = {
	email: (v) => v && v.trim().length > 0,
}

export function ShareProtocolForm({ data, onChange, validationStatus }) {
	useEffect(() => {
		if (!data) {
			onChange({ email: '' }, true)
		}
	}, [data, onChange])

	if (!data) {
		return null
	}

	return (
		<div>
			<p className="mb-2">
				Per condividere il protocollo con un altro utente, inserisci il suo indirizzo email qui sotto. Se
				l'utente è registrato a WareHRV come professionista, il protocollo verrà duplicato e reso disponibile
				sulla sua lista di protocolli.
			</p>
			<Input
				name="email"
				value={data.email}
				onChange={onChange}
				label="Indirizzo Email Utente"
				error={validationStatus.email?.error}
				errorText={validationStatus.email?.message}
			/>
		</div>
	)
}

export function Protocol() {
	const params = useParams<{ id: string }>()
	const navigate = useNavigate()
	const [protocol, refresh, protocolLoadingStatus] = useProtocol(params.id)
	const [data, onChange] = useForm<Therapy>({})
	const [saving, setSaving] = useState(false)

	useEffect(() => {
		if (protocol) {
			onChange(protocol, true)
		}
	}, [protocol, onChange])

	async function handleSaveProtocol(data, { implicit = false } = {}) {
		setSaving(true)
		try {
			await server().post('/therapies/', data)
			await refresh()

			if (!implicit) {
				toast.success('Protocollo salvato con successo!')
			}
		} catch (error) {
			console.log(error)
			toast.error('Errore durante il salvataggio del protocollo')
		} finally {
			setSaving(false)
		}
	}

	async function handleCopyProtocol() {
		try {
			const response = await server().post('/therapies/clone/', data)

			toast.success('Protocollo duplicato con successo!')

			navigate(`/protocols/${response.data.uid}`)
		} catch (error) {
			console.log(error)
			toast.error('Errore durante la duplicazione del protocollo')
		}
	}

	async function handleShareProtocol(shareData) {
		try {
			await server().post('/therapies/share/', {
				email: shareData.email,
				therapyId: data.uid,
			})

			toast.success('Protocollo condiviso con successo!')
		} catch (error) {
			console.log(error)
			toast.error('Errore durante la condivisione del protocollo')
		}
	}

	if (protocolLoadingStatus.loading) {
		return <Loading />
	}

	return (
		<div>
			<Breadcrumbs
				items={[
					{ title: 'Protocolli', link: '/protocols' },
					{ title: 'Modifica Protocollo', link: `` },
				]}
			/>
			<Box
				title={
					<input
						value={data.name}
						name="name"
						onChange={onChange}
						className="w-full border-b border-white focus:outline-none focus:border-gray-300"
						onBlur={() => handleSaveProtocol(data, { implicit: true })}
					/>
				}
				icon={<Book size={22} />}
				actions={
					<div>
						<PopForm
							title="Condividi Protocollo"
							icon={<Share size={22} className="mr-2" />}
							form={(data, onChange, validationStatus) => (
								<ShareProtocolForm
									data={data}
									onChange={onChange}
									validationStatus={validationStatus}
								/>
							)}
							validationRules={ShareProtocolValidationRules}
							onConfirm={handleShareProtocol}
						>
							<Button variant="info">
								<Share className="inline-block mr-2" size={16} /> Condividi Protocollo
							</Button>
						</PopForm>
						<PopForm
							title="Duplica Protocollo"
							icon={<Copy size={22} className="mr-2" />}
							form={(data, onChange, validationStatus) => (
								<div>Vuoi veramente duplicare il protocollo?</div>
							)}
							onConfirm={handleCopyProtocol}
						>
							<Button variant="primary">
								<Copy className="inline-block mr-2" size={16} /> Duplica Protocollo
							</Button>
						</PopForm>
						<Button loading={saving} onClick={() => handleSaveProtocol(data)} variant="success">
							<Save className="inline-block mr-2" size={16} /> Salva Modifiche
						</Button>
					</div>
				}
			>
				<ProtocolForm therapy={protocol} data={data} onChange={onChange} onSave={handleSaveProtocol} />
			</Box>
		</div>
	)
}
