import { useMemo } from 'react'
import { Group } from '../../common/app.types'

export type GroupPageStatisticsProps = {
	group: Group
}

function StatBox({ title, subtitle = '', value }) {
	return (
		<div className="flex flex-col items-center justify-center p-4 mb-2 bg-white shadow">
			<div className="text-md text-gray-700">{subtitle}</div>
			<div className="font-bold text-xl">{value}</div>
			<div className="text-sm text-gray-500">{title}</div>
		</div>
	)
}

export function GroupPageStatistics({ group }: GroupPageStatisticsProps) {
	const maxPatient = useMemo(() => {
		if (!group.stats) {
			return null
		}

		let maxPatient = {
			count: 0,
			id: '',
		}

		for (const key in group.stats.patientMeasureCount) {
			if (group.stats.patientMeasureCount[key] > maxPatient.count) {
				maxPatient = {
					count: group.stats.patientMeasureCount[key],
					id: key,
				}
			}
		}

		const patient = group.patients.find((p) => p.uid === maxPatient.id)
		if (!patient) {
			return null
		}

		return {
			...maxPatient,
			patient,
		}
	}, [group.stats, group.patients])

	const maxTag = useMemo(() => {
		if (!group.stats) {
			return null
		}

		let maxTag = {
			count: 0,
			id: '',
		}

		for (const key in group.stats.tagCount) {
			if (group.stats.tagCount[key] > maxTag.count) {
				maxTag = {
					count: group.stats.tagCount[key],
					id: key,
				}
			}
		}

		const tag = group.tags.find((tag) => tag.uid === maxTag.id)
		if (!tag) {
			return null
		}

		return {
			...maxTag,
			tag,
		}
	}, [group.stats, group.tags])

	const maxAudio = useMemo(() => {
		if (!group.stats) {
			return null
		}

		let maxAudio = {
			count: 0,
			title: '',
		}

		for (const key in group.stats.audioCount) {
			if (group.stats.audioCount[key] > maxAudio.count) {
				maxAudio = {
					count: group.stats.audioCount[key],
					title: key,
				}
			}
		}

		if (maxAudio.count === 0) {
			return null
		}

		return maxAudio
	}, [group.stats])

	return (
		<div>
			{!group.stats ||
				(!maxTag && !maxPatient && !maxAudio && (
					<div className="p-4 text-center">Statistiche non disponibili per questo gruppo</div>
				))}
			{group.stats && (
				<div className="p-4">
					<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
						{maxPatient && (
							<StatBox
								title="Persona più attiva"
								value={`${maxPatient.count} registrazioni`}
								subtitle={maxPatient.patient.name}
							/>
						)}
						{maxTag && (
							<StatBox
								title="Tag più utilizzato"
								value={`${maxTag.count} volte`}
								subtitle={maxTag.tag?.title}
							/>
						)}
						{maxAudio && (
							<StatBox
								title="Audio più riprodotto"
								value={`${maxAudio.count} volte`}
								subtitle={maxAudio.title}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	)
}
