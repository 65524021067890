export function ProfilePageHelp() {
	return (
		<div>
			<h4 className="uppercase pb-4 border-b-2 border-main-dark mb-4 font-semibold">
				Pagina Profilo
			</h4>
			<div className="text-gray-700">
				<p className="pb-2">
					Da questa pagina puoi visualizzare le informazioni relative
					al tuo profilo, e{' '}
					<strong>
						modificare il tuo nome e le tue preferenze per la
						privacy
					</strong>
					. Dopo aver effettuato le modifiche desiderate, clicca sul
					pulsante{' '}
					<strong className="bold text-main uppercase">
						'Aggiorna Profilo'
					</strong>{' '}
					per salvarle.
				</p>
				<p className="pb-2">
					Puoi anche consultare{' '}
					<strong>la nostra privacy policy</strong> e{' '}
					<strong>i termini e condizioni d'uso del serivizo.</strong>
				</p>
			</div>
		</div>
	)
}
