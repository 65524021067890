import firebase from 'firebase'
import { useEffect, useRef, useState } from 'react'
import { Play, Plus, Trash, Upload } from 'react-feather'
import toast from 'react-hot-toast'

import { Button } from '../../common/components/button.component'
import { Input } from '../../common/components/input.component'
import { sortBySeqNumber } from './protocol.util'

function UploadButton({ onUpload }) {
	const uploadRef = useRef<HTMLInputElement>()
	const [loading, setLoading] = useState(false)

	function handleUploadButtonClick() {
		uploadRef.current?.click()
	}

	async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
		if (event.currentTarget.files.length > 0) {
			setLoading(true)
			try {
				await onUpload(event.currentTarget.files.item(0))
			} catch {
			} finally {
				handleClearFile()
				setLoading(false)
			}
		}
	}

	function handleClearFile() {
		uploadRef.current.value = ''
	}

	return (
		<>
			<input
				ref={uploadRef}
				type="file"
				onChange={handleFileChange}
				style={{ position: 'absolute', left: -999999 }}
			/>

			<Button loading={loading} variant="primary" onClick={handleUploadButtonClick}>
				<Upload size={18} className="mr-2" /> Carica File
			</Button>
		</>
	)
}

function OpenStorageLink({ storageId, children }) {
	const [link, setLink] = useState()

	useEffect(() => {
		async function getStoragePath() {
			const url = await firebase.app().storage().ref(storageId).getDownloadURL()
			setLink(url)
		}

		getStoragePath()
	}, [storageId])

	if (!link) {
		return null
	}

	return (
		<a
			href={link}
			target="_blank"
			rel="noopener noreferrer nofollow"
			className="ml-2 link cursor-pointer  flex items-center"
		>
			{children}
		</a>
	)
}

export function ProtocolFormAudioTab({ audios, onCreateAudio, onRemoveAudio, onEditAudio, onBlur }) {
	async function handleUploadForAudio(audio, file: File) {
		const filename = (Math.random() * 10e16).toString(16)
		const storage_id = `therapies/${audio.uid}/${filename}`

		try {
			await firebase.app().storage().ref().child(`therapies/${audio.uid}/${filename}`).put(file)

			toast.success('File caricato correttamente')
		} catch (error) {
			console.log('Upload failed', error)
		}

		onEditAudio(audio, { storage_id, title: file.name })
	}

	if (!audios) {
		return null
	}

	return (
		<div className="bg-white">
			{audios?.length === 0 && (
				<p className="p-4 text-center text-gray-500">Non sono presenti audio in questo passaggio</p>
			)}

			{sortBySeqNumber(audios).map((audio) => (
				<div key={audio.uid} className="mb-4 p-4 border-t border-gray-300">
					<div className="flex justify-between items-center">
						<span className="uppercase">Contenuto Video:</span>
						<Button variant="danger" size="md" onClick={() => onRemoveAudio(audio)}>
							<Trash size={14} />
						</Button>
					</div>
					<Input
						value={audio.title}
						disableAutoComplete
						label="Nome Audio"
						inputProps={{
							onBlur,
						}}
						onChange={(e) => onEditAudio(audio, { title: e.currentTarget.value })}
					/>
					<div className="flex items-center">
						<div className="mr-2">
							<UploadButton onUpload={(file) => handleUploadForAudio(audio, file)} />
						</div>
						{audio.storage_id && (
							<div className="flex items-center flex-1">
								<OpenStorageLink storageId={audio.storage_id}>
									<Play size={18} className="mr-2" /> Riproduci
								</OpenStorageLink>
							</div>
						)}
					</div>
				</div>
			))}
			<div className="flex justify-end mb-2 p-4">
				<Button onClick={onCreateAudio} variant="success" className="w-full lg:w-auto">
					<Plus size={18} className="mr-2" /> Aggiungi Nuovo Audio
				</Button>
			</div>
		</div>
	)
}
