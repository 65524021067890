import React, { useMemo } from 'react'
import { createPortal } from 'react-dom'

import { useHotkey } from '../hooks/use-hotkey.hook'
import { useDelayedRender } from '../hooks/use-delayed-render.hook'

export enum ModalAnimationDuration {
	MS75 = 75,
	MS100 = 100,
	MS150 = 150,
	MS200 = 200,
	MS300 = 300,
	MS500 = 500,
	MS700 = 700,
	MS1000 = 1000,
}

export enum ModalFooterAlign {
	Left,
	Center,
	Right,
}

export type ModalProps = {
	open: boolean
	onClose: () => void
	onClosed?: () => void
	animationDuration?: ModalAnimationDuration
	closeOnEscape?: boolean
	closeOnBackdropClick?: boolean
	children: React.ReactNode
}

export type ModalFooterProps = {
	children: React.ReactNode
	additionalChildren?: React.ReactNode
	align?: ModalFooterAlign
}

export const Modal = function ({
	open,
	onClose,
	onClosed = () => {},
	animationDuration = ModalAnimationDuration.MS500,
	closeOnEscape = true,
	closeOnBackdropClick = true,
	children,
}: ModalProps) {
	useHotkey(closeOnEscape && open ? 'escape' : false, (event) => {
		event.preventDefault()
		onClose()
	})

	const [shouldRender, isVisible] = useDelayedRender(open, animationDuration, onClosed)

	const openClassName = `translate-y-0 opacity-100`
	const closedClassName = `translate-y-full sm:-translate-y-full opacity-0`
	const positionClassName = `my-0 sm:my-10`
	const sizeClassName = `w-full sm:w-2/3 lg:w-1/2 min-h-screen sm:min-h-0`
	const borderClassName = ``

	if (!shouldRender) {
		return null
	}

	return createPortal(
		<>
			<div
				onClick={(e) => {
					if (!closeOnBackdropClick) {
						return
					}

					if (e.target !== e.currentTarget) {
						return
					}

					if (onClose) {
						onClose()
					}
				}}
				className={`transform duration-${animationDuration} fixed z-10 top-0 left-0 h-screen bg-black overflow-y-scroll ${
					isVisible ? 'bg-opacity-25' : 'bg-opacity-0'
				}`}
			>
				<div className={`flex top-0 justify-center h-auto w-screen pointer-events-none`}>
					<div
						className={`bg-white pointer-events-auto shadow transform duration-${animationDuration} ${borderClassName} ${positionClassName} ${sizeClassName} ${
							isVisible ? openClassName : closedClassName
						}`}
					>
						<div className="h-full flex flex-col">{children}</div>
					</div>
				</div>
			</div>
		</>,
		document.body,
	)
}

Modal.Title = function Title({ children }) {
	return <div className="p-4 text-2xl font-bold flex items-center">{children}</div>
}

Modal.Content = function Content({ children }) {
	return <div className="flex-1 px-4 pt-0 pb-8">{children}</div>
}

Modal.Footer = function Footer({ children, additionalChildren, align = ModalFooterAlign.Right }: ModalFooterProps) {
	const alignClassName = useMemo(() => {
		switch (align) {
			case ModalFooterAlign.Left: {
				return `justify-start`
			}
			case ModalFooterAlign.Center: {
				return `justify-center`
			}
			case ModalFooterAlign.Right:
			default: {
				return `justify-end`
			}
		}
	}, [align])

	return (
		<div className={`p-4 border-t border-gray-200 flex items-center ${alignClassName}`}>
			<div className="w-full">{additionalChildren}</div>
			{children}
		</div>
	)
}
