import Joi from 'joi'
import { useEffect, useState } from 'react'
import { Save, Settings, ThumbsUp } from 'react-feather'
import toast from 'react-hot-toast'

import { server } from '../../../core/server.core'
import { Profile } from '../../common/app.types'
import { Box } from '../../common/components/box.component'
import { Breadcrumbs } from '../../common/components/breadcrumbs.component'
import { Button } from '../../common/components/button.component'
import { Checkbox } from '../../common/components/checkbox.component'
import { Input } from '../../common/components/input.component'
import { Spinner } from '../../common/components/spinner.component'
import { useForm } from '@polaritybit/use-form'
import { useValidation } from '../../common/hooks/use-validation.hook'
import { useClaims } from './hooks/use-claims.hook'
import { useProfile } from './hooks/use-profile.hook'

const ProfileValidationRules = {
	name: [
		{
			rule: (v) => v && v.length >= 3,
			message: 'Il nome deve essere lungo almeno 3 caratteri',
		},
	],
	email: [
		{
			rule: function (v) {
				try {
					Joi.assert(v, Joi.string().email({ tlds: { allow: false } }))

					return true
				} catch (error) {
					return false
				}
			},
			message: 'Email non valida',
		},
	],
	acceptTerms: (v) => v === true,
	acceptPrivacy: (v) => v === true,
	age: (v) => +v >= 0,
	gender: (v) => v && v !== null && typeof v !== 'undefined' && ['male', 'female', 'nonbinary'].includes(v),
}

export function ProfilePage() {
	const [profile, profileLoadingStatus] = useProfile()
	const claims = useClaims()
	const [data, onChange] = useForm<Profile>()
	const [loading, setLoading] = useState(false)
	const [validationStatus, { validate, resetValidationStatus }] = useValidation(ProfileValidationRules)

	useEffect(() => {
		if (profile) {
			onChange(profile, true)
		}
	}, [profile, onChange])

	useEffect(() => {
		resetValidationStatus()
	}, [data, resetValidationStatus])

	async function handleUpdateProfile() {
		if (!validate(data)) {
			return
		}

		setLoading(true)

		try {
			await server().post('/me', data)

			toast.success('Profilo aggiornato correttamente!')
		} catch (error) {
			console.log(error)
			toast.error(`Si è verificato un errore durante l'aggiornamento del profilo: ${error}`)
		} finally {
			setLoading(false)
		}
	}

	if (profileLoadingStatus.loading) {
		return (
			<div className="w-full h-64 flex justify-center items-center">
				<Spinner margin="" color="text-black" />
			</div>
		)
	}

	return (
		<div>
			<Breadcrumbs items={[{ title: 'Profilo', link: '/profile' }]} />
			<Box
				title="Il Mio Profilo"
				icon={<Settings size={22} />}
				actions={
					<Button
						loading={loading}
						onClick={handleUpdateProfile}
						variant="success"
						className="w-full lg:w-auto"
					>
						<Save size={18} className="mr-2" /> Aggiorna Profilo
					</Button>
				}
			>
				<div>
					Tipo di utenza:{' '}
					<strong>
						{claims.admin
							? 'Amministratore'
							: claims.doctor
							? 'Professionista (Abbonato)'
							: claims.guest
							? 'Ospite'
							: 'Persona'}
					</strong>
					{!claims.admin && !claims.doctor && (
						<div className="p-4 my-4 border border-green-600 bg-green-200 flex items-center">
							<ThumbsUp className="lg:inline-block mr-2 hidden" />
							<div>
								<a href="" target="_blank" rel="nofollow noreferrer" className="link font-bold">
									Abbonati ora
								</a>{' '}
								a MindToMove per aver accesso a tutte le funzionalità del pannello del professionista!
							</div>
						</div>
					)}
				</div>
				<div className={`grid grid-cols-1 ${profile?.idCode ? 'lg:grid-cols-3' : 'lg:grid-cols-2'} gap-2`}>
					<Input
						name="name"
						onChange={onChange}
						value={data.name}
						label="Pseudonimo"
						disableAutoComplete
						error={validationStatus.name?.error}
						errorText={validationStatus.name?.message}
					/>
					<Input
						disabled
						name="email"
						onChange={onChange}
						value={data.email}
						label="Indirizzo Email"
						disableAutoComplete
						error={validationStatus.email?.error}
						errorText={validationStatus.email?.message}
					/>
					{profile?.idCode && (
						<Input
							disabled
							name="idCode"
							onChange={onChange}
							value={data.idCode}
							label="Codice Professionista"
							disableAutoComplete
							error={validationStatus.idCode?.error}
							errorText={validationStatus.idCode?.message}
						/>
					)}
				</div>
				<div className="grid grid-cols-1 lg:grid-cols-4 gap-2">
					<Input
						name="age"
						onChange={onChange}
						value={data.age}
						label="Età"
						type="number"
						error={validationStatus.age?.error}
						errorText={validationStatus.age?.message}
					/>
					<Input
						name="gender"
						onChange={onChange}
						value={data.gender}
						label="Sesso"
						select
						error={validationStatus.gender?.error}
						errorText={validationStatus.gender?.message}
					>
						<option value="">Seleziona...</option>
						<option value="female">Femmina</option>
						<option value="male">Maschio</option>
						<option value="nonbinary">Non Binario</option>
					</Input>
				</div>
				<div>
					<Checkbox
						className="my-2"
						name="acceptTerms"
						disabled={profile.acceptTerms}
						onChange={onChange}
						checked={data.acceptTerms}
						error={validationStatus.acceptTerms?.error}
						errorText={validationStatus.acceptTerms?.message}
						label={
							<>
								Ho letto e accetto i{' '}
								<a href="/terms" className="link" target="_blank" rel="noopener nofollow noreferrer">
									termini e le condizioni d'uso del servizio
								</a>
							</>
						}
					/>
					<Checkbox
						className="my-2"
						name="acceptPrivacy"
						disabled={profile.acceptPrivacy}
						onChange={onChange}
						checked={data.acceptPrivacy}
						error={validationStatus.acceptPrivacy?.error}
						errorText={validationStatus.acceptPrivacy?.message}
						label={
							<>
								Ho preso visione della{' '}
								<a href="/privacy" className="link" target="_blank" rel="noopener nofollow noreferrer">
									privacy policy
								</a>
							</>
						}
					/>
					<Checkbox
						name="acceptMarketing"
						onChange={onChange}
						checked={data.acceptMarketing}
						className="my-2"
						label={<>Desidero essere contattato per finalità di marketing</>}
					/>
				</div>
			</Box>
		</div>
	)
}
