import { format } from 'date-fns'
import numeral from 'numeral'
import { useMemo } from 'react'
import { CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis } from 'recharts'

import { Measure, Patient } from '../../common/app.types'
import { useStableCallbackReference } from '../../common/hooks/use-stable-callback-reference.hook'

function getRandomColor(value) {
	const firstAlphabet = value.charAt(0).toLowerCase()
	const asciiCode = firstAlphabet.charCodeAt(0)

	const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString()

	var num = Math.round(0xffffff * parseInt(colorNum))
	var r = (num >> 16) & 255
	var g = (num >> 8) & 255
	var b = num & 255

	return 'rgb(' + r + ', ' + g + ', ' + b + ', 1)'
}

type GroupChartProps = {
	title: string
	patients: Patient[]
	getValue: (v: Measure) => number
	label: string
	filter?: { type: any; title: any }
}

type Score = {
	time: number
	[key: string]: number
}

export function GroupChart({ patients, title, getValue, label, filter }: GroupChartProps) {
	const stableGetValue = useStableCallbackReference(getValue)

	const scores = useMemo(() => {
		if (!patients) {
			return null
		}

		const scores = patients.reduce<Score[]>((r: Score[], n: Patient) => {
			if (!n.measures) {
				return r
			}

			for (const measure of n.measures) {
				if (filter?.title && filter.title !== '0' && measure.title !== filter.title) {
					continue
				}

				if (filter?.type && filter.type !== '0' && measure.type !== filter.type) {
					continue
				}

				let score = r.find((t) => t.time === +measure.uid)
				if (!score) {
					score = { time: +measure.uid }
					r.push(score)
				}

				score[n.uid] = stableGetValue(measure)

				if (isNaN(score[n.uid])) {
					score[n.uid] = 0
				}
			}

			return r
		}, [])

		return scores
	}, [patients, filter, stableGetValue])

	const scoredPatients = useMemo(() => {
		return patients.filter((p) => {
			return scores
				.map((s) => Object.keys(s))
				.flat()
				.includes(p.uid)
		})
	}, [scores, patients])

	if (!patients || patients.length === 0) {
		return null
	}

	if (scores.length === 0) {
		return (
			<div className="bg-white py-4">
				<p className="text-center font-bold mb-2 text-main text-xl">{title}</p>

				<div className="text-center">
					<h3>Nessun dato disponibile</h3>
				</div>
			</div>
		)
	}

	return (
		<div className="bg-white py-4">
			<p className="text-center font-bold mb-2 text-main text-xl">{title}</p>
			<ResponsiveContainer height={390} width="100%">
				<ComposedChart data={scores}>
					<CartesianGrid />
					<XAxis
						dataKey="time"
						scale="time"
						type="number"
						domain={['dataMin - 100000000', 'dataMax + 100000000']}
						tickFormatter={(t) => format(new Date(t), 'dd/MM/yyyy')}
						style={{ fontSize: 12 }}
						height={16}
					/>
					{/* <YAxis /> */}
					{/* <ReferenceLine y={0} strokeWidth={2} stroke={'#333'} /> */}
					{scoredPatients
						// .filter((p) => !!scores[p.uid])
						.map((patient) => (
							<Line
								type="monotone"
								key={patient.uid}
								dataKey={patient.uid}
								width={2}
								stroke={getRandomColor(patient.uid)}
								strokeWidth={2}
								dot={true}
								isAnimationActive={false}
							/>
						))}
					<Tooltip
						contentStyle={{
							backgroundColor: '#fff',
							zIndex: 99,
							position: 'relative',
						}}
						labelFormatter={(value, payload) => {
							const name = payload?.[0]?.name
							const patient = patients.find((x) => x.uid === name)

							return `${format(new Date(value), 'dd/MM/yyyy')} - ${
								patient?.name
									? `${patient?.name} (${patient?.uid.substr(-8)})`
									: patient?.uid.substr(-8)
							}`
						}}
						formatter={(value, name) => {
							return [numeral(value).format('0,0.00'), `${label}`]
						}}
					/>
					<Legend
						formatter={(uid) => {
							const patient = patients.find((x) => x.uid === uid)

							return patient?.name
								? `${patient?.name} (${patient?.uid.substr(-8)})`
								: patient?.uid.substr(-8)
						}}
					/>
				</ComposedChart>
			</ResponsiveContainer>
		</div>
	)
}
