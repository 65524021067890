import React, { Children, cloneElement, useLayoutEffect, useRef } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'

type TabProps = {
	title: React.ReactNode
	disabled?: boolean
	onSelect?: (number) => void
	active?: boolean
	icon?: React.ReactNode
}

export function Tab({ title, disabled = false, onSelect, active, icon }: TabProps) {
	return (
		<div
			onClick={!disabled ? onSelect : undefined}
			className={`min-w-1/2 lg:min-w-min text-center select-none border-b-2 ${
				disabled ? 'text-gray-400 cursor-default' : 'cursor-pointer'
			} ${
				active
					? 'border-main text-main hover:bg-gray-100'
					: !disabled
					? 'border-white hover:border-main hover:bg-main hover:text-white'
					: 'border-white'
			} flex-1 py-2 px-4 transition flex justify-center items-center bg-white shadow font-bold`}
		>
			<span className="flex items-center mx-4">
				{icon}
				<span>{title}</span>
			</span>
		</div>
	)
}

export function Tabs({ children, selectedTab, onSelectTab }) {
	const ref = useRef<HTMLDivElement>()
	useLayoutEffect(() => {
		const node = ref.current
		function onScroll(event) {
			node?.scroll({
				behavior: 'smooth',
				left: event.target.offsetLeft - 128,
			})
		}

		node?.addEventListener('click', onScroll)

		return () => {
			node?.removeEventListener('click', onScroll)
		}
	}, [])

	function handleScrollTabBar(event) {
		ref.current?.scroll({
			behavior: 'smooth',
			left: ref.current?.scrollLeft + Number(event.currentTarget.dataset.offset),
		})
	}

	return (
		<div className="flex">
			<div
				className="bg-main text-white border-b border-r flex justify-center items-center w-10 lg:hidden"
				data-offset={-128}
				onClick={handleScrollTabBar}
			>
				<ChevronLeft />
			</div>
			<div
				className="flex-1 scrollbar-none flex justify-between overflow-x-auto border-b border-gray-200"
				ref={ref}
			>
				{Children.map(children, (child, index) => {
					if (!child) {
						return null
					}

					return cloneElement(child, {
						...child.props,
						active: index === selectedTab,
						onSelect: () => onSelectTab(index),
					})
				})}
			</div>
			<div
				className="bg-main text-white border-b border-l flex justify-center items-center w-10 lg:hidden"
				data-offset={128}
				onClick={handleScrollTabBar}
			>
				<ChevronRight />
			</div>
		</div>
	)
}

Tabs.Tab = Tab
