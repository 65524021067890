import { useMemo } from 'react'
import { Patient } from '../../common/app.types'
import { StatBox } from './stat-box.component'

export type PatientPageStatisticsProps = {
	patient: Patient
}

export function PatientPageStatistics({ patient }: PatientPageStatisticsProps) {
	const totalAudioCount = useMemo(() => {
		if (!patient.stats) {
			return 0
		}

		return Object.entries(patient.stats.audioCount).reduce((acc, [key, value]) => {
			return acc + value
		}, 0)
	}, [patient.stats])

	const maxTag = useMemo(() => {
		if (!patient.stats) {
			return null
		}

		let maxTag = {
			count: 0,
			id: '',
		}

		for (const key in patient.stats.tagCount) {
			if (patient.stats.tagCount[key] > maxTag.count) {
				maxTag = {
					count: patient.stats.tagCount[key],
					id: key,
				}
			}
		}

		return {
			...maxTag,
			tag: patient.tags.find((tag) => tag.uid === maxTag.id),
		}
	}, [patient.stats, patient.tags])

	return (
		<div>
			{!patient.stats && <div className="p-4">Statistiche non disponibili per questa persona</div>}
			{patient.stats && (
				<div className="p-4">
					<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
						<StatBox title="Registrazioni Effettuate" value={patient.stats.measureCount} />
						<StatBox title="Audio Riprodotti" value={totalAudioCount} />
						{maxTag && maxTag.tag && (
							<StatBox
								title="Tag più utilizzato"
								value={`${maxTag.count} volte`}
								subtitle={maxTag.tag?.title}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	)
}
