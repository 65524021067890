import React, { useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import { Navigate } from 'react-router-dom'
import useSWR from 'swr'
import { server } from '../../../core/server.core'
import { Button } from '../../common/components/button.component'
import { Loading } from '../../common/components/loading.component'
import { Logo } from '../../common/components/logo.component'
import { useAuth } from '../auth/hooks/use-auth.hook'

export function JoinGroup() {
	const [user] = useAuth()
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const params = useParams<{ id: string }>()

	const { data, error } = useSWR(params.id ? `/join/${params.id}` : null)
	console.log(data, error)

	const isLoading = useMemo(() => {
		return loading || (!data && !error)
	}, [loading, data, error])

	async function handleJoinGroup(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault()
		event.stopPropagation()

		try {
			setLoading(true)
			await server().post(`/join/${params.id}`)

			toast.success('Invito accettato correttamente!')
			setSuccess(true)
		} catch (error) {
			toast.error(`Si è verificato un errore e non è stato possibile accettare l'invito.`)
		} finally {
			setLoading(false)
		}
	}

	if (!user) {
		return <Navigate to="/login" />
	}

	return (
		<div className="h-screen w-full lg:w-1/3 lg:mx-auto flex items-center justify-center flex-col p-4 relative">
			{isLoading && (
				<div className="absolute inset-0 bg-white bg-opacity-95">
					<Loading />
				</div>
			)}
			<Logo />
			<form className="lg:border border-gray-100 lg:shadow p-4 mt-8 w-full" onSubmit={handleJoinGroup}>
				<h2 className="mb-2 text-center">
					Sei stato invitato ad unirti al gruppo <strong>{data?.name}</strong> di{' '}
					<strong>{data?.doctor}</strong>.
				</h2>

				<div className="flex mt-4 flex-row w-full justify-center">
					{!success && data?.canJoin && (
						<Button loading={loading} variant="success" type="submit">
							Accetta l'invito
						</Button>
					)}

					{!data?.canJoin && !success && (
						<p className="text-center">
							Purtroppo non puoi accettare l'invito. Se non hai già accettato in precedenza, ricorda che
							devi essere associato al professionista di riferimento del gruppo ed essere rimosso
							dall'eventuale gruppo a cui stai già attualmente partecipando per poter partecipare a questo
							gruppo!
						</p>
					)}

					{success && (
						<p className="text-center text-green-800 font-bold">Hai accettato l'invito correttamente.</p>
					)}
				</div>
			</form>
			<div className="flex-1"></div>
			<div className="text-xs">
				<p>&copy; 2021 Mind &amp; Move SRL</p>
				<p>
					<a
						className="link"
						href="https://www.mindtomove.it"
						rel="nofollow noopener noreferrer"
						target="_blank"
					>
						https://www.mindtomove.it
					</a>
				</p>
			</div>
		</div>
	)
}
