export function getFeedbackColor(feedback) {
	switch (feedback) {
		case 0: {
			return '#f44336'
		}
		case 1: {
			return '#ff9800'
		}
		case 2: {
			return '#ffab40'
		}
		case 3: {
			return '#8bc34a'
		}
		case 4: {
			return '#4caf50'
		}

		default: {
			return '#333'
		}
	}
}
