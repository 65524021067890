export function getDurationString(duration) {
	const date = new Date(0)
	date.setMilliseconds(duration)

	return `${date
		.getUTCHours()
		.toString()
		.padStart(2, '0')}:${date
		.getUTCMinutes()
		.toString()
		.padStart(2, '0')}:${date.getUTCSeconds().toString().padStart(2, '0')}`
}
