export const BreathingValidationRules = {
	title: [
		{
			rule: (v) =>
				typeof v !== 'undefined' && v !== null && v.length !== 0,
			message: 'Campo obbligatorio',
		},
		{
			rule: (v) => v?.length >= 3,
			message: 'Il titolo del tag deve essere lungo almeno 3 caratteri',
		},
	],
	inspiration: [
		{
			rule: (v) =>
				typeof v !== 'undefined' && v !== null && v.length !== 0,
			message: 'Campo obbligatorio',
		},
		{
			rule: (v) => +v >= 0,
			message: 'Il campo non può essere negativo',
		},
	],
	inspause: [
		{
			rule: (v) =>
				typeof v !== 'undefined' && v !== null && v.length !== 0,
			message: 'Campo obbligatorio',
		},
		{
			rule: (v) => +v >= 0,
			message: 'Il campo non può essere negativo',
		},
	],
	expiration: [
		{
			rule: (v) =>
				typeof v !== 'undefined' && v !== null && v.length !== 0,
			message: 'Campo obbligatorio',
		},
		{
			rule: (v) => +v >= 0,
			message: 'Il campo non può essere negativo',
		},
	],
	expause: [
		{
			rule: (v) =>
				typeof v !== 'undefined' && v !== null && v.length !== 0,
			message: 'Campo obbligatorio',
		},
		{
			rule: (v) => +v >= 0,
			message: 'Il campo non può essere negativo',
		},
	],
}
