import { useCallback, useMemo, useState } from 'react'
import { Bell, Clock, Edit, Plus, RotateCw, Tag, Trash, XCircle } from 'react-feather'
import toast from 'react-hot-toast'

import { Button } from '../../common/components/button.component'
import { Checkbox } from '../../common/components/checkbox.component'
import { Loading } from '../../common/components/loading.component'
import { ModalForm } from '../../common/components/modal-form.component'
import { Table, TableColumn } from '../../common/components/table.component'
import { Tooltip } from '../../common/components/tooltip.component'
import { SortDirection } from '../../common/hooks/use-sort.hook'
import { getDurationString } from '../../common/utils/get-duration-string.util'
import { getType } from '../patients/utils/get-type.util'
import { TagAdditionalLoading, TagForm } from '../tools/tag-form.component'
import { TagValidationRules } from './validation-rules/tag.validation-rules'

const sortPropertyFnMap = {
	uid: (item) => item.uid,
	title: (item) => item.title.toLowerCase(),
	time: (item) => item.time,
	type: (item) => item.type,
}

function getSortProperty(key) {
	return sortPropertyFnMap[key] ?? 'title'
}

function useTagForm(): [boolean, any, { onOpen; onClose; onClosed }] {
	const [isOpen, setIsOpen] = useState(false)
	const [tag, setTag] = useState()

	const onOpen = useCallback(async function onOpen(item) {
		setTag(item)
		setIsOpen(true)
	}, [])
	const onClose = useCallback(function onClose() {
		setIsOpen(false)
	}, [])
	const onClosed = useCallback(function onClosed() {
		setTag(null)
	}, [])

	return [isOpen, tag, { onOpen, onClose, onClosed }]
}

export function GroupPageTags({ tags, onSaveTag, onDeleteOrRestoreTag }) {
	const [checkedTags, setCheckedTags] = useState<string[]>([])
	const [loading, setLoading] = useState(false)
	const [isTagFormOpen, tagFormData, tagFormHandlers] = useTagForm()

	const tagsTableColumns = useMemo((): TableColumn[] => {
		return [
			{
				key: 'mobile-cell',
				title: '',
				headerClassName: 'hidden',
				cellClassName: 'table-cell lg:hidden',
				getValue(item) {
					return (
						<div className="shadow border p-4 mb-2 bg-white flex">
							<div className="flex-1 flex justify-between items-center">
								<div>
									<div className="font-bold">{item.title}</div>
									<div className="text-sm text-gray-500">{getType(item.type)}</div>
								</div>
								<span className="flex items-center italic text-sm">
									{+item.time > 0 && (
										<>
											<Clock size={16} className="inline mr-2" />
											{getDurationString(+item.time * 1000)}
										</>
									)}
								</span>
							</div>
						</div>
					)
				},
			},
			{
				key: 'checkbox',
				title: '',
				cellClassName: 'hidden lg:table-cell w-8',
				renderHeader(props: any) {
					return (
						<div className="hidden lg:block p-4 cursor-pointer">
							<Checkbox
								className="cursor-pointer"
								checked={checkedTags.length === tags.length}
								onChange={(e) => {
									if (e.target.checked) {
										setCheckedTags(tags.map((t) => t.uid))
									} else {
										setCheckedTags([])
									}
								}}
								inputProps={{ readOnly: true }}
							/>
						</div>
					)
				},
				getValue(item) {
					if (item.globalTagId) {
						return null
					}

					return (
						<Checkbox
							checked={checkedTags.includes(item.uid)}
							inputProps={{ readOnly: true }}
							preventBubbling={false}
						/>
					)
				},
			},
			{
				key: 'isDeleted',
				title: '',
				cellClassName: 'hidden lg:table-cell w-16',
				getValue(item) {
					if (item.isDeleted) {
						return (
							<Tooltip content="Oscurato">
								<XCircle size={16} className="inline text-red-500" />
							</Tooltip>
						)
					}

					return null
				},
			},
			{
				key: 'title',
				title: 'Nome Tag',
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell',
				getSortProperty: getSortProperty('title'),
			},
			{
				key: 'type',
				title: 'Tipo',
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell',
				getValue: (item) => getType(item.type),
				getSortProperty: getSortProperty('type'),
			},
			{
				key: 'time',
				title: 'Durata',
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell',
				getValue(item) {
					if (item.time < 0) {
						return '-'
					}

					return getDurationString(+item.time * 1000)
				},
				getSortProperty: getSortProperty('time'),
			},
			{
				key: 'hasReminder',
				title: 'Notifica',
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell',
				getValue(item) {
					if (item.hasReminder) {
						return (
							<div className="flex items-center text-sm">
								<Bell size={16} className="inline text-orange-500 mr-2" /> {item.reminder}
							</div>
						)
					}
				},
			},
			{
				key: 'actions',
				title: '',
				headerClassName: 'hidden lg:table-cell text-left p-4',
				cellClassName: 'hidden lg:table-cell lg:p-0 lg:px-4 text-right w-8',
				getValue(item) {
					return (
						<>
							<Button
								variant="primary"
								onClick={(e) => {
									e.stopPropagation()
									tagFormHandlers.onOpen(item)
								}}
							>
								<Edit size={16} />
							</Button>
						</>
					)
				},
			},
		]
	}, [checkedTags, tagFormHandlers, tags])

	async function handleDeleteMultipleTags() {
		for (const uid of checkedTags) {
			await onDeleteOrRestoreTag(uid, { setLoading, silent: true, forceStatus: 'delete' })
		}
		toast.success('Operazione completata!')
	}

	async function handleRestoreMultipleTags() {
		for (const uid of checkedTags) {
			await onDeleteOrRestoreTag(uid, { setLoading, silent: true, forceStatus: 'restore' })
		}
		toast.success('Operazione completata!')
	}

	function handleDeleteOrRestoreTag({ setLoading, onCancel }) {
		if (tagFormData) {
			onDeleteOrRestoreTag(tagFormData.uid, { setLoading, onCancel })
		}
	}

	return (
		<div>
			{loading && (
				<div className="absolute inset-0 bg-white bg-opacity-95">
					<Loading />
				</div>
			)}
			<ModalForm
				open={isTagFormOpen}
				onClose={tagFormHandlers.onClose}
				onClosed={tagFormHandlers.onClosed}
				form={(data, onChange, validationStatus, options) => {
					return (
						<TagForm
							tag={tagFormData}
							data={data}
							onChange={onChange}
							validationStatus={validationStatus}
							options={options}
							onClearCalibration={null}
						/>
					)
				}}
				title={tagFormData ? 'Modifica Tag' : 'Nuovo Tag'}
				confirmLabel="Salva"
				confirmVariant="success"
				cancelLabel="Annulla"
				cancelVariant="default"
				icon={<Tag size={18} className="mr-2 inline" />}
				onConfirm={(data) => {
					return onSaveTag(data)
				}}
				validationRules={TagValidationRules}
				footerAddition={({ loading, additionalLoading, setLoading, onCancel }) => {
					return (
						<>
							{tagFormData?.uid && !tagFormData?.isDeleted && (
								<Button
									disabled={loading}
									loading={additionalLoading === TagAdditionalLoading.Delete}
									variant="danger"
									onClick={() => handleDeleteOrRestoreTag({ setLoading, onCancel })}
								>
									<Trash className="mr-2" size={16} /> Oscura Tag
								</Button>
							)}
							{tagFormData?.isDeleted && (
								<Button
									disabled={loading}
									variant="info"
									loading={additionalLoading === TagAdditionalLoading.Delete}
									onClick={() => handleDeleteOrRestoreTag({ setLoading, onCancel })}
								>
									<RotateCw className="mr-2" size={16} /> Ripristina Tag
								</Button>
							)}
						</>
					)
				}}
			/>

			{!tags?.length && (
				<div className="flex flex-1 h-20 justify-center items-center border shadow text-gray-600 text-center">
					Il gruppo non ha nessun tag associato
				</div>
			)}
			{!!tags?.length && (
				<Table
					items={tags}
					columns={tagsTableColumns}
					getItemKey={(m) => m.uid}
					rowClassName="lg:hover:bg-gray-100 cursor-pointer lg:odd:bg-gray-50"
					commonCellClassName="lg:p-4"
					headerRowClassName="lg:border-b"
					onRowClick={(item) => {
						setCheckedTags((tags) => {
							if (tags.includes(item.uid)) {
								return tags.filter((tag) => tag !== item.uid)
							}

							return [...tags, item.uid]
						})
					}}
					defaultSortProperties={{
						property: getSortProperty('title'),
						direction: SortDirection.Ascending,
					}}
				/>
			)}

			<div className="flex justify-between items-center mt-2 pt-4 border-t">
				<div>
					{checkedTags.length > 0 && (
						<Button variant="info" className="w-full lg:w-auto" onClick={handleRestoreMultipleTags}>
							<RotateCw className="mr-2" size={16} /> Ripristina Selezionati
						</Button>
					)}
					{checkedTags.length > 0 && (
						<Button variant="danger" className="w-full lg:w-auto" onClick={handleDeleteMultipleTags}>
							<Trash size={16} className="mr-2" /> Oscura Selezioanti
						</Button>
					)}
				</div>
				<Button variant="success" className="w-full lg:w-auto" onClick={() => tagFormHandlers.onOpen()}>
					<Plus size={16} className="mr-2" /> Nuovo Tag
				</Button>
			</div>
		</div>
	)
}
