import { useEffect } from 'react'

import { Input } from '../../common/components/input.component'

type BreathingFormProps = {
	breathing?: any
	data: any
	onChange: Function
	validationStatus: any
}

export function BreathingForm({ breathing, data, onChange, validationStatus }: BreathingFormProps) {
	useEffect(() => {
		if (!breathing) {
			onChange(
				{
					uid: Math.floor(+new Date() * Math.random() * 100).toString(32),
					title: '',
					inspiration: 0,
					inspause: 0,
					expiration: 0,
					expause: 0,
					hasReminder: false,
					reminder: null,
					notificationId: null,
				},
				true,
			)
		}
		if (breathing) {
			onChange(breathing, true)
		}
	}, [breathing, onChange])

	useEffect(() => {
		if (!data) {
			return
		}

		if (data.hasReminder && !data.reminder) {
			onChange({
				reminder: '15:00',
				notificationId: Math.floor(Math.random() * 1000000),
			})
		} else if (!data.hasReminder && data.reminder) {
			onChange({
				reminder: null,
				notificationId: null,
			})
		}
	}, [data, onChange])

	return (
		<div>
			<Input
				name="title"
				label="Nome Respirazione"
				value={data?.title}
				onChange={onChange}
				error={validationStatus.title?.error}
				errorText={validationStatus.title?.message}
				inputProps={{ autoComplete: 'off' }}
			/>

			<div className="grid grid-cols-2 grid-rows-2 gap-2">
				<Input
					label="Inspirazione (sec)"
					min={0}
					step={0.5}
					type="number"
					value={data?.inspiration}
					name="inspiration"
					onChange={onChange}
					error={validationStatus.inspiration?.error}
					errorText={validationStatus.inspiration?.message}
					inputProps={{ autoComplete: 'off' }}
				/>
				<Input
					label="Pausa Inspirazione (sec)"
					min={0}
					step={0.5}
					type="number"
					value={data?.inspause}
					name="inspause"
					onChange={onChange}
					error={validationStatus.inspause?.error}
					errorText={validationStatus.inspause?.message}
					inputProps={{ autoComplete: 'off' }}
				/>
				<Input
					label="Espirazione (sec)"
					min={0}
					step={0.5}
					type="number"
					value={data?.expiration}
					name="expiration"
					onChange={onChange}
					error={validationStatus.expiration?.error}
					errorText={validationStatus.expiration?.message}
					inputProps={{ autoComplete: 'off' }}
				/>
				<Input
					label="Pausa Espirazione (sec)"
					min={0}
					step={0.5}
					type="number"
					value={data?.expause}
					name="expause"
					onChange={onChange}
					error={validationStatus.expause?.error}
					errorText={validationStatus.expause?.message}
					inputProps={{ autoComplete: 'off' }}
				/>
			</div>
		</div>
	)
}
