import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { useCallback, useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { Bold, Link, Trash, Underline } from 'react-feather'

import '../../../html-editor.css'
import { Button } from './button.component'
import { Input } from './input.component'
import { PopForm } from './pop-form.component'

function getDraftState(html) {
	const blocks = htmlToDraft(html)
	const { contentBlocks, entityMap } = blocks
	const content = ContentState.createFromBlockArray(contentBlocks, entityMap)
	return EditorState.createWithContent(content)
}

export function LinkForm({ data, onChange, link, currentState }) {
	useEffect(() => {
		if (!data) {
			onChange(
				{
					title: '',
					link: '',
				},
				true,
			)
		}
	}, [data, onChange])

	useEffect(() => {
		let title = link?.title
		if (!title || title.length === 0) {
			title = currentState?.selectionText
		}

		onChange({
			title: title ?? '',
			link: link?.target ?? '',
		})
	}, [link, currentState, onChange])

	return (
		<div>
			<Input label="Titolo" value={data?.title} onChange={onChange} name="title" />
			<Input label="URL" value={data?.link} onChange={onChange} name="link" />
		</div>
	)
}

export function HtmlEditor({ value, onChange, onBlur }) {
	const [state, setState] = useState(getDraftState(value))

	const handleStateChange = useCallback(
		(state: EditorState) => {
			setState(state)

			onChange(draftToHtml(convertToRaw(state.getCurrentContent())))
		},
		[onChange],
	)

	return (
		<Editor
			editorState={state}
			onEditorStateChange={handleStateChange}
			onBlur={onBlur}
			wrapperClassName="border border-gray-200"
			editorClassName="px-4"
			toolbar={{
				options: ['inline', 'link'],
				inline: {
					component: ({ currentState, onChange }) => (
						<div className="mr-2 pr-2 border-r border-gray-200">
							<Button variant="link" outline={currentState?.bold} onClick={() => onChange('bold')}>
								<Bold size={18} />
							</Button>
							<Button
								variant="link"
								outline={currentState?.underline}
								onClick={() => onChange('underline')}
							>
								<Underline size={18} />
							</Button>
						</div>
					),
				},
				link: {
					component: (props) => {
						return (
							<div>
								<PopForm
									title="Link"
									icon={<Link size={18} className="mr-2" />}
									form={(data, onChange) => {
										return (
											<LinkForm
												data={data}
												onChange={onChange}
												currentState={props?.currentState}
												link={props?.currentState?.link}
											/>
										)
									}}
									onConfirm={(data) => props.onChange('link', data.title, data.link)}
									footerAddition={({ onCancel }) => (
										<div className="w-full">
											{props.currentState?.link && (
												<Button
													variant="danger"
													onClick={() => {
														props.onChange('unlink')
														onCancel()
													}}
												>
													<Trash size={18} className="mr-2" /> Rimuovi Link
												</Button>
											)}
										</div>
									)}
								>
									<Button variant="link">
										<Link size={18} />
									</Button>
								</PopForm>
							</div>
						)
					},
				},
			}}
		/>
	)
}
